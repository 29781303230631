import { Component } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SphereSubscribeStripeComponent } from "../sphere-subscribe-stripe/sphere-subscribe-stripe.component";
import { Router } from "@angular/router";

@Component({
  selector: "myp-theme-chooser",
  templateUrl: "theme-chooser.component.html",
})
export class ThemeChooserComponent extends BaseComponent {
  constructor(public userService: UserService, public activeModal: NgbActiveModal, public appService: AppService, private _router: Router) {
    super(userService);
  }

  changeTheme(theme) {
    if (!this.userService.legacyFree && !this.userService.user.active_subscription && theme != "auto" && theme != "plainlight" && theme != "plaindark") {
      this.upgrade();
    } else {
      this.userService.themeImg = theme;
      this.userService
        .saveUser({
          id: this.user.id,
          theme: theme,
        })
        .subscribe((result) => {});
    }
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }
}
