import { Component, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from "@angular/core";

import { BaseComponent } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-stars",
  templateUrl: "stars.component.html",
})
export class StarsComponent implements AfterViewInit {
  private _stars: number;
  @Input()
  set stars(stars: number) {
    this.width = stars ? stars * 20 : 0;
    this._stars = stars;
  }

  get stars() {
    return this._stars;
  }

  @Input() size: string = "sm";
  @Input() selectingRating: boolean = false;
  @Output() ratingChosen: EventEmitter<number> = new EventEmitter();
  public containerWidth: number;
  public width: number;
  public chosenRating: number = 0;

  @ViewChild("starsContainer", { static: false }) starsContainerEl: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.containerWidth = this.starsContainerEl.nativeElement.offsetWidth;
  }

  setHoverRating(rating, persist = false) {
    if (this.selectingRating) {
      this.width = rating * 20;
    }
  }

  chooseRating(rating) {
    if (this.selectingRating) {
      this.width = rating * 20;
      this.chosenRating = rating;
      this.ratingChosen.emit(rating);
    }
  }
}
