import { Component, EventEmitter, Input, Output } from "@angular/core";
import { HelperService, UserService, WindowService } from "@mypxplat/xplat/core";
import algoliasearch from "algoliasearch";
import { SearchIndex } from "algoliasearch";
import { CommunitySearchBaseComponent } from "@mypxplat/xplat/features";

import { AppService, AvoService, WebCommunityService } from "@mypxplat/xplat/web/core";
import firebase from "firebase/compat/app";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { Location } from "@angular/common";
@Component({
  selector: "myp-community-search",
  templateUrl: "community-search.component.html",
})
export class CommunitySearchComponent extends CommunitySearchBaseComponent {
  @Output() viewPost: EventEmitter<any> = new EventEmitter();
  @Output() postsLoaded: EventEmitter<any> = new EventEmitter();
  @Output() scrollToTop: EventEmitter<any> = new EventEmitter();
  @Input() betaStringId: string;
  private _query: string;
  @Input() set query(value) {
    this._query = value;
  }
  get query() {
    return this._query;
  }
  public algoliaIndex: SearchIndex;
  public results: Array<any>;
  public parentsMap: any = {};
  public page: number = 1;
  public totalPages: number;

  constructor(
    userService: UserService,
    public helperService: HelperService,
    public communityService: WebCommunityService,
    private _router: Router,
    private _location: Location,
    public appService: AppService,
    private _win: WindowService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  ngOnInit() {
    const searchClient = algoliasearch(this.env.algolia.app_id, this.env.algolia.apiKey);
    this.algoliaIndex = searchClient.initIndex(this.betaStringId ? "prs_beta_community_posts_created_desc" : "prs_community_posts_created_desc");
    if (this.communityService.cachedSearchResults) {
      this.parentsMap = this.communityService.cachedSearchParentsMap;
      this.results = this.communityService.cachedSearchResults;
      this.page = this.communityService.cachedSearchPage;
      this._win.setTimeout(() => {
        this.postsLoaded.next(true);
      }, 300);
    }
    this.search();
  }

  search(args?, scrollToTop?, reset?) {
    if (!args || args.key == "Enter") {
      const params = new HttpParams().appendAll({ search: this.query });
      this._location.replaceState(location.pathname, params.toString());
      if (reset) {
        this.page = 1;
        this.totalPages = undefined;
        this.communityService.cachedSearchResults = undefined;
        this.communityService.cachedSearchParentsMap = undefined;
        this.communityService.cachedSearchPage = undefined;
      }
      if (!this.page) this.page = 1;
      let algoliaArgs: any = {
        highlightPreTag: "<em>",
        highlightPostTag: "</em>",
        attributesToHighlight: ["message", "subject"],
        page: this.page - 1 < 0 ? 0 : this.page - 1,
      };
      if (this.betaStringId) algoliaArgs.filters = `beta_string_id:"${this.betaStringId}"`;
      this.algoliaIndex.search(this.query, algoliaArgs).then((result) => {
        if (!this.betaStringId) {
          this.avoService.trackEvent().communitySearch({
            userId_: this.user.id,
            searchText: this.query,
            searchResultsCount: result.hits.length,
            prsId: this.user.id,
            siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          });
        }

        this.totalPages = result.nbPages;
        result.hits.sort((a: any, b: any) => {
          return new Date(a.created._seconds) > new Date(b.created._seconds) ? -1 : 1;
        });
        let parentPromises = [];
        result.hits.forEach((hit: any) => {
          if (hit.parentID) parentPromises.push(this.communityService.getPostByID(hit.parentID, true, false, this.betaStringId ? "beta_community_posts" : "community_posts"));
          if (hit.useful && hit.useful.length) {
            let iMarkedUseful = hit.useful.filter((id) => id == this.userService.user.id).length;
            hit.markedUseful = iMarkedUseful;
          }
        });
        Promise.all(parentPromises).then((parents) => {
          parents.forEach((parent) => {
            if (parent) this.parentsMap[parent.id] = parent;
          });
          this.results = result.hits.filter((hit: any) => {
            return !hit.deleted && (!hit.parentID || this.parentsMap[hit.parentID]);
          });
          this.communityService.cachedSearchResults = this.results;
          this.communityService.cachedSearchParentsMap = this.parentsMap;
          if (scrollToTop) this.scrollToTop.next(true);
        });
      });
    }
  }

  toggleUseful(post) {
    this.communityService.updatePost(post.objectID, {
      useful: post.markedUseful ? firebase.firestore.FieldValue.arrayRemove(this.user.id) : firebase.firestore.FieldValue.arrayUnion(this.user.id),
    });
    post.markedUseful = !post.markedUseful;
  }

  clear() {
    this.query = "";
    this.results = undefined;
    this.page = 1;
    this.totalPages = undefined;
  }

  paginate(direction) {
    this.page = this.page + (direction == "next" ? 1 : -1);
    this.communityService.cachedSearchPage = this.page;
    this.search(false, true);
  }

  viewPostClicked(args) {
    let id = args.objectID;
    if (args.parentID) id = args.parentID;
    if (this.betaStringId) {
      this._router.navigate([`beta/betapost/${id}`], { queryParams: { betaStringId: this.betaStringId, searchTerm: this.query } });
    } else {
      this._router.navigate(["community/post/" + id]);
    }
  }
}
