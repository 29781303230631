import { AppService } from "./app.service";
import { ScriptService } from "./script.service";
import { MessagingService } from "./messaging.service";
import { BetaService } from "./beta.service";
import { WebCommunityService } from "./community.service";
import { AvoService } from "./avo.service";
import { WebErrorHandler } from "./error-handler.service";
import { FirebaseService } from "./firebase.service";
import { ReftagService } from "./reftag.service";

export const CORE_PROVIDERS: any[] = [
  // Services
  AppService,
  MessagingService,
  ScriptService,
  BetaService,
  WebCommunityService,
  AvoService,
  WebErrorHandler,
  FirebaseService,
  ReftagService,
];

export * from "./app.service";
export * from "./script.service";
export * from "./messaging.service";
export * from "./beta.service";
export * from "./community.service";
export * from "./avo.service";
export * from "./firebase.service";
export * from "./error-handler.service";
export * from "./reftag.service";
