import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChange } from "@angular/core";

import { BaseComponent, UserService, environment, WindowService, EventBusService, WorkspaceService, ContentService, HelperService, CommunityService } from '@mypxplat/xplat/core';
import { VideoNotesBaseComponent } from "@mypxplat/xplat/features";
import { AppService, WebCommunityService } from '@mypxplat/xplat/web/core';
import { take } from "rxjs/operators";
import { MessageInputComponent } from "../message-input/message-input.component";
import { EditCommentComponent, SimpleSignupComponent } from "../modals";

@Component({
  selector: "myp-video-notes",
  templateUrl: "video-notes.component.html",
})
export class VideoNotesComponent extends VideoNotesBaseComponent {
  @Input() height: number;
  @Input() fullHeight: boolean;
  @Input() forceDark: boolean;
  public isHovering = false;
  
  private _activeTime: any;
  @Input() set activeTime(time) {
    this._activeTime = time;
    if (this.timedVideoNotes && this.timedVideoNotes.length) {
      this.timedVideoNotes.forEach(item => {
        if (((parseInt(item.video_timestamp) - 5) < time.seconds) && ((parseInt(item.video_timestamp) + 5) > time.seconds)) {
          item.active = true;
        } else {
          if (item.active) item.active = false;
        }
      })
    }
  }

  get activeTime() {
    return this._activeTime;
  }

  @Output() playVideoFrom: EventEmitter<any> = new EventEmitter();

  
  public noteInputHeight: number = 44;

  @ViewChild("msgInput") msgInput: MessageInputComponent;
  @ViewChild("chatWindow") chatWindow: ElementRef;
  
  constructor(
    public userService: UserService, 
    private _win: WindowService, 
    private _eventbusService: EventBusService, 
    public workspaceService: WorkspaceService, 
    public appService: AppService,
    public helperService: HelperService,
    public contentService: ContentService,
    public communityService: WebCommunityService
  ) {
    super(userService, contentService, communityService);
  }

  
  noteValueChange(args) {
    this.note = args;
  }

  addNote() {
    super.addNote(this.activeTime)
    this.msgInput.emptyInput()
  }

  scrollChatToBottom() {
    this._win.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
      }
    }, 25);
  }

  inputHeightChange(args) {
    this.noteInputHeight = args;
  }

  editNote(note) {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg" }, true, true);
    modalRef.componentInstance.comment = note;
    modalRef.componentInstance.isVideoNote = true;
    modalRef.result.then((result) => {
      if (result) {
        this.contentService.updateVideoNote({id: result.id, text: result.text}).subscribe(result => {
          this.timedVideoNotes.forEach(item => {
            if (item.id == result.video_note.id) {
              item.text = result.video_note.text
              this.communityService.buildHtmlComment(item);
            }
          });
          this.videoNotes.forEach(item => {
            if (item.id == result.video_note.id) {
              item.text = result.video_note.text
              this.communityService.buildHtmlComment(item);
            }
          });
        });
        // this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({ message: result.message, edited: true });
      }
    }, (error) => {});
  }

  noteOptions(note) {
    let args: any = {
      title: 'Note Options',
      actions: ["Edit Note", "Delete Note"],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Note") {
            this.editNote(note)
          } else if (result == "Delete Note") {
            this.deleteNote(note);
          }
        }
      });
  }
}
