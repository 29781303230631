interface Scripts {
  name: string;
  src: string;
  extraParams?: any;
}
export const ScriptStore: Scripts[] = [
  { name: "segment", src: "./assets/js/segment.js" },
  { name: "segment-dev", src: "./assets/js/segment-dev.js" },
  { name: "googletagmanager", src: "./assets/js/google-tagmanager.js" },
  { name: "webview-interface", src: "./assets/js/webview-interface.js" },
  {
    name: "usercentrics-dev",
    src: "https://app.usercentrics.eu/browser-ui/latest/loader.js",
    extraParams: {
      id: "usercentrics-cmp",
      "data-version": "preview",
      "data-settings-id": "dyPcRoeeJ",
    },
  },
  {
    name: "usercentrics-prod",
    src: "https://app.usercentrics.eu/browser-ui/latest/loader.js",
    extraParams: {
      id: "usercentrics-cmp",
      "data-settings-id": "dyPcRoeeJ",
    },
  },
];

// <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-version="preview" data-settings-id="dyPcRoeeJ" async></script>
// <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js"                        data-settings-id="dyPcRoeeJ" async></script>
