import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CommunityService, EventBusService, HelperService, ProductService, UserService, WindowService } from "@mypxplat/xplat/core";

import { CommunityBaseComponent } from "@mypxplat/xplat/features";
import { AppService, AvoService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { filter, map, take, takeUntil } from "rxjs/operators";
import { CommunitySearchComponent } from "../community-search/community-search.component";
import { AddEditSkillComponent } from "../modals";
import { ChannelComponent } from "./channel/channel.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "myp-community",
  templateUrl: "community.component.html",
})
export class CommunityComponent extends CommunityBaseComponent {
  @Input() public betaStringId: string;

  @ViewChild("communityColumnLayoutContainer") communityColumnLayoutContainer: ElementRef;
  @ViewChild("contentContainer") contentContainer: ElementRef;
  @ViewChild("searchCmp") searchCmp: CommunitySearchComponent;
  @ViewChild("channelCmp") channelCmp: ChannelComponent;
  public displayedSection: string = "channels";
  public channelID: string;
  public topicID: string;
  public postID: any;
  public scrollToPostID: any;

  public topics: Array<any>;
  public allTopics: Array<any>;
  public showingAllTopcs: boolean = false;
  public selectedTopic: any;

  public inSetupMode: boolean = false;
  public setupStep: string = "init";
  public tagline: string;
  public loading: boolean = true;
  public shouldShowMemberResults: boolean = false;
  public searchResults: any;

  public skillsAcknowledged: boolean = false;

  public shouldShowPostDetails: boolean = false;
  public postDetail: any;
  public postDetailReplies: Array<any>;

  public query;
  public selectedQuery;

  public betaProgram: any;
  public isBetaManager: boolean = false;
  public filters: any = {
    type: "all",
    status: "all",
    reviewStatus: "all",
    author: "all",
  };
  public betaAnnouncements: Array<any>;

  public profile: any = {
    description: this.webCommunityService.profile?.description ? this.webCommunityService.profile.description : this.userService.user.description,
    tagline: this.webCommunityService.profile?.tagline ? this.webCommunityService.profile.tagline : "",
  };

  public communityQuery: string;
  public selectedBetaView = this.communityService.cachedDisplayType;

  constructor(
    userService: UserService,
    private _router: Router,
    private _win: WindowService,
    private _activatedRoute: ActivatedRoute,
    private _eventbusService: EventBusService,
    public appService: AppService,
    public webCommunityService: WebCommunityService,
    public communityService: WebCommunityService,
    public location: Location,
    public avoService: AvoService,
    public cd: ChangeDetectorRef,
    public betaService: BetaService,
    public helperService: HelperService,
    public productService: ProductService,
    public db: AngularFirestore
  ) {
    super(userService, communityService);
  }

  ngOnInit() {
    this.isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
    this.buildFilters();
    if (this.userService.user.banned) {
      this._router.navigate(["../home"]);
    } else {
      this.initView();
      this._router.events.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        // this allows you to "navigate" to another product detail from this page
        if (result instanceof NavigationEnd) {
          this.initView();
        }
      });
    }
  }

  initView() {
    this.communityService.firebaseProfile$
      .pipe(
        filter((profile) => profile !== null),
        take(1)
      )
      .subscribe((profile) => {
        this.displayedSection = this._activatedRoute.snapshot.params["category"];
        if (!this.displayedSection && this.betaStringId) this.displayedSection = "betadetails";
        if (this.betaStringId) {
          // get beta details.
          this.communityService.getBetaCommunityDetails(this.betaStringId);
          this.initBetaAnnouncements();
        }
        this.channelID = this._activatedRoute.snapshot.params["channelid"] && this.displayedSection == "channels" ? this._activatedRoute.snapshot.params["channelid"] : undefined;
        if (this.communityService.selectedChannel) this.channelID = this.communityService.selectedChannel.id;
        this.topicID = this._activatedRoute.snapshot.params["channelid"] && this.displayedSection == "topics" ? this._activatedRoute.snapshot.params["channelid"] : false;
        this.init();
        if (!this.webCommunityService.hasSetupCommunity && this.user.active_subscription && !this.betaStringId) this.inSetupMode = true;

        if (this._activatedRoute.snapshot.queryParams["search"]) {
          this.query = this._activatedRoute.snapshot.queryParams["search"];
          this.search();
        } else if (this._activatedRoute.snapshot.queryParams["postid"]) {
          this._router.navigate(["community/post/" + this._activatedRoute.snapshot.queryParams["postid"]], { queryParamsHandling: "merge" });
        } else if (this._activatedRoute.snapshot.queryParams["scrollToPost"]) {
          this.scrollToPostID = this._activatedRoute.snapshot.params["scrollToPost"];
        }
        this.cd.detectChanges();
      });
    this.communityService.getFirebaseProfile();
    this._eventbusService.observe(this._eventbusService.types.betaAnnouncementUpdated).subscribe((result) => {
      this.initBetaAnnouncements();
    });
  }

  initBetaAnnouncements() {
    this.db
      .collection("beta_community_posts")
      .ref.where("parentID", "==", null)
      .where("pinned", "==", true)
      .get()
      .then((result) => {
        let pinnedPosts = [];
        result.forEach((doc) => {
          let post: any = doc.data();
          if (!post.deleted) {
            post.id = doc.id;
            pinnedPosts.push(post);
          }
        });
        this.betaAnnouncements = pinnedPosts;
      });
  }

  initTopics() {
    this.webCommunityService.getTopics().then((result) => {
      this.topics = result.filter((item) => !item.archived);
      let topics = result.filter((item) => !item.archived);

      let archived = result.filter((item) => item.archived);
      archived.sort((a, b) => (a.title > b.title ? 1 : -1));
      topics.sort((a, b) => (a.title > b.title ? 1 : -1));
      this.topics = [...topics].filter((item) => !item.limitToRole || this.userService.user[item.limitToRole]);
      this.allTopics = [...topics, ...archived].filter((item) => !item.limitToRole || this.userService.user[item.limitToRole]);
      if (this.displayedSection == "topics") {
        let topic = this.topicID ? this.topics.filter((item) => item.string_id.toLowerCase() == this.topicID.toLowerCase())[0] : false;
        this.showTopic(topic || this.topics[0]);
      }
    });
  }

  channelSelected(event) {
    this.displayedSection = "channels";
    this.selectedTopic = undefined;
    this.selectedChannel = this.betaService.communityTagMap[event] || this.webCommunityService.skillsMap[event] || this.webCommunityService.extraChannelsMap[event];
    this.communityService.selectedChannel = this.selectedChannel;
    this.cd.detectChanges();
  }

  scrollToTop() {
    this.contentContainer.nativeElement.scrollTop = 0;
  }

  postsLoaded(args) {
    this._win.setTimeout(() => {
      if (this.appService.scrolledContentOffsets.community) this.contentContainer.nativeElement.scrollTop = this.appService.scrolledContentOffsets.community;
    }, 300);
  }

  filter() {
    this.communityService.showBetaType = this.filters.type == "all" ? undefined : this.filters.type;
    this.communityService.showBetaStatus = this.filters.status == "all" ? undefined : this.filters.status;
    this.communityService.showBetaReviewStatus = this.filters.reviewStatus == "all" ? undefined : this.filters.reviewStatus;
    this.communityService.showBetaAuthor = this.filters.author == "all" ? undefined : this.filters.author;
    this.channelCmp.initPosts();
  }

  buildFilters() {
    this.filters = {
      type: this.communityService.showBetaType || "all",
      status: this.communityService.showBetaStatus || "all",
      reviewStatus: this.communityService.showBetaReviewStatus || "all",
      author: this.communityService.showBetaAuthor || "all",
    };
  }

  resetFilters() {
    this.filters = {
      type: "all",
      status: "all",
      reviewStatus: "all",
      author: "all",
    };
    this.filter();
  }

  init() {
    if (this.webCommunityService.profile && this.webCommunityService.profile.status == "banned") {
      this._router.navigate(["../home"]);
    }
    if (this.betaStringId) {
      this.betaService.getBetas().subscribe((result) => {
        this.betaProgram = this.betaService.betaDetailByStringId[this.betaStringId];
        if (!this.betaProgram) {
          alert("A beta program with this ID was not found.");
          this._router.navigate(["../beta"]);
        } else if (!this.betaService.enrolledMap[this.betaProgram.string_id]) {
          alert("You are not enrolled in this beta.");
          this._router.navigate(["../beta"]);
        } else {
          this.betaService.getBetaCommunityTags(this.betaProgram.string_id).subscribe((result: any) => {
            this.initBetaCommunityTags(result);
            this.displayedChannels = this.channels;
            this.loading = false;
            if (!this._activatedRoute.snapshot.queryParams["search"]) {
              if (this.channelID) {
                let channel = this.channels.filter((item) => item.id.toString().toLowerCase() == this.channelID.toString().toLowerCase())[0];
                this.showChannel(channel);
              } else {
                if (this.displayedSection == "channels") this.showChannel(this.channels[0]);
              }
            }
          });
        }
      });
    } else {
      this.loadMySkills().subscribe((mySkills) => {
        this.webCommunityService.getAvailableSkills().subscribe((result) => {
          this.initSkills(result);
          this.loading = false;
          if (this.channelID) {
            let channel = this.channels.filter((item) => item.id.toLowerCase() == this.channelID.toString().toLowerCase())[0];
            this.showChannel(channel);
            let isMyChannel = this.mySkills.filter((item) => item.skill_id == channel.id);
            if (this.channelID !== "general" && this.channelID !== "all_posts" && !isMyChannel.length) {
              this.displayedChannels = this.channels;
              this.showingMyChannels = false;
            }
          } else {
            if (this.displayedSection == "channels") this.showChannel(this.channels[0]);
          }

          if (this._activatedRoute.snapshot.queryParams["skill_id"]) {
            if (this.webCommunityService.activeProfileResults) {
              this.showMemberResults({
                query: this.webCommunityService.skillsMap[this._activatedRoute.snapshot.queryParams["skill_id"]].title,
                results: this.webCommunityService.activeProfileResults,
              });
            } else {
              this._router.navigate(["../community/connect"]);
            }
          }
          this.cd.detectChanges();
        });
      });

      // lets not init topics for beta yet.
      this.initTopics();
    }
  }

  public toggleChannels() {
    if (this.showingMyChannels) {
      this.displayedChannels = this.channels;
    } else {
      this.displayedChannels = this.myChannels;
    }
    this.showingMyChannels = !this.showingMyChannels;
  }

  public postDetailRepliesSubscription: any;
  showPostDetail(postId?) {
    this.communityColumnLayoutContainer.nativeElement.classList.add("blurred");
    this.shouldShowPostDetails = true;
    this.webCommunityService
      .getPostByID(postId || this.postID)
      .then((result: any) => {
        let parentPostID = postId || this.postID;
        if (result[0] && result[0].parentID) {
          parentPostID = result[0].parentID;
          this.webCommunityService
            .getPostByID(parentPostID)
            .then((result: any) => {
              this.postDetail = result[0];
              this.postDetailReplies = result.filter((item) => item.parentID);
            })
            .catch((err) => {});
        } else {
          this.postDetail = result[0];
          this.postDetailReplies = result.filter((item) => item.parentID);
        }

        this.postDetailRepliesSubscription = this.webCommunityService.watchPostReplies(parentPostID, this.betaStringId ? "beta_community_posts" : "community_posts").subscribe((result) => {
          this.postDetailReplies = result;
        });
      })
      .catch((error) => {
        this.appService.alertError(error);
        this.closePostDetails(false, true);
      });
  }

  closePostDetails(args, force) {
    if (force || (args && args.target && args.target.className && args.target.className.indexOf && args.target.className.indexOf("post-detail-overlay") > -1)) {
      this.communityColumnLayoutContainer.nativeElement.classList.remove("blurred");
      this.postDetailRepliesSubscription.unsubscribe();
      this.shouldShowPostDetails = false;
      this.postDetailReplies = undefined;
      this.postDetail = undefined;
      // this._router.navigate(["../community/channels"]);
    }
  }

  search() {
    this.selectedQuery = this.query;
    if (this.displayedSection == "search") {
      this.searchCmp.query = this.query;
      this.searchCmp.search(false, true, true);
    } else {
      this.switchSection("search");
    }
  }

  clear() {
    this.communityService.cachedSearchPage = undefined;
    this.communityService.cachedSearchParentsMap = undefined;
    this.communityService.cachedSearchResults = undefined;
    this.query = "";
    if (this.displayedSection == "search") this.showChannel(this.betaStringId ? "all_posts" : "general");
  }

  contentContainerScrolled(args) {
    this.appService.scrolledContentOffsets.community = this.contentContainer.nativeElement.scrollTop;
  }

  showChannel(channel, clearScroll = false) {
    this.selectedTopic = undefined;
    if (clearScroll) this.appService.scrolledContentOffsets.community = 0;
    this.switchSection("channels", channel);
    if (channel?.id) {
      this.avoService.trackEvent().communityDetail({
        siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        channelName: [channel.id],
        name: channel.id,
        title: channel.id,
        customDestinationPageName_: "Community Detail",
      });
    }
  }

  showTopic(topic, clearScroll = false) {
    this.selectedChannel = undefined;
    if (clearScroll) this.appService.scrolledContentOffsets.community = 0;
    this.switchSection("topics", false, topic);
  }

  showMemberResults(results) {
    this.communityColumnLayoutContainer.nativeElement.classList.add("blurred");
    this.searchResults = results;
    this.shouldShowMemberResults = true;
  }

  closeMemberResults() {
    this.communityColumnLayoutContainer.nativeElement.classList.remove("blurred");
    this.shouldShowMemberResults = false;
    this._router.navigate(["../community/connect"]);
  }

  switchSection(section, channel?, topic?) {
    if (channel == "general" || channel == "all_posts") channel = this.channels.filter((item) => item.string_id == channel)[0];
    this.displayedSection = section;
    let route = (this.betaStringId ? "beta/" + this.betaStringId : "community") + "/" + section;
    if (channel) {
      route = (this.betaStringId ? "beta/" + this.betaStringId : "community") + "/" + section + "/" + channel.id.toString().toLowerCase();
    } else if (topic) {
      this.loading = false;
      route = (this.betaStringId ? "beta/" + this.betaStringId : "community") + "/" + section + "/" + topic.string_id.toLowerCase();
    }
    if (section != "search") this.location.replaceState(route);
    if (channel) {
      this.selectedTopic = undefined;
      this.selectedChannel = channel;
      this.communityService.selectedChannel = channel;
    } else if (topic) {
      this.selectedChannel = undefined;
      this.selectedTopic = topic;
    }
  }

  setProfile(isPublic) {
    let args = {
      photo: this.userService.user.photoURL,
      cover_photo: this.userService.user.cover_photo,
      public: isPublic,
      firstName: this.userService.user.firstName,
      firstName_lowercase: this.userService.user.firstName ? this.userService.user.firstName.toLowerCase() : null,
      lastName: this.userService.user.lastName,
      lastName_lowercase: this.userService.user.lastName ? this.userService.user.lastName.toLowerCase() : null,
      name: this.userService.user.firstName + " " + this.userService.user.lastName,
      name_lowercase: this.userService.user.firstName && this.userService.user.lastName ? this.userService.user.firstName.toLowerCase() + " " + this.userService.user.lastName.toLowerCase() : null,
      description: this.userService.user.description,
      hasSetupCommunity: true,
      id: this.userService.user.id,
      dateCommunityActivated: new Date(),
      email: this.userService.user.email.toLowerCase(),
    };
    this.webCommunityService.updateProfile(args).then((result) => {
      this.setupStep = isPublic ? "extra_details" : "done";
    });
    this.userService.saveUser({ public: isPublic }).subscribe();
  }

  updateProfile() {
    this.webCommunityService.updateProfile(this.profile).then((result) => {
      this.setupStep = "done";
    });
    if (this.profile.description || this.profile.firstName || this.profile.lastName) {
      delete this.profile.tagline;
      this.userService.saveUser(this.profile).subscribe();
    }
  }

  addSkill() {
    const modalRef = this.appService.showModal(AddEditSkillComponent, { size: "lg" });
    modalRef.componentInstance.skillAdded.pipe(take(1)).subscribe((result) => {
      this.init();
    });
  }

  doneSetup() {
    this.inSetupMode = false;
    this.cd.detectChanges();
  }
}
