import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { BaseComponent, StorageService, UserService, ProductService, WindowService, EventBusService } from "@mypxplat/xplat/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ExchangeService } from "@mypxplat/xplat/core";
import { BrowseExchangeComponent } from "../browse-exchange/browse-exchange.component";
import { Subscription } from "rxjs";
import { SphereSubscribeStripeComponent, UploadWareComponent } from "../modals";
import { Location } from "@angular/common";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-main-exchange",
  templateUrl: "main-exchange.component.html",
})
export class MainExchangeComponent extends BaseComponent implements OnInit, OnDestroy {
  displayedSection: string = "featured";
  categoriesSubscription: Subscription;
  displayedCategory: any;
  displayedWares: any;
  browseCategories: any;
  loading: boolean = false;
  paging: boolean = false;
  page: number = 1;
  limit: number = 25;
  searching: boolean = false;
  query: string;
  searchSubscription: Subscription;
  searchResults: Array<any>;
  windowWidth: number = window.innerWidth;
  featuredCreators: Array<any>;
  categoryParam: string;
  showBrowseCategories: boolean = false;
  @ViewChild("browseExchange") browseExchange: BrowseExchangeComponent;
  @ViewChild("contentContainer") contentContainer: ElementRef;

  constructor(
    private storageService: StorageService,
    public userService: UserService,
    public productService: ProductService,
    private _win: WindowService,
    private _route: ActivatedRoute,
    private _router: Router,
    public exchangeService: ExchangeService,
    public appService: AppService,
    public eventBusService: EventBusService,
    private location: Location
  ) {
    super(userService);
  }

  ngOnInit() {
    this.appService.title = this.user.active_subscription ? this.env.studioOnePlusBrandName + ": Exchange" : "PreSonus: Exchange";
    this.displayedSection = this._route.snapshot.params["category"];
    if (this._route.snapshot.params["exchangecategory"]) {
      this.categoryParam = this._route.snapshot.params["exchangecategory"];
    }
    this.switchSection(this.displayedSection || "browse");
    this._win.setTimeout(() => {
      if (this.appService.scrolledContentOffsets.exchange) this.contentContainer.nativeElement.scrollTop = this.appService.scrolledContentOffsets.exchange;
    }, 250);
  }

  viewExchangeDetail(id) {
    this._router.navigate(["exchange/detail/", id]);
  }

  go(item_id) {
    this._router.navigate(["exchange/detail/", item_id]);
  }

  exchangeInfo() {
    this.eventBusService.emit(this.eventBusService.types.playVideo, {
      id: "CZFt4C0AGn8",
      title: "Exchange in Studio One - Next-level Presets, Sound Sets, & Features from users, for users!",
      type: "youtube",
      thumb: "https://i3.ytimg.com/vi/CZFt4C0AGn8/hqdefault.jpg",
    });
  }

  switchSection(section) {
    this.displayedSection = section;
    if (section == "myfavorites") {
      this.limit = 10;
      this.page = 1;
      this.displayedCategory = { title: "myfavorites" };
      this.loadFavorites();
      this.showBrowseCategories = false;
      this.location.replaceState("exchange/" + section);
      // this._router.navigate(["exchange/", section], { replaceUrl: true });
    } else if (section == "featured") {
      this.loadFeaturedCreators();
      this.showBrowseCategories = false;
      this.location.replaceState("exchange/" + section);
    } else if (section == "browse") {
      let category = "all";
      if (this.exchangeService.lastViewedCategoryArgs) {
        category = this.exchangeService.lastViewedCategoryArgs.category;
      } else if (this.categoryParam) {
        category = this.categoryParam;
      }
      this.location.replaceState("exchange/browse/" + category);
      // this._router.navigate(
      //   ["exchange/browse/", this.categoryParam ? this.categoryParam : this.exchangeService.lastViewedCategoryArgs ? this.exchangeService.lastViewedCategoryArgs.category : "all"],
      //   { replaceUrl: true }
      // );
      this._win.setTimeout(() => {
        this.showBrowseCategories = true;
      }, 100);
    } else {
      this.showBrowseCategories = false;
      this.location.replaceState("exchange/" + section);
    }
  }

  contentContainerScrolled(args) {
    this.appService.scrolledContentOffsets.exchange = this.contentContainer.nativeElement.scrollTop;
  }

  loadFavorites(event?) {
    this.loading = true;
    this.exchangeService.getFavorites().subscribe((result) => {
      this.loading = false;
      this.displayedWares = result;
    });
  }

  loadFeaturedCreators() {
    this.loading = true;
    this.exchangeService.getFeaturedCreators().subscribe((result) => {
      this.loading = false;
      this.featuredCreators = result;
    });
  }

  uploadWare() {
    this.appService.showModal(UploadWareComponent, { size: "lg", ariaLabelledBy: "modal-title" });
  }

  goToCreator(id) {
    this._router.navigate(["profile/" + id, "exchangecontent"]);
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }

  ngOnDestroy() {}
}
