import { Component, OnInit, Input, Output, EventEmitter, HostListener } from "@angular/core";

import { RegisterProductBaseComponent } from "@mypxplat/xplat/features";
import { StorageService, UserService, ProductService, WindowService, environment, StorageKeys, HelperService } from "@mypxplat/xplat/core";
import { NgbActiveModal, NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
// import * as moment from 'moment';
import { format } from "date-fns";
import { Router } from "@angular/router";
import { Product } from "@mypxplat/xplat/core";
import { AppService, FirebaseService } from "@mypxplat/xplat/web/core";
import { RedeemHardwareOfferComponent } from "../redeem-hardware-offer/redeem-hardware-offer.component";
import { DatePickerComponent, RegisterSuccessSplashComponent } from "..";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { take } from "rxjs/operators";

@Component({
  selector: "myp-register-product",
  templateUrl: "register-product.component.html",
})
export class RegisterProductComponent extends RegisterProductBaseComponent implements OnInit {
  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    if (this.registering) {
      $event.returnValue = "Please wait until your product has been successfully registered before closing this window.";
    }
  }

  @Input() optionCardOptions: any;
  @Input() autoNavigate: boolean = true;
  @Input() mustang: boolean = false;
  // @Input() asset_id: number;
  @Output() productRegistered: EventEmitter<any> = new EventEmitter();
  private shortMask: any = [/\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/];
  private longMask: any = [
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
  ];
  public mask = this.longMask;
  public maxDate: NgbDateStruct;
  public date: Date;
  public registering: boolean = false;
  public registerResponse: any;
  public dismissMessage: string;
  public registeredProduct: any;
  public licenseResult: any;
  public loadingProgressMessage: string = "Registering your product...";
  public errorMsg: string;
  public rewards: Array<any>;
  private _audioBoxSerialNumberFail: string;

  constructor(
    userService: UserService,
    public productService: ProductService,
    public storageService: StorageService,
    public helperService: HelperService,
    private _router: Router,
    public appService: AppService,
    public activeModal: NgbActiveModal,
    private _translate: TranslateService,
    private _win: WindowService,
    public db: AngularFirestore,
    public fbService: FirebaseService
  ) {
    super(userService);
  }

  ngOnInit() {
    const year = parseInt(format(new Date(), "yyyy"));
    const month = parseInt(format(new Date(), "M"));
    const day = parseInt(format(new Date(), "d"));
    if (this.mustang) this.selectedType = "hardware";
    this.maxDate = new NgbDate(year, month, day);
    if (this.optionCardOptions) {
      this.selectedType = "hardware";
      this.selectedOptionCardAssetId = this.optionCardOptions.asset_ids[0].asset_id;
    }
    if (this.mustang) this.loadingProgressMessage = "Redeeming Studio One+...";
    this._translate.get("products.register.register_ab_fail").subscribe((lang) => {
      this._audioBoxSerialNumberFail = lang;
    });
  }

  keyPressed(args) {
    let tester = /\w/;
    // dont do anything if its a single key and its not alphanumeric.
    if (args.key.length == 1 && !tester.test(args.key)) {
      return false;
    } else if (this.productKey) {
      let rawKey = this.productKey.replace(/_|-/g, "");
      if (args.key == "Backspace" && rawKey.length == 21) {
        this.mask = this.shortMask;
      } else if (rawKey.length > 19) {
        this.mask = this.longMask;
      } else {
        this.mask = this.shortMask;
      }
    }
  }

  resetRegisterFlow(type) {
    this.registeredProduct = undefined;
    this.registering = false;
    this.registerResponse = undefined;
    this.dismissMessage = undefined;
    this.productKey = "";
    if (type == "hardware") {
      this.selectedType = "hardware";
    } else {
      this.selectedType = "software";
    }
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", keyboard: true, ariaLabelledBy: "modal-title" });
    modalInstance.componentInstance.dateSelected.subscribe((date) => {
      this.date = date;
    });
  }

  performRegister() {
    if (this.productKey) {
      let rawKey = this.productKey.replace(/_|-/g, "");

      const lowerSerial = rawKey.toLowerCase();
      if (lowerSerial.substring(2, 0) == "u3") {
        if (lowerSerial.substring(2, 3) == "o" || lowerSerial.substring(3, 4) == "1" || lowerSerial.substring(3, 4) == "l" || lowerSerial.substring(4, 5) == "o") {
          if (!confirm(this._audioBoxSerialNumberFail)) {
            return false;
          }
        }
      }
      if (this.productKey.includes("-____-____")) {
        this.productKey = this.productKey.slice(0, -10);
      }

      if (this.selectedType == "hardware" || rawKey.length == 20 || rawKey.length == 28) {
        if (this.selectedType == "software") {
          this.registering = true;
          this.productService.registerSoftware(this.productKey).subscribe({
            next: (result: any) => {
              if (result.result && result.result == "error") {
                alert(result.message_plain);
                this.registering = false;
              } else {
                this.loadingProgressMessage = "Done! Refreshing your user data...";
                this.userService.getUserDetails(false).subscribe((userResult) => {
                  this.userService.getSubscriptionDetails(true).subscribe((subResult) => {
                    if (!this.userService.isEmployeeOrDealer && this.user.active_subscription && this.appService.app != "sphere") {
                      this.storageService.removeItem(StorageKeys.TOKEN);
                      this.storageService.removeItem(StorageKeys.USER);
                      window.location.href = environment.mypresonusUrl + "welcome";
                    } else {
                      this.loadingProgressMessage = "Almost done, refreshing your product data...";
                      this.productService.getProducts(true).subscribe((updatedProducts) => {
                        this.productService.getLicenseDetails(result.assetId || result.licenseId).subscribe((licenseResult) => {
                          this.licenseResult = licenseResult;
                          this.registeredProduct = this.productService.productMap[this.licenseResult.product?.id];
                          let splashId = undefined;
                          if (this.registeredProduct) splashId = this.registeredProduct.stringId;
                          this.registerResponse = result;

                          this.productRegistered.next(result);
                          this.registering = false;
                          let code = result.processed_case || null;
                          if (result.productCategory == "subscription") splashId = "sphere";
                          this.checkForSplash(splashId, null, code, "software").then((hasSplash) => {
                            if (!hasSplash) {
                              this.checkReward();
                              this.dismissMessage = "Software successfully registered.";
                            }
                            if (this.autoNavigate) this.viewRegisteredProduct(this.licenseResult.product.id);
                          });
                        });
                      });
                    }
                  });
                });
              }
            },
            error: (error) => {
              this.registering = false;
              this.appService.alertError(error);
            },
          });
        } else {
          if (this.date || this.mustang) {
            this.registering = true;
            let method: any;
            if (this.mustang) {
              method = this.productService.registerFenderPromo(rawKey);
            } else {
              method = this.productService.registerHardware(rawKey, format(this.date, "MM/dd/yyyy"), this.selectedOptionCardAssetId, !this.selectedOptionCardAssetId);
            }

            method.subscribe({
              next: (result: any) => {
                this.productService.getProducts(true).subscribe((updatedProducts) => {
                  this.userService.getSubscriptionDetails(true).subscribe((subResult) => {
                    if (result.productCategory == "subscription") {
                      this.checkForSplash("sphere", null, result.processed_case, "software").then((hasSplash) => {
                        if (!hasSplash) {
                          this.checkReward();
                          this.dismissMessage = "Software successfully registered.";
                        }
                      });
                    } else {
                      this.productService.getLicenseDetails(result.assetId || result.licenseId).subscribe((licenseResult) => {
                        this.licenseResult = licenseResult;
                        this.registeredProduct = this.productService.productMap[this.licenseResult.product.id];
                        this.registerResponse = result;
                        let code = result.giveAwayIds && result.giveAwayIds.length ? result.giveAwayIds[0].processed_case : null;
                        this.checkForSplash(this.registeredProduct.stringId, this.licenseResult.license.offers, code).then((hasSplash) => {
                          if (!hasSplash) {
                            this.registerResponse = result;
                            this.checkReward();
                            this.productRegistered.next(result);
                            this.registering = false;
                            this.dismissMessage = "Hardware successfully registered.";
                            if (this.licenseResult.license.offers && this.licenseResult.license.offers.length) {
                              this.activeModal.close();
                              const modalRef = this.appService.showModal(RedeemHardwareOfferComponent, { size: "lg" });
                              modalRef.componentInstance.parentProduct = this.registeredProduct;
                              modalRef.componentInstance.offers = this.licenseResult.license.offers;
                              modalRef.componentInstance.license = this.licenseResult.license;
                              modalRef.componentInstance.redeemed.subscribe((result) => {});
                            }
                          }
                          if (this.autoNavigate) this.viewRegisteredProduct(this.registeredProduct.id);
                        });
                      });
                    }
                  });
                });
              },
              error: (error) => {
                this.registering = false;
                if (this.helperService.retrieveErrorMessage(error) == "Serial not found." && this.mustang) {
                  this.errorMsg =
                    "We are unable to validate the serial number you entered. Please try again or contact <a href='https://support.fender.com/en-US/contact-general/' target='_blank'>Fender Support</a>.";
                } else {
                  this.errorMsg = this.helperService.retrieveErrorMessage(error);
                }
              },
            });
          } else {
            this._translate.get("products.register.enter_required").subscribe((lang) => alert(lang));
          }
        }
      } else {
        this._translate.get("products.register.make_sure_key").subscribe((lang) => alert(lang));
      }
    } else {
      this._translate.get("products.register.enter_required").subscribe((lang) => alert(lang));
    }
  }

  checkReward() {
    if (this.registerResponse?.reward) {
      this.rewards = [];
      for (var i in this.registerResponse.reward) {
        this.rewards.push(this.registerResponse.reward[i]);
      }
    }
  }

  checkForSplash(stringid, offers?, code?, registrationType?) {
    return new Promise((resolve, reject) => {
      if (!stringid) {
        resolve(false);
      }
      this.fbService
        .handleFirestorePromise(() =>
          this.db
            .collection("product_splashes")
            .doc(stringid + (!this.env.production ? "_dev" : ""))
            .ref.get()
        )
        .then((regularResult) => {
          if (this.userService.subDetails.active) {
            this.db
              .collection("product_splashes")
              .doc(stringid + "_sphere" + (!this.env.production ? "_dev" : ""))
              .ref.get()
              .then((sphereResult) => {
                if (sphereResult.exists) {
                  let sphereData: any = sphereResult.data();
                  sphereData.string_id = sphereResult.id;
                  this.showSplash(sphereData, offers, code, registrationType);
                  resolve(true);
                } else if (regularResult.exists) {
                  let regularData: any = regularResult.data();
                  regularData.string_id = regularResult.id;
                  this.showSplash(regularData, offers, code, registrationType);
                  resolve(true);
                } else {
                  // if theres a code, that means they got some sort of bundled studio one+ time, so show
                  // them the studio one+ splash if one for their hardware doesn't exist.
                  if (code) {
                    this.db
                      .collection("product_splashes")
                      .doc("sphere" + (!this.env.production ? "_dev" : ""))
                      .ref.get()
                      .then((s1PlusSplashResult) => {
                        let s1PlusSplashData: any = s1PlusSplashResult.data();
                        s1PlusSplashData.string_id = s1PlusSplashResult.id;
                        this.showSplash(s1PlusSplashData, offers, code, registrationType);
                        resolve(true);
                      });
                  } else {
                    resolve(false);
                  }
                }
              })
              .catch(() => {});
          } else {
            if (regularResult.exists) {
              let regularData: any = regularResult.data();
              regularData.string_id = regularResult.id;
              this.showSplash(regularData, offers, code, registrationType);
              resolve(true);
            } else {
              // if theres a code, that means they got some sort of bundled studio one+ time, so show
              // them the studio one+ splash if one for their hardware doesn't exist.
              if (code) {
                this.db
                  .collection("product_splashes")
                  .doc("sphere" + (!this.env.production ? "_dev" : ""))
                  .ref.get()
                  .then((s1PlusSplashResult) => {
                    let s1PlusSplashData: any = s1PlusSplashResult.data();
                    s1PlusSplashData.string_id = s1PlusSplashResult.id;
                    this.showSplash(s1PlusSplashData, offers, code, registrationType);
                    resolve(true);
                  });
              } else {
                resolve(false);
              }
            }
          }
        })
        .catch(() => {});
    });
  }

  showSplash(splash, offers?, code?, registrationType = "hardware") {
    this.activeModal.close();
    if (code && this.userService.subDetails?.subscription?.bundle_time) {
      let translateKeyString = "hardware_bundle.processed_codes." + registrationType + "." + code;
      let bundleTextToInject = this._translate.instant(translateKeyString);
      let months = this.userService.subDetails.subscription.bundle_time;
      bundleTextToInject = bundleTextToInject.replace(/{{ months }}/g, months);
      let endDate = format(new Date(this.userService.subDetails.subscription.end_date), "MMMM do, yyyy");
      bundleTextToInject = bundleTextToInject.replace(/{{ endDate }}/g, endDate);
      if (this.userService.subDetails?.stripe_subscription?.discount?.coupon?.percent_off) {
        bundleTextToInject = bundleTextToInject.replace(/{{ percentDiscount }}/g, this.userService.subDetails.stripe_subscription.discount.coupon.percent_off);
      }
      const parser = new DOMParser();
      const doc = parser.parseFromString(splash.description, "text/html");
      const bundleTimeDescriptorDiv = doc.getElementById("bundle_time_descriptor");
      if (bundleTimeDescriptorDiv) {
        bundleTimeDescriptorDiv.innerHTML = bundleTextToInject;
        splash.description = doc.body.innerHTML;
      }
    }
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.productId = this.registeredProduct?.id;
    modalRef.componentInstance.productName = this.registeredProduct?.title;
    modalRef.componentInstance.isNewRegistration = true;

    modalRef.componentInstance.closed.pipe(take(1)).subscribe((result) => {
      if (offers && offers.length) {
        const modalRef = this.appService.showModal(RedeemHardwareOfferComponent, { size: "lg" });
        modalRef.componentInstance.parentProduct = this.registeredProduct;
        modalRef.componentInstance.license = this.licenseResult.license;
        modalRef.componentInstance.offers = offers;
        modalRef.componentInstance.redeemed.subscribe((result) => {});
      }
    });
  }

  viewRegisteredProduct(productId) {
    this._router.navigate(["products/detail/", productId]);
    this.activeModal.close();
  }

  close(event) {
    event.stopPropagation();
    this.activeModal.close();
  }
}
