import { Component, Input } from "@angular/core";
import ConfettiGenerator from "confetti-js";
import { BaseComponent, ProductService, UserService, WindowService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "myp-studioone6-grace-period",
  templateUrl: "studioone6-grace-period.component.html",
})
export class Studioone6GracePeriodComponent extends BaseComponent {
  public successRedeems: Array<any> = [];

  constructor(userService: UserService, private _win: WindowService, public activeModal: NgbActiveModal, public productService: ProductService, public router: Router) {
    super(userService);
  }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("clear-modal-content");
    this._win.setTimeout(() => {
      var confettiSettings = {
        target: "confetti",
        max: "80",
        size: "1",
        animate: true,
        props: ["circle", "square", "triangle", "line"],
        rotate: true,
        start_from_edge: true,
        respawn: false,
      };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }, 800);
  }

  viewS16(product) {
    this.router.navigate(["products/detail/", product.s16Id]);
    this.activeModal.close();
  }

  redeem(product) {
    product.redeeming = true;
    this.productService.redeemProduct(this.env.product_ids[product.stringId.indexOf("artist") > -1 ? "s16artist" : "s16pro"], false, product.id, [product.id]).subscribe((result) => {
      this.productService.getProducts(true).subscribe(() => {
        product.redeeming = false;
        product.redeemed = true;
        product.s16Id = result.asset.licenseId;
        this.successRedeems.push(product);
      });
    });
  }
}
