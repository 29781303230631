import { Component, Input, OnInit } from "@angular/core";

import { ForgotpasswordBaseComponent } from "@mypxplat/xplat/features";
import { UserService, WindowService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Auth } from "aws-amplify";

@Component({
  selector: "myp-forgotpassword",
  templateUrl: "forgotpassword.component.html",
})
export class ForgotpasswordComponent extends ForgotpasswordBaseComponent implements OnInit {
  @Input() resetPasswordWithEmail: string;

  constructor(public userService: UserService, public activeModal: NgbActiveModal, windowService: WindowService, public translateService: TranslateService) {
    super(userService, windowService, translateService);
  }

  ngOnInit() {
    if (this.resetPasswordWithEmail) this.email = this.resetPasswordWithEmail;
    this.done.pipe(takeUntil(this.destroy$)).subscribe((isDone) => {
      if (isDone) {
        this.activeModal.close();
      }
    });
  }

  resolved(captchaResponse) {
    this.captcha = captchaResponse;
  }

  sendCode() {
    if (this.email) {
      Auth.forgotPassword(this.email)
        .then((data) => (this.emailSent = true))
        .catch((err) => {
          if (err.code == "LimitExceededException") {
            alert("You have exceeded your tries in resetting your password. Please try again later.");
          } else if (err.code == "InvalidParameterException") {
            alert("Your email does not exist in our system or it has not been verified.");
          } else {
            alert(err.message);
          }
        });
    }
  }

  submit() {
    if (this.newPassword == this.newPassword2 && this.checkPassword()) {
      this.loading = true;
      Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword)
        .then((result) => {
          this.success = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.code == "LimitExceededException") {
            alert("You have exceeded your tries in resetting your password. Please try again later.");
          } else if (err.code == "CodeMismatchException") {
            alert("The code you entered is invalid.");
          } else {
            alert(err.message);
          }
        });
    } else {
      alert("Passwords do not match or your new password is not strong enough.");
    }
  }

  checkPassword() {
    this.passwordPasses = this.userService.passwordTester.test(this.newPassword);
    return this.passwordPasses;
  }
}
