import { Component, OnInit, AfterViewInit, Input } from "@angular/core";

import { BaseComponent, CommunityService, environment, HelperService, ProductService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppService, AvoService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { Intercom } from "ng-intercom";
import anime from "animejs/lib/anime.es.js";
import { TranslateService } from "@ngx-translate/core";
import { filter, take, takeUntil, throttle } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { interval } from "rxjs";
import { CloudNavOverlayComponent, UploadDetailsComponent } from "@mypxplat/xplat/web/features";

@Component({
  selector: "myp-cloud-nav",
  templateUrl: "cloud-nav.component.html",
})
export class CloudNavComponent extends BaseComponent implements OnInit {
  animateMe: boolean;
  @Input() logo: string = "./assets/icons/plus-icon-pink.png";
  @Input() textLogo: string;
  public currentLanguage: any;
  public totalUploadPercentage: any;
  public uploadingFileCount: number;
  public showNotifications: boolean = false;
  public environment = environment;
  constructor(
    userService: UserService,
    public router: Router,
    public appService: AppService,
    public betaService: BetaService,
    private _win: WindowService,
    public intercom: Intercom,
    private _translateService: TranslateService,
    public helperService: HelperService,
    public workspaceService: WorkspaceService,
    public fbAuth: AngularFireAuth,
    public communityService: WebCommunityService,
    public productService: ProductService,
    public avoService: AvoService
  ) {
    super(userService);
    this._translateService.onLangChange.subscribe((result) => {
      this.currentLanguage = this.helperService.languages.filter((lang) => lang.code == result.lang)[0];
    });
    this.userService.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((result) => {
        if (this.helperService.selectedLanguage) {
          this.currentLanguage = this.helperService.selectedLanguage;
        } else if (this.user.language_code) {
          this.currentLanguage = this.helperService.languages.filter((lang) => lang.code == this.user.language_code)[0];
        }
        if (!this.currentLanguage) {
          this.helperService.selectedLanguage = this.helperService.languages.filter((lang) => lang.code == "en")[0];
          this.currentLanguage = this.helperService.selectedLanguage;
          if (this.user) {
            this.userService
              .saveUser({
                id: this.user.id,
                language_code: "en",
              })
              .subscribe((result) => {});
          }
        }
      });
  }

  ngOnInit() {
    this._win.setTimeout(() => {
      if (!this.userService.isInWelcomeFlow && this.userService.user.active_subscription && environment.intercom) {
        this.intercom.boot({
          name: this.user.firstName + " " + this.user.lastName,
          email: this.user.email,
          user_id: this.user.id,
          active_subscription: this.user.active_subscription,
          widget: {
            activator: "#intercom",
          },
        });
      }
      this.animateMe = true;
    }, 350);

    this.workspaceService.fileUploadSessionCompleted$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.uploadingFileCount = 0;
        this.totalUploadPercentage = undefined;
      }
    });

    this.workspaceService.fileUploadProgressUpdate$
      .pipe(
        takeUntil(this.destroy$),
        throttle(
          () => {
            return interval(200);
          },
          { leading: true, trailing: true }
        )
      )
      .subscribe((result) => {
        this.uploadingFileCount = Object.keys(this.workspaceService.uploadingFilesSessionMap).length;
        if (this.uploadingFileCount > 0) {
          let progresses = [];
          let total = 0;
          Object.values(this.workspaceService.uploadingFilesSessionMap).forEach((item: any) => {
            total += item.progress;
            progresses.push(item.progress);
          });
          this.totalUploadPercentage = (total / this.uploadingFileCount).toFixed(0);
        } else {
          this.uploadingFileCount = 0;
          this.totalUploadPercentage = undefined;
        }
      });
  }

  profilePhotoClicked(section = "about") {
    this.router.navigate(["/profile", this.user.id, section], { replaceUrl: true });
  }

  downloadDetails() {
    this.appService.showModal(UploadDetailsComponent, { size: "lg" });
  }

  logout() {
    this.router.navigate(["/logout"]);
  }

  hamburgerClick() {
    this.appService.showModal(CloudNavOverlayComponent, { size: "sm", ariaLabelledBy: "modal-title" });
  }

  trackPageEvent(tabName) {
    this.avoService.trackEvent().navigationClicked({
      siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
      navigationLabel: tabName,
    });
  }
}
