import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, HelperService, environment, ProductService } from "@mypxplat/xplat/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";
import { SubscribeComponent, SphereSubscribeStripeComponent } from "@mypxplat/xplat/web/features";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "myp-edit-profile",
  templateUrl: "edit-profile.component.html",
})
export class EditProfileComponent extends BaseComponent implements OnInit {
  public displayedSection;
  public env = environment;

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    private _router: Router,
    private _route: ActivatedRoute,
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public productService: ProductService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.displayedSection = this._route.snapshot.params["category"];
    this.changeCategory(this.displayedSection, false);
  }

  changeCategory(section, nav = true) {
    this.displayedSection = section;
    if (nav) this._router.navigate(["account/", section], { replaceUrl: true });
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }
}
