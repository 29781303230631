import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContentService, environment, HelperService, UserService } from "@mypxplat/xplat/core";

import { CourseDetailBaseComponent } from "@mypxplat/xplat/features";
import { AppService } from "@mypxplat/xplat/web/core";
import { SphereSubscribeStripeComponent } from "../modals";

@Component({
  selector: "myp-course-detail",
  templateUrl: "course-detail.component.html",
})
export class CourseDetailComponent extends CourseDetailBaseComponent implements OnInit {
  constructor(
    userService: UserService,
    public activatedRoute: ActivatedRoute,
    public contentService: ContentService,
    public helperService: HelperService,
    public appService: AppService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.contentService.getEducationData().subscribe((result) => {
      this.courseId = this.activatedRoute.snapshot.params["id"];
      this.course = this.contentService.courseMap[this.courseId];
    });
  }

  pin(enroll = true) {
    let args: any = {
      user_id: this.user.id,
      learn_group_id: this.course.id,
      enrolled: enroll,
      date_started: new Date(),
    };

    this.contentService.saveUserLearnGroupData(args).subscribe({
      next: (result) => {},
      error: (error) => {
        this.appService.alertError(error);
      },
    });
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }
}
