import { inject, Injectable } from "@angular/core";
import { EventBusService, LogService, UserService } from "@mypxplat/xplat/core";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import Bugsnag from "@bugsnag/js";
@Injectable()
export class WebErrorHandler extends BugsnagErrorHandler {
  log = inject(LogService);

  constructor(private eventBusService: EventBusService, public userService: UserService) {
    super();
  }

  handleError(error: any) {
    // catch all throw's in the callstack and handle them here.
    this.log.debug("error", error);
    if (error && error.status && (error.status == 403 || error.status == 401)) {
      if (error.url && error.url.indexOf("paeapi") > -1) {
        this.userService.tokenExpired$.next(true);
      }
    } else if (error.error && error.error.error) {
      const errorMsg = error.error.error;
      if (errorMsg == "Your access token is invalid or has expired.") {
        this.userService.tokenExpired$.next(true);
      }
    } else if (error.message && error.message.indexOf("ChunkLoadError") > -1) {
      this.eventBusService.emit(this.eventBusService.types.showNewCodeAlert, true);
    } else {
      // report the error to bugsnag
      this.log.info("Submitting to Bugsnag.");
      super.handleError(error);
    }
  }

  reportError(error) {
    Bugsnag.notify(error);
  }
}
