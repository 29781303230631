import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UserService, ProductService, WindowService, environment, HelperService, BaseComponent, CheckoutService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { User } from "@mypxplat/xplat/core";
import { NgxSpinnerService } from "ngx-spinner";
import moment from "moment";
import { ReplaySubject, Subject, filter, takeUntil } from "rxjs";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { CreateTicketComponent, RegisterSuccessSplashComponent, StripePaymentComponent } from "@mypxplat/xplat/web/features";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare var Stripe;

@Component({
  selector: "myp-sphere-subscribe-stripe",
  templateUrl: "sphere-subscribe-stripe.component.html",
})
export class SphereSubscribeStripeComponent extends BaseComponent implements OnInit {
  @Input() term: string = "monthly";
  @Input() coupons: any;
  @Input() scheduleDetails: any;
  @Input() isModal: boolean = true;
  @ViewChild("stripePaymentCmp") stripePaymentCmp: StripePaymentComponent;
  public mask: any = [
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
  ];
  public purchaseConfirmed: boolean;
  public showKeyField: boolean = false;
  public coupon: string;
  public employeeCoupon: string;
  public applyingEmployeeCoupon: boolean;
  public selectedCard: any;
  public env = environment;

  public checkoutStartedTracked: boolean = false;

  // public orderCompletedTrackingArgs: any;

  public loadingMsg: string;
  public percentDone: number;
  public priceList: any;
  public showHelptext: boolean = false;
  public activeOffers: any;
  public selectedActiveOffer: any;
  public subscription: any;
  public error: any;
  public productKey: string;
  public errorMessage: string;
  public isSubscribed: boolean = false;
  public hideMonthlyOption: boolean = false;
  public showScheduleOptions: boolean;
  constructor(
    public userService: UserService,
    public productService: ProductService,
    private _spin: NgxSpinnerService,
    public appService: AppService,
    public avoService: AvoService,
    public win: WindowService,
    public helperService: HelperService,
    public router: Router,
    public db: AngularFirestore,
    public fbService: FirebaseService,
    private modalService: NgbModal,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit(): void {
    this.showScheduleOptions = this.activatedRoute.snapshot.queryParams["showScheduleOptions"];
    if (this.env.features.checkout_page && !this.showScheduleOptions) this.purchaseConfirmed = true;
    if (this.checkoutService.selectedS1PlusPlan) this.term = this.checkoutService.selectedS1PlusPlan;
    if (this.activatedRoute.snapshot.queryParams["term"]) this.term = this.activatedRoute.snapshot.queryParams["term"];
    if (this.checkoutService.coupons) this.coupons = this.checkoutService.coupons;
    if (this.checkoutService.scheduleDetails) this.scheduleDetails = this.checkoutService.scheduleDetails;

    if (this.coupons) this.checkoutService.coupons = this.coupons;
    if (this.scheduleDetails && this.userService.subDetails?.subscription?.subscription_levels_id == 2) {
      this.hideMonthlyOption = true;
    } else if (this.scheduleDetails && this.userService.subDetails?.subscription?.subscription_levels_id == 1 && this.userService.subDetails?.scheduled_stripe_subscription) {
      this.hideMonthlyOption = true;
    }
    if (this.hideMonthlyOption) this.term = "annual";

    this.checkoutService
      .initCheckout({
        stripe: Stripe(environment.stripe.public_key),
        title: "Studio One+",
        couponParam: this.activatedRoute.snapshot.queryParams["coupon"],
        source: this.activatedRoute.snapshot.queryParams["source"],
        scheduleDetails: this.scheduleDetails,
      })
      .then(() => {
        this.initSubscribeStep();
      });

    this.checkoutService.checkoutComplete$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result.trackingData) this.avoService.trackEvent().orderCompleted({ ...result.trackingData });
      this.subscribeSuccess();
    });
  }

  initSubscribeStep() {
    this.checkoutService.loadS1PlusPrices(this.term && (this.term == "monthly" || this.term == "annual") ? this.term : "monthly").then((result) => {
      this.trackCheckoutStarted();
    });
  }

  applyEmployeeCoupon() {
    if (this.userService.isEmployee && this.employeeCoupon) {
      this._spin.show();
      this.productService.employeeRedeemSphere(this.employeeCoupon).subscribe(
        (result) => {
          this.userService.getUserDetails(false).subscribe((result: User) => {
            this._spin.hide();
            if (this.user.active_subscription) {
              this.isSubscribed = true;
              this._spin.hide();
              this.subscribeSuccess();
              this.appService.initApp();
            }
          });
        },
        (error) => {
          this._spin.hide();
          this.checkoutService.checkoutError = {
            type: "coupon_error",
            message: this.helperService.retrieveErrorMessage(error),
          };
        }
      );
    }
  }

  trackCheckoutStarted() {
    if (!this.checkoutStartedTracked) {
      this.checkoutStartedTracked = true;
      this.avoService.avoInitted$.pipe(filter((result) => !!result)).subscribe((result) => {
        this.avoService.trackEvent().checkoutStarted({
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          locale: (this.user?.language_code || "en") + "-" + (this.user?.country || "US"),
          products: [
            {
              price: parseFloat(
                (
                  this.checkoutService.s1PlusPrices[this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly"].price -
                  this.checkoutService.s1PlusPrices[this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly"].tax
                ).toFixed(2)
              ),
              sku: environment.product_ids["sphere_" + (this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly")].toString(),
              productId: environment.product_ids["sphere_" + (this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly")].toString(),
              quantity: 1,
              name: "Studio One Plus",
            },
          ],
          countryCode: this.user.country,
          total: this.checkoutService.s1PlusPrices[this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly"].price,
          price: parseFloat(
            (
              this.checkoutService.s1PlusPrices[this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly"].price -
              this.checkoutService.s1PlusPrices[this.checkoutService.selectedS1PlusPlan ? this.checkoutService.selectedS1PlusPlan : "monthly"].tax
            ).toFixed(2)
          ),
          quantity: 1,
        });
      });
    }
  }

  confirmPurchase() {
    this.purchaseConfirmed = true;
  }

  registerKey() {
    if (this.productKey) {
      let rawKey = this.productKey.replace(/_|-/g, "");
      this._spin.show();
      this.productService.registerSoftware(this.productKey).subscribe({
        next: (result: any) => {
          this.userService.getUserDetails(false).subscribe((result: User) => {
            this._spin.hide();
            if (this.user.active_subscription) {
              this.isSubscribed = true;
              this.subscribeSuccess();
              this.appService.initApp();
            }
          });
        },
        error: (error) => {
          this._spin.hide();
          this.error = {
            type: "register_key",
            message: this.helperService.retrieveErrorMessage(error),
          };
        },
      });
    } else {
      this.error = {
        type: "register_key",
        message: "Please enter a valid product key.",
      };
    }
  }

  openSupportTicket() {
    const modalRef = this.appService.showModal(CreateTicketComponent, { size: "lg", ariaLabelledBy: "modal-title", backdrop: "static" });
    modalRef.componentInstance.inputDescription = 'I received the following error when trying to checkout. "' + this.error.description + '"';
    modalRef.result.then((result) => {
      if (result.ticket_id) {
        this.error = undefined;
        window.location.href = environment.mypresonusUrl + "support/open";
      }
    });
  }

  finish() {
    this.userService.isInWelcomeFlow = false;
    window.scrollTo(0, 0);
    this.router.navigate(["/home"]);
  }

  close() {
    this.modalService.dismissAll();
  }

  subscribeSuccess() {
    this.userService.isInWelcomeFlow = false;
    this.userService.getSubscriptionDetails(true).subscribe();
    window.scrollTo(0, 0);
    this.modalService.dismissAll();
    this.router.navigate(["/home"]);
    this.fbService
      .handleFirestorePromise(() =>
        this.db
          .collection("product_splashes")
          .doc(this.env.production ? "sphere" : "sphere_dev")
          .ref.get()
      )
      .then((result) => {
        if (result.exists) {
          let splash = result.data();
          this.showSplash(splash);
        }
      })
      .catch(() => {});
  }

  showSplash(splash) {
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.productId = this.checkoutService.selectedS1PlusPlan == "annual" ? environment.product_ids.sphere_annual : environment.product_ids.sphere_monthly;
    modalRef.componentInstance.productName = "Studio One+";
  }

  ngOnDestroy(): void {
    this.checkoutService.tearDown();
  }

  keyUpRadio(e, type): void {
    switch (e.key) {
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowDown":
      case "ArrowRight":
        if (this.checkoutService.selectedS1PlusPlan == "annual") {
          this.checkoutService.selectedS1PlusPlan = "monthly";
        } else {
          this.checkoutService.selectedS1PlusPlan = "annual";
        }
        break;
      case " ":
      case "enter":
      case "space":
        this.checkoutService.selectPlan(type);
        break;
    }
  }
}
