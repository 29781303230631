import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, UserService, ProductService, Product, SimpleProduct, AdminService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { format } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AdminAddNewProductComponent } from "../../modals";

@Component({
  selector: "myp-admin-product-list",
  templateUrl: "product-list.component.html",
})
export class AdminProductListComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public products: Array<SimpleProduct>;
  public filteredProducts: Array<SimpleProduct>;
  public sections: any = [
    {
      name: "Software",
      id: "software",
      selected: true,
    },
    {
      name: "Hardware",
      id: "hardware",
    },
    {
      name: "Extensions",
      id: "extensions",
    },
    // {
    //   name: "Bundles",
    // },
  ];
  public selectedSection = this.sections[0].name;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    private _spin: NgxSpinnerService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    public location: Location
  ) {
    super(userService);
  }

  ngOnInit() {
    this.appService.preferredSortDirection = "asc";
    this._activatedRoute.params.subscribe((params) => {
      const type = params["type"] || this.sections[0].name;
      this.selectSection(type, false);
    });
    this.selectSection("software");
  }

  filterProducts(args) {
    this.filteredProducts = this.products.filter((product) => {
      return product.title.toLowerCase().indexOf(args.toLowerCase()) > -1;
    });
  }

  sortProducts() {
    this.filteredProducts.sort((a, b) => (a.title > b.title ? (this.appService.sortDirection == "asc" ? 1 : -1) : this.appService.sortDirection == "asc" ? -1 : 1));
  }

  selectSection(id, navigate = true) {
    this.selectedSection = id;
    this.sections.forEach((section) => {
      section.selected = false;
      if (section.id == id) section.selected = true;
    });
    if (navigate) this.router.navigate(["/products/list", id.toLowerCase()]);
    this.getProducts(id.toLowerCase());
  }

  getProducts(type) {
    this.loading = true;
    this.adminService.productList(type, true).subscribe((products: Array<SimpleProduct>) => {
      this.loading = false;
      this.processProducts(products);
    });
  }

  processProducts(products) {
    if (this.selectedSection != "bundles") {
      products.forEach((item: any) => {
        this.adminService.productMap[item.id] = item;
        let metadata = [];
        if (item.version) metadata.push({ title: "Version", value: item.version });
        if (item.stringId) metadata.push({ title: "Identifer", value: item.stringId });
        if (item.contractId && item.contractId.trim()) metadata.push({ title: "Contract ID", value: item.contractId });
        if (item.id) metadata.push({ title: "ID", value: item.id });
        item.metadata = metadata;
      });
    }
    this.loading = false;
    this.products = products;

    this.filteredProducts = [...products];
    this.sortProducts();
  }

  go(id) {
    this.router.navigate(["/products/detail", id]);
  }

  create() {
    const modal = this.appService.showModal(AdminAddNewProductComponent, { size: "lg" });
    modal.componentInstance.data = {
      categoryId: this.selectedSection,
    };
    modal.componentInstance.completed.subscribe((res) => {
      this.go(res.productId);
    });
  }
}
