import { Component, OnInit, Input } from '@angular/core';

import { BaseComponent, StorageService, UserService } from '@mypxplat/xplat/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'myp-footer',
  templateUrl: 'footer.component.html'
})
export class FooterComponent extends BaseComponent implements OnInit {
  public breadCrumb: string = 'Home';
  public currentYear = moment().format('YYYY')
  @Input() type: string = 'mypresonus';


  constructor(
    public storage: StorageService,
    public userService: UserService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && event.url) {
        let parts = event.url.split('/');
        this.breadCrumb = parts[1].toUpperCase();
      }
    });
  }
}
