import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-list-item",
  templateUrl: "list-item.component.html",
})
export class ListItemComponent extends BaseComponent {
  @Input() item: any;
  @Input() showImage: boolean = true;
  @Input() photo: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() alignTop: boolean = false;
  @Input() imageRound: boolean = true;
  @Input() imageBorder: boolean = true;
  @Input() largeImage: boolean = false;
  @Input() hoverState: boolean = true;
  @Input() includeBottomBorder: boolean = false;
  @Input() subData: Array<string>;
  @Input() subDataHtml: any;
  @Input() metadata: Array<{ title: string; value: string }>;
  @Input() includeChevron: boolean = true;
  @Input() inactive: boolean;
  @Input() isLink: boolean = false;
  @Output() itemClicked: EventEmitter<any> = new EventEmitter();

  constructor(userService: UserService) {
    super(userService);
  }

  itemClick(event) {
    if (event && event.target && event.target.className && event.target.className.includes && event.target.className.includes("circle-icon-btn")) return;
    setTimeout(
      () => {
        this.itemClicked.next(this.item);
      },
      event.key ? 100 : 1
    );
  }
}
