import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from "@angular/core";

import { BaseComponent, CheckoutService, environment, UserService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { UpgradeSoftwareStripeComponent } from "../modals";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "myp-product-purchase-upgrade",
  templateUrl: "product-purchase-upgrade.component.html",
})
export class ProductPurchaseUpgradeComponent extends BaseComponent implements OnInit {
  @Input() homepage: boolean = false;
  @Input() couponParam: string;
  @Input() minimumProductPrice: number;
  public discountedMinimumProductPrice: number;
  isUpgrade: boolean = false;
  activeCurrency: any;
  displayCurrency: string;
  public appliedActiveOffer: any;

  public title: string = "Purchase the latest Studio One Professional";
  constructor(
    public userService: UserService,
    public translate: TranslateService,
    public appService: AppService,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit(): void {
    if (!this.checkoutService.upgradableStudioOneProducts) {
      this.checkoutService.getUpgradableProducts(true).subscribe((result: any) => {
        if (result && result.success && result.data) {
          this.process();
        }
      });
    } else {
      this.process();
    }
  }

  process() {
    this.userService.getActiveOffers().subscribe((result: any) => {
      let applies;
      if (result.data && result.data.length) {
        result.data.forEach((coupon) => {
          let appliesToProducts = coupon.applies_to_products.split(",");
          if (appliesToProducts.includes(this.checkoutService.upgradableStudioOneProducts?.data?.products[0].stripeProductId)) {
            applies = coupon;
          }
        });
      }
      this.activeCurrency = this.checkoutService.upgradableStudioOneProducts?.data?.geo["currency-code"];
      this.displayCurrency = this.activeCurrency;
      let prices: number[] = this.checkoutService.upgradableStudioOneProducts?.data?.prices.filter((p) => p.currency === this.activeCurrency.toLowerCase()).map((p) => p.unit_amount);
      if (!prices.length) {
        this.displayCurrency = "USD";
        prices = this.checkoutService.upgradableStudioOneProducts?.data?.prices.filter((p) => p.currency === "usd").map((p) => p.unit_amount);
      }
      this.isUpgrade = this.checkoutService.upgradableStudioOneProducts?.data?.products[0].title.indexOf("Upgrade") > -1;
      this.minimumProductPrice = this.isNoDecimalCurrency(this.activeCurrency) ? Math.min(...prices) : Math.min(...prices) / 100;
      if (applies) {
        this.discountedMinimumProductPrice = this.minimumProductPrice - (this.minimumProductPrice * applies?.percentage) / 100;
        this.appliedActiveOffer = applies;
      }
      if (this.isUpgrade) this.title = "Upgrade to the latest Studio One Professional";
    });
  }

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  showRegisterUpgradeProduct() {
    if (this.env.features.checkout_page) {
      this.checkoutService.couponParam = this.couponParam;
      this.router.navigate(["/checkout"], { queryParams: { checkoutProductType: "Perpetual" } });
    } else {
      this.appService.closeModals();
      const modalRef = this.appService.showModal(UpgradeSoftwareStripeComponent, {
        size: "xl",
        backdrop: "static",
      });
      modalRef.componentInstance.couponParam = this.couponParam;
      modalRef.componentInstance.upgradableProducts = this.checkoutService.upgradableStudioOneProducts;
    }
  }
}
