import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContentService, EventBusService, HelperService, UserService } from "@mypxplat/xplat/core";

import { LessonDetailBaseComponent } from "@mypxplat/xplat/features";
import { AppService } from "@mypxplat/xplat/web/core";
import { takeUntil } from "rxjs";
import { SphereSubscribeStripeComponent } from "../modals";

@Component({
  selector: "myp-lesson-detail",
  templateUrl: "lesson-detail.component.html",
})
export class LessonDetailComponent extends LessonDetailBaseComponent {
  constructor(
    userService: UserService,
    public contentService: ContentService,
    public activatedRoute: ActivatedRoute,
    public helperService: HelperService,
    public appService: AppService,
    public eventBusService: EventBusService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.lessonId = this.activatedRoute.snapshot.params["id"];
    this.init();
    this.eventBusService
      .observe(this.eventBusService.types.videoClosed)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.contentService.getEducationData().subscribe((result) => {
          this.init();
        });
      });
  }

  init() {
    this.contentService.getEducationData().subscribe((result) => {
      this.lesson = this.contentService.lessonMap[this.lessonId];
    });
  }

  pinLesson(enroll = true) {
    let args: any = {
      user_id: this.user.id,
      learn_group_id: this.lesson.id,
      enrolled: enroll,
      date_started: new Date(),
    };

    this.contentService.saveUserLearnGroupData(args).subscribe({
      next: (result) => {},
      error: (error) => {
        this.appService.alertError(error);
      },
    });
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }
}
