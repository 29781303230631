import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@mypxplat/xplat/core';

import { PostBaseComponent } from '@mypxplat/xplat/features';
import { AppService, WebCommunityService } from '@mypxplat/xplat/web/core';
import { EditCommentComponent } from '../../modals';

@Component({
  selector: 'myp-post',
  templateUrl: 'post.component.html'
})
export class PostComponent extends PostBaseComponent {

  @Input() post: any;
  @Input() index: any;
  @Input() replies: Array<any>;
  @Output() channelSelected: EventEmitter<any> = new EventEmitter()
  @Output() deleteIndexSet: EventEmitter<any> = new EventEmitter()
  @Output() postEdited: EventEmitter<any> = new EventEmitter()

  constructor(
    userService: UserService,
    public communityService: WebCommunityService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.post.htmlMessage) {
      this.post.htmlMessage = this.appService.urlify(this.post.message);
      this.post.htmlMessage = this.post.htmlMessage.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }
  }

  delete() {
    if (confirm("Are you sure you want to delete this post? This cannot be undone.")) {
      this.deleteIndexSet.next(this.index)
      this.communityService.deletePost(this.post.key).then(result => {
      })
    }
  }

  like() {
    this.communityService.likeUnlikePost(this.post)
  }

  edit() {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg" });
    modalRef.componentInstance.comment = this.post;
    modalRef.result.then(
      (result) => {
        if (result) {
          // this.post.message = result.message;
          this.post.message = result.message;
          this.post.htmlMessage = this.appService.urlify(result.message);
          this.communityService.updatePost(this.post.key, {message: this.post.message})
          this.postEdited.next({post: this.post, index: this.index})
        }
      },
      (error) => {}
    );
  }

}