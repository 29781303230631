import { Component, EventEmitter, Output } from "@angular/core";
import { BaseComponent, HelperService, UserService, environment } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CurrencyPipe } from "@angular/common";
import { AppService } from "@mypxplat/xplat/web/core";
import { EditAddressComponent } from "../edit-address/edit-address.component";
declare var Stripe;
@Component({
  selector: "myp-confirm-plan-switch",
  templateUrl: "confirm-plan-switch.component.html",
})
export class ConfirmPlanSwitchComponent extends BaseComponent {
  @Output() planSwitched: EventEmitter<any> = new EventEmitter();
  public priceList: any;
  public isConverted: boolean;
  public proratedTotal: any;
  public formatedProratedTotal: any;
  public prorationDate: any;
  public isTaxIncluded: boolean = false;
  public convertedAmount: any;
  public loading: any = "Loading your account details...";
  public currentTerm: string;
  public switchingToTerm: string;
  public renewalDate: any;
  public normalAnnualAmount: any;
  public formattedNormalAmount: any;
  public selectedPriceId: any;
  public stripe: any;
  public clientSecret: string;
  public subscriptionId: string;
  constructor(userService: UserService, public activeModal: NgbActiveModal, private currencyPipe: CurrencyPipe, public appService: AppService, public helperService: HelperService) {
    super(userService);
  }

  ngOnInit() {
    if (this.userService.subDetails.subscription.provider == "stripe") {
      this.stripe = Stripe(environment.stripe.public_key);
      this.currentTerm = this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "Annual" : "Monthly";
      this.switchingToTerm = this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "Monthly" : "Annual";
      this.renewalDate = new Date(this.userService.subDetails.stripe_subscription.current_period_start * 1000);

      // add a year to this.renewalDate
      this.renewalDate.setFullYear(this.renewalDate.getFullYear() + 1);

      this.userService.getSpherePricesStripe().subscribe((result: any) => {
        if (result.success && result.data) {
          if (result.data.prices) {
            if (this.userService.subDetails?.stripe_subscription?.product) {
              this.priceList = result.data.prices.filter((p) => p.product === environment.stripe.subscription_product.monthly || p.product === environment.stripe.subscription_product.annual);
              let switchPrice = this.priceList.filter(
                (p) =>
                  p.currency === this.userService.subDetails.stripe_subscription.product.price.currency &&
                  p.recurring.interval === (this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "month" : "year")
              );
              if (switchPrice && switchPrice.length) {
                this.isConverted = false;
                this.isTaxIncluded = this.isConverted || this.userService.subDetails.stripe_customer.address.country === "US" ? false : true;
                this.userService.previewStripeInvoice({ price_id: switchPrice[0].id, is_tax_inclusive: this.isTaxIncluded }).subscribe({
                  next: (result: any) => {
                    if (result && result.data) {
                      let normalAmount = result.data.lines?.data?.filter((line) => line.type == "subscription")[0]?.amount;
                      this.normalAnnualAmount = this.isNoDecimalCurrency(result.data.currency)
                        ? this.isDivisibleBy100(result.data.currency)
                          ? Number((normalAmount / 100).toFixed(0))
                          : normalAmount
                        : Number((normalAmount / 100).toFixed(2));
                      this.formattedNormalAmount = this.currencyPipe.transform(this.normalAnnualAmount, result.data.currency.toUpperCase());
                      this.proratedTotal = this.isNoDecimalCurrency(result.data.currency)
                        ? this.isDivisibleBy100(result.data.currency)
                          ? Number((result.data.total / 100).toFixed(0))
                          : result.data.total
                        : Number((result.data.total / 100).toFixed(2));
                      this.formatedProratedTotal = this.currencyPipe.transform(this.proratedTotal, result.data.currency.toUpperCase());
                      this.prorationDate = result.data.subscription_proration_date;
                      this.loading = false;
                      // this.switchPlan(switchPrice[0].id);
                      this.selectedPriceId = switchPrice[0].id;
                    }
                  },
                  error: (errorObj: any) => {
                    let error = this.helperService.retrieveErrorMessage(errorObj);
                    if (error == "No address found.") {
                      const modalRef = this.appService.showModal(EditAddressComponent);
                      modalRef.componentInstance.address = {};
                      modalRef.componentInstance.message = "Please enter a valid billing address to continue.";
                      modalRef.componentInstance.addressAdded.subscribe((result) => {
                        if (result) this.ngOnInit();
                      });
                    } else {
                      alert("There was an error switching your subscription plan, please contact support.");
                    }
                  },
                });
              } else {
                this.isConverted = true;
                this.isTaxIncluded = this.isConverted || this.userService.subDetails.stripe_customer.address.country === "US" ? false : true;
                let basePrices = this.priceList.filter(
                  (p) => p.currency === "usd" && p.recurring.interval === (this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "month" : "year")
                );

                let currencyData = {
                  currency_code: this.userService.subDetails.stripe_subscription.product.price.currency,
                  convert: [],
                };
                if (basePrices && basePrices.length) {
                  basePrices.map((price) => {
                    currencyData.convert.push({
                      from: "usd",
                      value: (price.unit_amount / 100).toFixed(2),
                      interval: price.recurring.interval,
                    });
                  });

                  this.userService.currencyConvert(currencyData).subscribe((result: any) => {
                    if (result.success && result.data.every((c) => c.valid === true)) {
                      result.data.map((price) => {
                        this.convertedAmount = this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2));

                        this.userService
                          .previewStripeInvoice({
                            price_data: {
                              recurring: {
                                interval: this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "month" : "year",
                              },
                              product:
                                this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year"
                                  ? environment.stripe.subscription_product.monthly
                                  : environment.stripe.subscription_product.annual,
                              currency: this.userService.subDetails.stripe_subscription.product.price.currency, //inverse product as it checks on current interval and not updated interval
                              unit_amount:
                                this.isNoDecimalCurrency(this.userService.subDetails.stripe_subscription.product.price.currency) &&
                                !this.isDivisibleBy100(this.userService.subDetails.stripe_subscription.product.price.currency)
                                  ? this.convertedAmount
                                  : parseInt((this.convertedAmount * 100).toFixed(0)),
                              tax_behavior: "unspecified",
                            },
                            is_tax_inclusive: this.isTaxIncluded,
                          })
                          .subscribe({
                            next: (result: any) => {
                              if (result && result.data) {
                                this.proratedTotal = this.isNoDecimalCurrency(result.data.currency)
                                  ? this.isDivisibleBy100(result.data.currency)
                                    ? Number((result.data.total / 100).toFixed(0))
                                    : result.data.total
                                  : Number((result.data.total / 100).toFixed(2));
                                this.formatedProratedTotal = this.currencyPipe.transform(this.proratedTotal, result.data.currency.toUpperCase());
                                this.prorationDate = result.data.subscription_proration_date;
                                this.loading = false;
                                // this.switchPlan();
                              }
                            },
                            error: (error: any) => {
                              // this.spinner.hide();
                              alert("There was an error switching your subscription plan, please try again.");
                            },
                          });
                      });
                    }
                  });
                }
              }
            } else {
              alert("An error occurred while switching your subscription plan, please refresh your browser and try again.");
            }
          }
        }
      });
    } else if (this.userService.subDetails.subscription.provider == "recharge") {
      this.loading = false;
    }
  }

  confirm() {
    if (this.userService.subDetails.subscription.provider == "stripe") {
      let subscribeRequest = this.isConverted
        ? {
            sales_channel: "myp_upgrade",
            proration_date: this.prorationDate,
            price_data: {
              recurring: {
                interval: this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year" ? "month" : "year",
              },
              product:
                this.userService.subDetails.stripe_subscription.product.price.recurring["interval"] === "year"
                  ? environment.stripe.subscription_product.monthly
                  : environment.stripe.subscription_product.annual, //inverse product as it checks on current interval and not updated interval
              currency: this.userService.subDetails.stripe_subscription.product.price.currency,
              unit_amount:
                this.isNoDecimalCurrency(this.userService.subDetails.stripe_subscription.product.price.currency) &&
                !this.isDivisibleBy100(this.userService.subDetails.stripe_subscription.product.price.currency)
                  ? this.convertedAmount
                  : parseInt((this.convertedAmount * 100).toFixed(0)),
              tax_behavior: "unspecified",
            },
            is_tax_inclusive: this.isTaxIncluded,
          }
        : {
            price_id: this.selectedPriceId,
            sales_channel: "myp_upgrade",
            proration_date: this.prorationDate,
            is_tax_inclusive: this.isTaxIncluded,
          };

      this.loading = "Switching your subscription plan...";
      this.userService.stripeSubscriptionSwitch(subscribeRequest).subscribe({
        next: (result: any) => {
          if (result.success && result.data) {
            if (result.data.invoice) {
              setTimeout(() => {
                this.subscriptionResume(result.data.invoice);
              }, 3000);
            } else {
              this.loading = false;
              alert("There was an error switching your subscription plan, please try again.");
            }
          }
        },
        error: (error) => {
          this.loading = false;
          this.appService.alertError(error);
        },
      });
    } else if (this.userService.subDetails.subscription.provider == "recharge") {
      this.userService.switchRechargeSubscriptionToHybrid().subscribe((result) => {
        console.log(result);
        this.planSwitched.emit({ subscriptionId: this.userService.subDetails.subscription.id });
        this.activeModal.close();
      });
    }
  }

  subscriptionResume(invoiceData) {
    this.userService
      .subscriptionResume({
        invoice: invoiceData,
        transaction_type: "switch",
      })
      .subscribe({
        next: (result: any) => {
          if (result.success && result.data) {
            this.clientSecret = result.data.clientSecret;
            this.subscriptionId = result.data.subscriptionId;
            this.confirmCardPayment();
          }
        },
        error: (error) => {
          // this.spinner.hide();
          this.loading = false;
          this.appService.alertError(error);
        },
      });
  }

  confirmCardPayment() {
    this.stripe.confirmCardPayment(this.clientSecret).then((response) => {
      if (response.error) {
        this.loading = false;
        alert(response.error.message);
      } else {
        this.loading = false;
        this.planSwitched.emit({ subscriptionId: this.subscriptionId });
        this.activeModal.close();
      }
    });
  }

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
}
