import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Auth } from "aws-amplify";
@Component({
  selector: "myp-verify-email",
  templateUrl: "verify-email.component.html",
})
export class VerifyEmailComponent extends BaseComponent {
  public cognitoUser: any;
  public code: any;
  @Input() codeSent: boolean = false;
  public success: boolean = false;
  constructor(userService: UserService, public activeModal: NgbActiveModal, public router: Router) {
    super(userService);
  }

  ngOnInit() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.cognitoUser = user;
      })
      .catch((error) => {});
  }

  resend() {
    Auth.verifyUserAttribute(this.cognitoUser, "email");
    alert("Code resent. Please check your email.");
    this.codeSent = true;
  }

  typoInEmail() {
    this.router.navigate(["/account/general"]);
    this.close();
  }

  submit() {
    Auth.verifyUserAttributeSubmit(this.cognitoUser, "email", this.code)
      .then((result) => {
        this.userService.saveUser({ id: this.userService.user.id, active: true }).subscribe();
        this.success = true;
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  close() {
    this.activeModal.close();
  }
}
