import { Component, OnInit } from "@angular/core";
import { BaseComponent, UserService, ProductService, WindowService, environment, HelperService, CheckoutService, EventBusService, ProductLicenseItem, SimpleProduct } from "@mypxplat/xplat/core";
import { take, takeUntil } from "rxjs/operators";
import { AppService, AvoService, WebErrorHandler } from "@mypxplat/xplat/web/core";
import { SphereSubscribeStripeComponent, ConfirmPlanSwitchComponent, CancelSubscriptionRechargeComponent } from "../../../modals";
import { ProductDetail } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CurrencyPipe } from "@angular/common";
import { addMonths } from "date-fns";
import { Router } from "@angular/router";

declare var Stripe;

@Component({
  selector: "myp-account-plan-generic",
  templateUrl: "./plan-generic.component.html",
})
export class PlanGenericComponent extends BaseComponent implements OnInit {
  public statusMap = {
    active: { title: "Active", color: "green" },
    canceled: { title: "Canceled", color: "red" },
    unpaid: { title: "UnPaid", color: "orange" },
    past_due: { title: "Past Due", color: "orange" },
    incomplete: { title: "Incomplete", color: "orange" },
    incomplete_expired: { title: "Incomplete And Expired", color: "red" },
    trialing: { title: "Trailing", color: "orange" },
    ended: { title: "Expired", color: "gray" },
    "missed payment": { title: "Missed Payment", color: "orange" },
    expired: { title: "Expired", color: "gray" },
  };

  public subscriptionProduct: ProductLicenseItem;
  public defaultCard: any;
  public loading: boolean = true;
  public noSubscriptionFound: boolean = false;
  public nextChargeDate: any;
  public editingExpDetails: any = false;
  public expDetails: any = {};
  public proratedTotal: any;
  public formatedProratedTotal: any;
  public prorationDate: any;
  public clientSecret: string;
  public subscriptionId: string;
  public stripe: any;
  public priceList: any;
  public isConverted: boolean = false;
  public convertedAmount: any;
  public isTaxIncluded: boolean = false;
  public loadingPaymentMethod: boolean = false;
  public loadingPaymentMethodSuccess: boolean = false;
  public paymentMethodLineItemLoaders: any = {};
  public missedPaymentDetails: any;
  public hybridElligible: boolean;
  public bundleTimeProgress: any;
  public bundleDaysLeft: number;
  public today: Date;

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
  constructor(
    public userService: UserService,
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public productService: ProductService,
    private _win: WindowService,
    public translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    public avoService: AvoService,
    public helperService: HelperService,
    public errorService: WebErrorHandler,
    public checkoutService: CheckoutService,
    private _eventbusService: EventBusService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.userService.getStorageDetails().subscribe();
    this.loading = true;
    this.userService.subDetails$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.init();
    });
    this.userService.getSubscriptionDetails(true).subscribe(() => (this.loading = false));
  }

  init(callBack?) {
    this.today = new Date();
    if (this.userService.subDetails && this.userService.subDetails.active) {
      this.loading = false;

      if (this.userService.subDetails.subscription) {
        if (this.userService.subDetails.subscription.status == "missed payment") {
          this.missedPaymentDetails = this.userService.subDetails.generic_subscription_details.payment_history.filter((p) => p.status == "error")[0];
        }
        this.isTaxIncluded = this.userService.subDetails?.stripe_customer?.metadata?.IsTaxInclusive == "true" ? true : false;

        if (this.userService.subDetails.subscription.bundle_time) {
          let remaining = this.calculateFreeTimeProgress(this.userService.subDetails.subscription.start_date, this.userService.subDetails.subscription.end_date);
          if (
            this.userService.subDetails.subscription_status == "monthly_active_coupon" &&
            this.userService.subDetails.stripe_subscription.discount.start &&
            this.userService.subDetails.stripe_subscription.discount.end
          ) {
            remaining = this.calculateFreeTimeProgress(
              new Date(this.userService.subDetails.stripe_subscription.discount.start * 1000),
              new Date(this.userService.subDetails.stripe_subscription.discount.end * 1000)
            );
          }
          this.bundleTimeProgress = remaining.percentage;
          this.bundleDaysLeft = remaining.daysLeft;
        }
      }
      if (callBack) callBack();
      if (this.userService.subDetails.subscription.Asset_id) {
        this.productService.getLicenseDetails(this.userService.subDetails.subscription.Asset_id).subscribe((result: { product: SimpleProduct; license: ProductLicenseItem }) => {
          this.subscriptionProduct = result.license;
        });
      }
    }
  }

  cancelSubscription() {
    const modalRef = this.appService.showModal(CancelSubscriptionRechargeComponent, { size: "lg" });
    modalRef.componentInstance.canceled.pipe(take(1)).subscribe((result) => {
      this.avoService.trackEvent().orderCanceled({
        userId_: this.userService.subDetails.subscription.Customer_id,
        prsId: this.userService.subDetails.subscription.Customer_id,
        prsSubStatus: this.userService.subDetails.subscription.status,
        subProvider: this.userService.subDetails.subscription.provider,
        subStartDate: this.userService.subDetails.subscription.start_date,
        subEndDate: this.userService.subDetails.subscription.end_date,
        subType: this.userService.subDetails.subscription.type,
        subCancelReason: result.subCancelReason,
        subCancelDetails: result.subCancelDetails,
      });
      this.spinner.show();
      this.userService.getSubscriptionDetails(true).subscribe(() => {
        this.spinner.hide();
      });
    });
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }

  previewSwitchPlanPrice() {
    const modalRef = this.appService.showModal(ConfirmPlanSwitchComponent, { size: "lg" });
    modalRef.componentInstance.planSwitched.subscribe((_) => {
      this.spinner.show();
      this.userService.getSubscriptionDetails(true).subscribe(() => {
        this.spinner.hide();
      });
    });
  }

  deactivate(activation: any, mixer?) {
    // this._win
    //   .confirm(this.translate.instant("sphere.account.remove_activation_confirm"))
    //   .then((_) => {
    //     let args: any = {
    //       activation_id: activation.id,
    //     };
    //     if (mixer) {
    //       args.hardwareSerial = activation.hardwareSerialNumber;
    //     } else {
    //       args.productid = activation.licenseId;
    //     }
    //     activation.loading = true;
    //     this.productService.deactivateSoftware(args).subscribe((result) => {
    //       this.productService.getLicenseDetails(this.userService.subDetails.subscription.Asset_id).subscribe((result: { product: SimpleProduct; license: ProductLicenseItem }) => {
    //         activation.loading = false;
    //         this.subscriptionProduct = result.license;
    //       });
    //     });
    //   })
    //   .catch((_) => {});
  }

  reactivate() {
    // let reactivate = {
    //   subscription_id: this.userService.subDetails.stripe_subscription.id,
    //   source: "myp_upgrade",
    // };
    // this.spinner.show();
    // this.userService.reactivateSubscription(reactivate).subscribe((result: any) => {
    //   if (result.subscriptions) {
    //     this.userService.getSubscriptionDetails(true).subscribe(() => {
    //       this.spinner.hide();
    //     });
    //   }
    // });
  }
  updatePaymentMethod() {
    this.userService.sendPaymentMethodUpdateEmail().subscribe((result) => {
      alert("An email has been sent to you with instructions on how to update your payment method.");
    });
  }

  calculateFreeTimeProgress(startDateStr, endDateStr) {
    // Parse the dates from strings to Date objects
    const startDate: any = new Date(startDateStr);
    const endDate: any = new Date(endDateStr);
    const currentDate: any = new Date();

    // Calculate the total duration and the elapsed duration in milliseconds
    const totalDuration = endDate - startDate;
    const elapsedDuration = currentDate - startDate;

    // Calculate the remaining duration in milliseconds
    const remainingDuration = endDate - currentDate;

    // Convert the remaining duration from milliseconds to days
    const daysLeft = remainingDuration / (1000 * 60 * 60 * 24);

    // Calculate the percentage
    const progressPercentage = (elapsedDuration / totalDuration) * 100;

    return {
      percentage: progressPercentage.toFixed(2), // Returns the percentage rounded to two decimal places
      daysLeft: Math.ceil(daysLeft), // Rounds up to the nearest whole number to ensure a whole day is counted
    };
  }
}
