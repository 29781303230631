import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

import { BaseComponent, UserService, HelperService, ExchangeService, ContentService, ProductService, Video, WindowService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UploadFileComponent,
  AddEditFeaturedCreatorComponent,
  EditCommentComponent,
  AddEditBetaProgramComponent,
  AddEditAnnouncementComponent,
  SegmentedBarComponent,
} from "@mypxplat/xplat/web/features";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { take, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import { BetaService } from "@mypxplat/xplat/web/core";
import { AdminAddSkillComponent, AddEditProductSplashComponent, AddEditTopicComponent } from "../modals";

@Component({
  selector: "myp-shared-admin",
  templateUrl: "admin.component.html",
})
export class AdminComponent extends BaseComponent implements OnInit {
  @ViewChild("contentContainer") contentContainer: ElementRef;
  @ViewChild("moderationSegmentedBar") moderationSegmentedBar: SegmentedBarComponent;
  @Input() hideBack: boolean;
  public displayedSection;
  public wares: any = [];
  public pluginImages: any;
  public featuredCreators: Array<any>;
  public feedback: Array<any>;
  public showExport: boolean = false;
  public start_date: NgbDateStruct;
  public end_date: NgbDateStruct;
  public replyingMessageKey: string;
  public videosRef: AngularFirestoreCollection;
  public videoCommentsRefs: any;
  public videosWithComments: Array<any>;
  public allComments: Array<any>;
  public videoMap: any = {};
  public commentMap: any = {};
  public loadingComments: boolean = true;
  public parentComments: Array<any>;
  public parentThreadMap: any;
  public commentDetail: any;
  public skills: any;
  public displayedComments: Array<any>;

  public communityStats: Array<any>;

  public reasonCodes: any = {
    project_complete: "No longer needed/project complete",
    not_satisfied: "Not satisfied with service or features",
    support_issue: "Support issue",
    too_expensive: "Too expensive",
    competitor: "Leaving for a competitor",
    other: "Other",
  };
  public settings: any = {
    sort_by: "date",
    sort_direction: "desc",
    filter: false,
  };

  public commentSettings: any = {
    filter: false,
  };

  public displayedSubSection: string = "videos";

  public userIdSearch: any;
  public userModerationResults: Array<any>;
  public moderatingUser: any;
  public moderatingUserNotFound: boolean = false;
  public postDetail: any;
  public allPostsGotten: boolean = false;
  public selectedModerationSection: string = "posts";
  public offendingUsers: Array<any>;

  constructor(
    userService: UserService,
    public helperService: HelperService,
    public exchangeService: ExchangeService,
    public db: AngularFirestore,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public appService: AppService,
    public contentService: ContentService,
    public betaService: BetaService,
    public communityService: WebCommunityService,
    public productService: ProductService,
    private _win: WindowService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.user.is_sphere_admin) {
      this._router.navigate(["home/"], { replaceUrl: true });
    }
    this.displayedSection = this._activatedRoute.snapshot.params["category"];
    this.displayedSubSection = this._activatedRoute.snapshot.params["subcategory"];
    this.changeCategory(this.displayedSection, this.displayedSubSection, false);
  }

  viewExchangeDetail(id) {
    this._router.navigate(["exchange/detail/", id]);
  }

  changeCategory(section, subsection?, nav = true) {
    this.displayedSection = section;
    if (section == "manage_learn_content" && !subsection) {
      subsection = "videos";
      nav = true;
    }
    if (subsection) this.displayedSubSection = subsection;
    if (nav) {
      let route = ["admin/", section];
      if (subsection) route.push(subsection);
      this._router.navigate(route, { replaceUrl: true });
    }
    this.initSection(section);
  }

  initSection(section) {
    if (section == "approveclouditems") {
      this.exchangeService.getUnapprovedWares().subscribe((result) => {
        this.wares = result;
      });
    } else if (section == "pluginimages") {
      this.exchangeService.getPluginImages().subscribe((result: any) => {
        this.pluginImages = result;
      });
    } else if (section == "exchangefeaturedcreators") {
      this.exchangeService.getFeaturedCreators().subscribe((result: any) => {
        this.featuredCreators = result;
      });
    } else if (section == "manage_betas") {
    } else if (section == "feedback") {
      this.userService.getFeedback(100).subscribe((result: Array<any>) => {
        this.feedback = result;
      });
    } else if (section == "video_comments") {
      if (this._activatedRoute.snapshot.queryParams && this._activatedRoute.snapshot.queryParams["commentKey"]) {
        this.initSingleComment();
      } else {
        this.initVideos().then((result) => {});
      }
    } else if (section == "manage_skills") {
      this.initSkills();
    } else if (section == "community_stats") {
      this.initCommunityStats();
    } else if (section == "manage_announcements") {
      // this.initManageAnnouncements();
    } else if (section == "product_splashes") {
      this.initManageSplashes();
    } else if (section == "community_topics") {
      this.initManageTopics();
    } else if (section == "user_posts") {
      if (this._activatedRoute.snapshot.queryParams["user_id"]) {
        this.userIdSearch = this._activatedRoute.snapshot.queryParams["user_id"];
        if (this.communityService.cachedModeratingPosts && this._activatedRoute.snapshot.queryParams["user_id"] != this.communityService.cachedModeratingPosts.user_id) {
          this.communityService.cachedModeratingPosts = undefined;
        }
        this.loadPosts();
      }
      this.initCachedPosts();
    }
  }

  initSingleComment() {
    this.loadingComments = true;
    let docUrl = "video_comments/" + this._activatedRoute.snapshot.queryParams["videoKey"] + "/comments/" + this._activatedRoute.snapshot.queryParams["commentKey"];
    this.db
      .doc("video_comments/" + this._activatedRoute.snapshot.queryParams["videoKey"])
      .get()
      .subscribe((video) => {
        this.videoMap[this._activatedRoute.snapshot.queryParams["videoKey"]] = video.data();
        this.db
          .doc(docUrl)
          .get()
          .subscribe((commentDetail: any) => {
            this.loadingComments = false;
            this.commentDetail = commentDetail.data();
            this.commentDetail.key = commentDetail.ref.id;
            this.commentDetail.videoID = this._activatedRoute.snapshot.queryParams["videoKey"];
            if (this.commentDetail.deleted) this.showAllComments();
          });
      });
  }

  initSkills() {
    this.communityService.getAvailableSkills(true).subscribe((skillsResult) => {
      skillsResult.sort((a, b) => (a.title > b.title ? 1 : -1));
      this.skills = skillsResult;
    });
  }

  showAllComments() {
    this._router.navigate([], {
      queryParams: {
        commentKey: null,
        videoKey: null,
      },
      queryParamsHandling: "merge",
    });
    this.commentDetail = undefined;
    this.initVideos();
  }

  public processedVideos: number = 0;
  public totalVideos: number;
  initVideos() {
    this.loadingComments = true;
    this.parentComments = [];
    this.parentThreadMap = {};
    let promises = [];
    this.videosWithComments = [];
    this.allComments = [];
    this.processedVideos = 0;
    return new Promise((result, reject) => {
      this.db
        .collection("video_comments", (ref) => {
          return ref.where("type", "==", "vimeo").where("created", ">", moment().subtract(2, "months").toDate());
        })
        .valueChanges({ idField: "key" })
        .pipe(take(1))
        .subscribe((result) => {
          result.forEach((video) => {
            this.videoMap[video.key] = video;
            promises.push(
              new Promise((resolve, reject) => {
                this.db
                  .collection("video_comments/" + video.key + "/comments", (ref) => {
                    return ref.orderBy("created", "desc").limit(25);
                  })
                  .valueChanges({ idField: "key" })
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((comsWithkey) => {
                    video.key;
                    comsWithkey.forEach((com: any) => {
                      if (!com.deleted) {
                        com.videoID = video.key;
                        if (this.commentMap[com.key]) {
                          this.allComments.forEach((item, index, myArray) => {
                            if (item.key == com.key) myArray[index] = com;
                          });
                          this.commentMap[com.key] = com;
                          if (!com.parentID) {
                            if (!this.parentThreadMap[com.key]) this.parentThreadMap[com.key] = [];
                            this.parentComments.forEach((item, index, myArray) => {
                              if (item.key == com.key) myArray[index] = com;
                            });
                          } else {
                            if (!this.parentThreadMap[com.parentID]) this.parentThreadMap[com.parentID] = [];
                            this.parentThreadMap[com.parentID].forEach((item, index, myArray) => {
                              if (item.key == com.key) myArray[index] = com;
                            });
                          }
                          this.parentComments.forEach((item) => {
                            if (this.parentThreadMap[item.key] && this.parentThreadMap[item.key].length) {
                              this.parentThreadMap[item.key].sort((a, b) => {
                                return a.created > b.created ? -1 : 1;
                              });
                              item.latestComment = this.parentThreadMap[item.key][0].created;
                            } else {
                              item.latestComment = item.created;
                            }
                          });
                          this.parentComments.sort((a, b) => {
                            return a.latestComment > b.latestComment ? -1 : 1;
                          });
                          this.displayedComments.forEach((item, index, myArray) => {
                            if (item.key == com.key) myArray[index] = com;
                          });
                          // this.displayedComments = [...this.parentComments];
                        } else {
                          this.allComments.push(com);
                          this.commentMap[com.key] = com;
                          if (!com.parentID) {
                            if (!this.parentThreadMap[com.key]) this.parentThreadMap[com.key] = [];
                            this.parentComments.push(com);
                          } else {
                            if (!this.parentThreadMap[com.parentID]) this.parentThreadMap[com.parentID] = [];
                            this.parentThreadMap[com.parentID].push(com);
                          }
                          this.parentComments.forEach((item) => {
                            if (this.parentThreadMap[item.key] && this.parentThreadMap[item.key].length) {
                              this.parentThreadMap[item.key].sort((a, b) => {
                                return a.created > b.created ? -1 : 1;
                              });
                              item.latestComment = this.parentThreadMap[item.key][0].created;
                            } else {
                              item.latestComment = item.created;
                            }
                          });
                          this.parentComments.sort((a, b) => {
                            return a.latestComment > b.latestComment ? -1 : 1;
                          });
                          this.displayedComments = [...this.parentComments];
                        }
                      }
                    });

                    this.filterComments();
                    this.allComments.sort((a, b) => {
                      return a.created > b.created ? -1 : 1;
                    });
                    resolve(true);
                    this.processedVideos++;
                  });
              })
            );
          });
          this.totalVideos = promises.length;
          return Promise.all(promises).then((result) => {
            this.loadingComments = false;
          });
        });
    });
  }

  addComment(args) {
    let msg: any = {
      message: args.reply,
      parentID: args.comment.key,
      author: {
        id: this.user.id,
        name: this.user.firstName + " " + this.user.lastName,
        photo: this.user.photoURL,
      },
      created: new Date(),
    };
    if (args.comment.author.id != this.user.id) {
      // dont send an email if youre replying to your own comment.
      let notifyArgs = {
        reply: msg.message,
        replyPhoto: msg.author.photo,
        replyUserName: this.user.firstName + " " + this.user.lastName,
        originalComment: args.comment.message,
        originalUserID: args.comment.author.id,
        videoTitle: this.videoMap[args.comment.videoID].title,
      };
      this.contentService.sendEmail(notifyArgs, "notify_comment_reply").subscribe(() => {});
    }

    if (args.replyingTo) {
      if (args.replyingTo.author.id != this.user.id) {
        // dont send an email if youre replying to your own comment.
        let notifyArgs = {
          reply: msg.message,
          replyPhoto: msg.author.photo,
          replyUserName: this.user.firstName + " " + this.user.lastName,
          originalComment: args.replyingTo.message,
          originalUserID: args.replyingTo.author.id,
          videoTitle: this.videoMap[args.comment.videoID].title,
        };
        this.contentService.sendEmail(notifyArgs, "notify_comment_reply").subscribe(() => {});
      }
    }
    this.db.collection("video_comments/" + args.comment.videoID + "/comments").add(msg);
  }

  removeCommentFromEveryWhere(comment) {
    let i;
    this.parentComments.forEach((item, index) => {
      if (item.key == comment.key) i = index;
    });
    this.parentComments.splice(i, 1);
    if (comment.parentID) {
      this.parentThreadMap[comment.parentID].forEach((item, index) => {
        if (item.key == comment.key) i = index;
      });
      this.parentThreadMap[comment.parentID].splice(i, 1);
    }
    this.allComments.forEach((item, index) => {
      if (item.key == comment.key) i = index;
    });
    this.allComments.splice(i, 1);
    this.displayedComments.forEach((item, index) => {
      if (item.key == comment.key) i = index;
    });
    this.displayedComments.splice(i, 1);
  }

  deleteComment(msg) {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).delete();
      this.removeCommentFromEveryWhere(msg);
    }
  }

  adminDeleteComment(msg) {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({
        deleted: true,
      });
      if (this.commentDetail) {
        this.showAllComments();
      } else {
        this.removeCommentFromEveryWhere(msg);
      }
    }
  }

  editComment(msg) {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg" });
    modalRef.componentInstance.comment = msg;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({ message: result.message, edited: true });
        }
      },
      (error) => {}
    );
  }

  flagComment(msg) {
    this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({
      flags: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
      cleared: false,
    });
  }

  likeComment(msg) {
    if (msg.likes && msg.likes.includes(this.user.id)) {
      this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({
        likes: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
      });
    } else {
      this.db.doc("video_comments/" + msg.videoID + "/comments/" + msg.key).update({
        likes: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id),
      });
    }
  }

  clearFlag(msg) {
    this.db
      .doc("video_comments/" + msg.videoID + "/comments/" + msg.key)
      .update({
        cleared: true,
      })
      .then(() => {
        alert("You have successfully cleared this comment. If it is flagged again, it will require another review.");
        if (this.commentDetail) this.showAllComments();
      });
  }

  filterComments() {
    if (this.commentSettings.filter == false) {
      this.displayedComments = this.parentComments;
    } else if (this.commentSettings.filter == "noreplies") {
      this.displayedComments = [];
      for (var i in this.parentThreadMap) {
        if (!this.parentThreadMap[i].length && this.parentComments.filter((item) => item.key == i)[0]) {
          this.displayedComments = [...this.displayedComments, this.parentComments.filter((item) => item.key == i)[0]];
        }
      }
    } else if (this.commentSettings.filter == "noreply") {
      this.displayedComments = [];
      for (var i in this.parentThreadMap) {
        if (!this.parentThreadMap[i].length && this.parentComments.filter((item) => item.key == i)[0]) {
          this.displayedComments = [...this.displayedComments, this.parentComments.filter((item) => item.key == i)[0]];
        } else {
          let iReplied = false;
          this.parentThreadMap[i].forEach((item) => {
            if (item.author.id == this.user.id) iReplied = true;
          });
          if (!iReplied && this.parentComments.filter((item) => item.key == i)[0]) this.displayedComments = [...this.displayedComments, this.parentComments.filter((item) => item.key == i)[0]];
        }
      }
    } else if (this.commentSettings.filter == "newreplies") {
      this.displayedComments = [];
      for (var i in this.parentThreadMap) {
        if (this.parentThreadMap[i].length) {
          let iReplied = false;
          this.parentThreadMap[i].forEach((item) => {
            if (item.author.id == this.user.id) iReplied = true;
          });
          if (iReplied && this.parentThreadMap[i][0].author.id != this.user.id && this.parentComments.filter((item) => item.key == i)[0]) {
            this.displayedComments = [...this.displayedComments, this.parentComments.filter((item) => item.key == i)[0]];
          }
        }
      }
    } else if (this.commentSettings.filter == "flagged") {
      let parentCommentsAdded = {};
      this.displayedComments = [...this.parentComments.filter((item) => item.flags && item.flags.length && !item.cleared)];
      this.displayedComments.forEach((item) => (parentCommentsAdded[item.key] = true));
      for (var i in this.parentThreadMap) {
        if (this.parentThreadMap[i].length) {
          let hasAFlag = false;
          this.parentThreadMap[i].forEach((item) => {
            if (item.flags && item.flags.length && !item.cleared) hasAFlag = true;
          });
          if (hasAFlag && !parentCommentsAdded[i] && this.parentComments.filter((item) => item.key == i)[0]) this.displayedComments.push(this.parentComments.filter((item) => item.key == i)[0]);
        }
      }
    }
    this.displayedComments.sort((a, b) => {
      return a.created > b.created ? -1 : 1;
    });
  }

  addPluginImage() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_plugin_image";
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      this.initSection("pluginimages");
    });
  }

  editPluginImage(uuid) {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_plugin_image";
    modalRef.componentInstance.pluginImageUUID = uuid;
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      this.initSection("pluginimages");
    });
  }

  deletePluginImage(uuid) {
    this.exchangeService.deletePluginImage(uuid).subscribe((result) => {
      this.initSection("pluginimages");
    });
  }

  addEditFeaturedCreator(creator) {
    const modalRef = this.appService.showModal(AddEditFeaturedCreatorComponent, { size: "lg" });
    modalRef.componentInstance.creator = creator;
    modalRef.componentInstance.creatorAdded.pipe(take(1)).subscribe((result) => {
      this.initSection("exchangefeaturedcreators");
    });
  }

  deleteFeaturedCreator(creator: any) {
    if (confirm("Are you sure you want to delete this featured creator?")) {
      this.exchangeService.deleteFeaturedCreator(creator.id).subscribe((result) => {
        this.initSection("exchangefeaturedcreators");
      });
    }
  }

  sortDirection(dir) {
    this.settings.sort_direction = dir;
    this.feedback.sort(function (a, b) {
      if (moment(a.created).format("YYYYMMDD") > moment(b.created).format("YYYYMMDD")) {
        return dir == "asc" ? 1 : -1;
      } else if (moment(a.created).format("YYYYMMDD") < moment(b.created).format("YYYYMMDD")) {
        return dir == "asc" ? -1 : 1;
      } else if (moment(a.created).format("YYYYMMDD") == moment(b.created).format("YYYYMMDD")) {
        return 0;
      }
    });
  }

  filter(type) {
    this.settings.filter = type;
    const processFn = (item) => {
      if (!type) {
        item.filtered = false;
      } else if (item.type != type) {
        item.filtered = true;
      } else {
        item.filtered = false;
      }
    };
    this.feedback.forEach(processFn);
  }

  downloadFile(data: any) {
    const replacer = (key, value) => (value === null ? "" : value.replace(",", "")); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    header.pop();
    let csv = data.map((row) => header.map((fieldName) => (fieldName != "photoURL" ? JSON.stringify(row[fieldName], replacer) : "")).join(","));
    csv.unshift(header.join(","));
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "feedback-export.csv");
  }

  export(type) {
    let start =
      this.start_date.year +
      "-" +
      (this.start_date.month < 10 ? "0" + this.start_date.month : this.start_date.month) +
      "-" +
      (this.start_date.day < 10 ? "0" + this.start_date.day : this.start_date.day);
    let end = this.end_date.year + "-" + (this.end_date.month < 10 ? "0" + this.end_date.month : this.end_date.month) + "-" + (this.end_date.day < 10 ? "0" + this.end_date.day : this.end_date.day);
    this.userService.getFeedback(false, start, end, type).subscribe((result: Array<any>) => {
      if (result && result.length) {
        this.downloadFile(result);
      } else {
        alert("No records matched your criteria.");
      }
    });
  }

  addEditBetaProgram(program?) {
    const modalRef = this.appService.showModal(AddEditBetaProgramComponent, { size: "lg" });
    if (program) modalRef.componentInstance.program = program;
    modalRef.componentInstance.programAdded.pipe(take(1)).subscribe((result) => {
      this.initSection("exchangefeaturedcreators");
    });
  }

  deleteBetaProgram(feature) {
    if (
      confirm(
        "Are you sure you want to delete this beta? No users will have access to this beta feature once it is deleted. Please coordinate with PreSonus development to officially launch this feature."
      )
    ) {
      this.betaService.deleteBeta(feature.id).subscribe((result) => {});
    }
  }

  showAddSkill() {
    const modalRef = this.appService.showModal(AdminAddSkillComponent);
    modalRef.componentInstance.skillAdded.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.initSkills();
    });
  }

  deleteSkill(skill) {
    if (confirm("Are you sure you want to delete this skill? This cannot be undone.")) {
      this.communityService.adminDeleteSkill(skill.id).subscribe((result) => {
        this.initSkills();
      });
    }
  }

  initCommunityStats() {
    this.communityService.getStats().then((result: any) => {
      result.forEach((item) => {
        if (item.data) console.log("Data for " + item.title, item.data);
      });
      this.communityStats = result;
    });
  }

  public splashes = [];
  initManageSplashes() {
    this.communityService.getSplashes().then((result) => {
      this.splashes = result;
    });
  }

  deleteSplash(splash) {
    if (confirm("Are you sure you want to delete this product splash? This cannot be undone.")) {
      this.communityService.deleteSplash(splash.id).then((result) => {
        this.initSection("product_splashes");
      });
    }
  }

  editSplash(splash) {
    const modalRef = this.appService.showModal(AddEditProductSplashComponent, { size: "lg" });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.splashAdded.pipe(take(1)).subscribe((result) => {
      this.initSection("product_splashes");
    });
  }

  public topics = [];
  initManageTopics() {
    this.communityService.getTopics(true).then((result) => {
      let archived = result.filter((item) => item.archived);
      let active = result.filter((item) => !item.archived);
      active.sort((a, b) => (a.title > b.title ? 1 : -1));
      archived.sort((a, b) => (a.title > b.title ? 1 : -1));

      this.topics = [...active, ...archived];
    });
  }

  archiveTopic(topic) {
    if (confirm("Are you sure you want to archive this topic?")) {
      this.communityService.toggleArchiveTopic(topic.string_id, true).then((result) => {
        this.initSection("community_topics");
      });
    }
  }

  unArchiveTopic(topic) {
    if (confirm("Are you sure you want to un-archive this topic?")) {
      this.communityService.toggleArchiveTopic(topic.string_id, false).then((result) => {
        this.initSection("community_topics");
      });
    }
  }

  editTopic(topic) {
    const modalRef = this.appService.showModal(AddEditTopicComponent, { size: "lg" });
    if (topic) modalRef.componentInstance.topic = topic;
    modalRef.componentInstance.topicAdded.pipe(take(1)).subscribe((result) => {
      this.initSection("community_topics");
    });
  }

  contentContainerScrolled(args) {
    this.appService.scrolledContentOffsets.moderating_posts = this.contentContainer.nativeElement.scrollTop;
  }

  public statusDirty: boolean = false;
  loadPosts() {
    this.userModerationResults = [];
    this.communityService.getUserProfile(this.userIdSearch).then((result: any) => {
      if (result) {
        if (!result.status) result.status = "active";
        this.moderatingUserNotFound = false;
        this.moderatingUser = result;
        if (this.communityService.cachedModeratingPosts && this.communityService.cachedModeratingPosts.user_id == this.userIdSearch) {
          this.userModerationResults = this.communityService.cachedModeratingPosts.posts;
        } else {
          this.communityService.getPostsWithReplies({ moderationAccountID: this.userIdSearch, clear: true }).then((result: any) => {
            this.communityService.cachedModeratingPosts = {
              user_id: this.userIdSearch,
              posts: result,
            };
            this.userModerationResults = result;
            if (result.length < 30) this.allPostsGotten = true;
          });
        }
      } else {
        this.moderatingUserNotFound = true;
      }
    });
  }

  loadMorePosts() {
    this.communityService.getPostsWithReplies({ moderationAccountID: this.userIdSearch }).then((more: any) => {
      if (more && more.length) {
        if (more.length < 30) this.allPostsGotten = true;
        this.userModerationResults = [...this.userModerationResults, ...more];
        this.communityService.cachedModeratingPosts = {
          user_id: this.userIdSearch,
          posts: this.userModerationResults,
        };
      }
    });
  }

  saveStatus() {
    if (this.moderatingUser.status == "probation") {
      if (confirm("Setting this user's status to probation will allow the user to view Community, but not post anything. Would you like to continue?")) {
        this.statusDirty = false;
        this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
          alert("Updated successfully.");
        });
      }
    } else if (this.moderatingUser.status == "banned") {
      if (confirm("Setting this user's status to banned will remove Community from their account entirely. Would you like to continue?")) {
        this.statusDirty = false;
        this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
          alert("Updated successfully.");
        });
      }
    } else {
      this.statusDirty = false;
      this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
        alert("Updated successfully.");
      });
    }
  }

  selectModeratedUser(user) {
    this.selectedModerationSection = "posts";
    this.userIdSearch = user.id;
    this.moderationSegmentedBar.options[0].selected = true;
    this.moderationSegmentedBar.options[1].selected = false;
    this.loadPosts();
  }

  switchModerationSection(args) {
    this.selectedModerationSection = args.id;
    if (args.id == "offending_users") {
      this.communityService.findModeratedUsers().then((result: any) => {
        this.offendingUsers = result;
      });
    } else {
      this.initCachedPosts();
    }
  }

  initCachedPosts() {
    if (this.communityService.cachedModeratingPosts) {
      this.userModerationResults = this.communityService.cachedModeratingPosts.posts;
      this.userIdSearch = this.communityService.cachedModeratingPosts.user_id;
      if (this.appService.scrolledContentOffsets.moderating_posts) {
        this._win.setTimeout(() => {
          this.contentContainer.nativeElement.scrollTop = this.appService.scrolledContentOffsets.moderating_posts;
        }, 300);
      }
    }
  }

  clearModerateSearch() {
    this.userIdSearch = "";
    this.userModerationResults = undefined;
    this.communityService.cachedModeratingPosts.posts = undefined;
    if (this._activatedRoute.snapshot.queryParams["user_id"]) this._router.navigate(["admin/user_posts"], { queryParams: {} });
    this.moderatingUser = undefined;
  }

  viewFullPost(post) {
    let id = post.id;
    if (post.parentID) id = post.parentID;
    this._router.navigate(["community/post/" + id]);
  }
}
