import { Component, OnInit } from "@angular/core";
import { BaseComponent, UserService, HelperService, WindowService, environment, EventBusService, WorkspaceService } from "@mypxplat/xplat/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { SphereSubscribeStripeComponent, WorkspaceUploadComponent } from "../../../modals";
import { take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "myp-account-showcases",
  templateUrl: "./showcases.component.html",
})
export class ShowcasesComponent extends BaseComponent implements OnInit {
  public showcases: Array<any>;
  public loading: boolean;

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    private _win: WindowService,
    private _router: Router,
    private _route: ActivatedRoute,
    public appService: AppService,
    private _eventbusService: EventBusService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    public router: Router,
    public communityService: WebCommunityService,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
  }

  ngOnInit() {
    // this.loadMySkills();
    this.loading = true;
    this.communityService.getAvailableSkills().subscribe((skills) => {
      this.init();
    });
  }

  init(onlySkills?) {
    this.communityService.getShowcases(this.userService.user.id).subscribe((result: any) => {
      this.loading = false;
      if (onlySkills) {
        this.showcases.forEach((item) => {
          item.loadingSkills = false;
          result.forEach((newStuff) => {
            if (item.id == newStuff.id) {
              item.skills = newStuff.skills;
            }
          });
        });
      } else {
        this.showcases = result;
      }
    });
  }

  addShowcase() {
    if (this.user.active_subscription) {
      const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
      modalRef.componentInstance.isShowcase = true;
      modalRef.componentInstance.allowedTypes = ["audio/mpeg", "audio/mp3"];
      modalRef.componentInstance.fileUploadFinished.subscribe((result) => {
        this.init();
      });
      modalRef.componentInstance.fileCreated.subscribe((result) => {
        this.init();
      });
    } else {
      if (this.env.features.checkout_page) {
        this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
      } else {
        this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
      }
    }
  }

  playAudio(file, event) {
    if (event) event.stopPropagation();
    if (this.communityService.playingAudioFile && this.communityService.playingAudioFile.id == file.id) {
      this._eventbusService.emit(this._eventbusService.types.playAudio, false);
    } else {
      this._eventbusService.emit(this._eventbusService.types.playAudio, {
        src: file.url,
        title: file.filename,
        file: file,
        hideDownloadOption: true,
      });
    }
  }

  addSkill(skill, showcase) {
    showcase.shouldShowChannelSelector = false;
    let args = {
      skill_id: skill.id,
      file_id: showcase.id,
    };
    showcase.loadingSkills = true;
    this.communityService.updateShowcaseSkill(args).subscribe((result) => {
      this.init(true);
    });
  }

  showcaseOptions(showcase) {
    let args: any = {
      title: "Showcase Options",
      actions: ["Edit Description", "Delete Showcase"],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Delete Showcase") {
            if (confirm("Are you sure you want to delete this showcase? It cannot be undone.")) {
              this.loading = true;
              this.workspaceService.deleteFile(showcase.id).subscribe((result) => {
                this.init();
              });
            }
          } else {
            let newDescription = prompt("Edit your showcase description...", showcase.description ? showcase.description : "");
            if (newDescription) {
              this.loading = true;
              this.workspaceService.updateFile({ id: showcase.id, description: newDescription }).subscribe((result) => {
                this.init();
              });
            }
          }
        }
      });
  }

  removeSkill(skill, showcase) {
    let args = {
      skill_id: skill.id,
      showcase_id: showcase.id,
      remove: true,
    };
    showcase.loadingSkills = true;
    this.communityService.updateShowcaseSkill(args).subscribe((result) => {
      this.init(true);
    });
  }
}
