import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent, Product, ProductService, ProductSnapshot, UserService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-beta-details",
  templateUrl: "beta-details.component.html",
})
export class BetaDetailsComponent extends BaseComponent {
  @Input() betaProgram: any;
  public snapshot: ProductSnapshot;
  public redeemingProduct: boolean = false;
  public loading: boolean;
  constructor(userService: UserService, public productService: ProductService) {
    super(userService);
  }

  ngOnInit() {
    if (this.betaProgram.product_id) {
      this.loading = true;
      this.productService.getProductSnapshot(this.betaProgram.product_id, undefined, this.betaProgram.id).subscribe((snapshot) => {
        this.snapshot = snapshot;
        this.snapshot.product.imageUrl;
        this.loading = false;
      });
    }
  }

  claimBetaProduct() {
    this.redeemingProduct = true;
    this.productService.redeemBetaProduct(this.betaProgram.id).subscribe((result) => {
      this.productService.getProducts(true).subscribe((result) => {
        this.redeemingProduct = false;
      });
    });
  }
}
