import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent, UserService, EventsService, HelperService, WindowService, EventBusService } from "@mypxplat/xplat/core";
import { AgmMap } from "@agm/core";
import * as moment from "moment";
import { AppService } from "@mypxplat/xplat/web/core";
import { SphereSubscribeStripeComponent } from "../modals";
declare var google;

@Component({
  selector: "myp-event-detail",
  templateUrl: "event-detail.component.html",
})
export class EventDetailComponent extends BaseComponent implements OnInit {
  // declare var FB;
  public loading: any;
  public event: any;
  public geocodeResult: any;
  public latLong: any;
  public address: string;
  public start: string;
  public end: string;
  public html: any;
  @ViewChild(AgmMap, { static: false }) myMap: AgmMap;

  constructor(
    userService: UserService,
    private _activatedRoute: ActivatedRoute,
    public eventsService: EventsService,
    public eventBusService: EventBusService,
    public helperService: HelperService,
    public appService: AppService,
    public router: Router,
    private _win: WindowService,
    private _zone: NgZone
  ) {
    super(userService);
  }

  ngOnInit() {
    const id = this._activatedRoute.snapshot.params["id"];
    this.loading = true;
    this.eventsService.getEvent(id).subscribe((result) => {
      this.event = result;
      this.loading = false;
      if (this.event) this.init();
    });
  }

  openLivestream(id) {
    this.eventBusService.emit(this.eventBusService.types.playVideo, {
      id: id,
      type: "vimeo",
    });
  }

  init() {
    this.start = this.event.startdate_events + " " + this.event.starttime_events;
    this.end = this.event.enddate_events + " " + this.event.endtime_events;

    if (this.event.address_events && this.event.city_events) {
      this.address = this.event.address_events + this.event.city_events + (this.event.state_events ? this.event.state_events : "") + (this.event.zip_events ? this.event.zip_events : "");
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: this.address }, (result, status) => {
        this._zone.run(() => {
          if (result && result.length) {
            this.latLong = {
              lat: result[0].geometry.location.lat(),
              long: result[0].geometry.location.lng(),
            };
            this.geocodeResult = result[0];
          }
        });
      });
    }
    this.html = this.event.description_events;
    if (this.event.description_events.indexOf('id="livestream"') > -1) {
      this._win.setTimeout(() => {
        let link = document.getElementById("livestream");
        if (link) {
          let livestreamID = link.getAttribute("data-livestream-id");
          link.addEventListener("click", () => {
            this.openLivestream(livestreamID);
          });
          if (moment(this.event.startdate_events).format("YYYYMMDD") < moment().format("YYYYMMDD")) {
            this.openLivestream(livestreamID);
            this.router.navigate(["/content/exclusivevideos"]);
          }
        }
      }, 100);
    }
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }
}
