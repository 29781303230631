import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from "@angular/core";

import { BaseComponent, EventBusService, File, UserService, WorkspaceService, environment, Notification, WindowService } from "@mypxplat/xplat/core";
import { AppService, AvoService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { WorkspaceUploadComponent, SphereSubscribeStripeComponent, ConfirmBetaAnnouncementEmail } from "@mypxplat/xplat/web/features";

import { take, takeUntil } from "rxjs/operators";
import { MessageInputComponent } from "../../message-input/message-input.component";
import { HtmlOverlayComponent } from "../../modals/html-overlay/html-overlay.component";
import { SearchContentComponent } from "../../modals/search-content/search-content.component";
import { FileUploader } from "ng2-file-upload";
import { Router } from "@angular/router";

@Component({
  selector: "myp-create-post-input",
  templateUrl: "create-post-input.component.html",
})
export class CreatePostInputComponent extends BaseComponent {
  @ViewChild("msgInput") msgInput: MessageInputComponent;
  @ViewChild("subjectInput") subjectInput: MessageInputComponent;
  @ViewChild("confirmPublicPostModal") confirmPublicPostModal: ElementRef;
  private _selectedChannel: any;
  @Input() set selectedChannel(channel) {
    this.selectedChannels = [];
    if (channel && channel.string_id != "all_posts" && channel.string_id != "general" && channel.string_id != "pinned_posts") {
      this.addChannel(channel);
      this._selectedChannel = channel;
    }
  }
  get selectedChannel() {
    return this._selectedChannel;
  }

  @Input() topic: any;
  @Input() betaStringId: any;

  @Output() queuedPostsChanged: EventEmitter<any> = new EventEmitter();
  public createPostActive: boolean = false;
  public postSubject: string;
  public postContent: string;
  public sphereOnlyPost: boolean = false;
  public employeeOnlyPost: boolean = false;
  public pinToBetaProgram: boolean = false;
  public sendBetaProgramAnnouncementEmail: boolean = false;
  public selectedChannels: Array<any>;
  public shouldShowChannelSelector: boolean;

  public uploadingFiles: any = false;
  public uploadedAttachments: Array<File>;
  public queuedPosts: Array<any> = [];
  public publicProfileWarning: boolean = false;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: any;
  public isBetaManager: boolean = false;
  constructor(
    userService: UserService,
    public communityService: WebCommunityService,
    public appService: AppService,
    public workspaceService: WorkspaceService,
    public eventBusService: EventBusService,
    private _zone: NgZone,
    public avoService: AvoService,
    private _win: WindowService,
    public betaService: BetaService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.isBetaManager = this.user.groups.some((group: any) => group.name === "beta_manager");
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    this.selectedChannels = this.selectedChannel && this.selectedChannel.string_id != "general" && this.selectedChannel.string_id != "all_posts" ? [this.selectedChannel] : [];
    if (this.communityService.attachingExistingFile) {
      this.createPostActive = true;
      this.uploadedAttachments = [this.communityService.attachingExistingFile];
      this.communityService.attachingExistingFile = undefined;
    }
    if (this.communityService.attachingPublicFileLink) {
      this.createPostActive = true;
      this._win.setTimeout(() => {
        this.msgInput.setMessageValue(this.communityService.attachingPublicFileLink);
        this.msgInput.detectContent();
        this.communityService.attachingPublicFileLink = undefined;
      }, 300);
    }

    if (this.communityService.migratingPostMessage) {
      this.createPostActive = true;
      this._win.setTimeout(() => {
        this.postContent = this.communityService.migratingPostMessage;
        this.msgInput.setMessageValue(this.communityService.migratingPostMessage);
        this.msgInput.detectContent();
        this.communityService.migratingPostMessage = undefined;
      }, 300);
    }
  }

  upgrade() {
    if (this.env.features.checkout_page) {
      this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }

  droppedFile(args) {
    this.attachFile(args);
    this.uploader.clearQueue();
  }

  makePublic() {
    this.communityService.updateProfile({ public: true }).then((result) => {
      this.postInputFocus();
    });
  }

  postSubjectContentChanged(args) {
    this.postSubject = args;
  }

  postContentChanged(args) {
    this.postContent = args;
  }

  postInputFocus(type = "post") {
    if (this.communityService.profile.public || this.betaStringId) {
      if (!this.createPostActive) {
        this.createPostActive = true;
      }
      if (type == "post" && this.betaStringId && !this.isBetaManager) this.populateBetaPostTemplate();
    } else {
      this.publicProfileWarning = true;
    }
  }

  public betaPostTemplatePopulated: boolean = false;
  populateBetaPostTemplate() {
    if (!this.betaPostTemplatePopulated) {
      this.betaPostTemplatePopulated = true;
      this.msgInput.setMessageValue(`
<strong>Issue Description:&nbsp;</strong><br />
<strong>Steps To Reproduce:&nbsp;</strong><br />
<strong>Observed Behavior:&nbsp;</strong><br />
<strong>Expected Behavior:&nbsp;</strong><br />
<strong>Operating System:&nbsp;</strong><br />
      `);
    }
  }

  detectOperatingSystem() {
    const osPatterns = [
      { name: "Windows 10", pattern: /Windows NT 10\.0/i },
      { name: "Windows 8.1", pattern: /Windows NT 6\.3/i },
      { name: "Windows 8", pattern: /Windows NT 6\.2/i },
      { name: "Windows 7", pattern: /Windows NT 6\.1/i },
      { name: "Windows Vista", pattern: /Windows NT 6\.0/i },
      { name: "Windows XP", pattern: /Windows NT 5\.1|Windows XP/i },
      { name: "Windows 2000", pattern: /Windows NT 5\.0/i },
      { name: "Mac OS X", pattern: /Mac OS X ([\d_]+)/i },
      { name: "Mac OS", pattern: /Mac_PowerPC/i },
      { name: "Linux", pattern: /Linux/i },
      { name: "Ubuntu", pattern: /Ubuntu/i },
      { name: "iPhone", pattern: /iPhone OS ([\d_]+)/i },
      { name: "iPad", pattern: /iPad; CPU OS ([\d_]+)/i },
      { name: "Android", pattern: /Android ([\d\.]+)/i },
      { name: "Chrome OS", pattern: /CrOS/i },
    ];

    let osInfo = "Unknown OS";

    for (const os of osPatterns) {
      const match = navigator.userAgent.match(os.pattern);
      if (match) {
        const version = match[1] ? match[1].replace(/_/g, ".") : "";
        osInfo = os.name + (version ? ` ${version}` : "");
        break;
      }
    }

    // Additional checks for specific hardware information
    if (/Macintosh/i.test(navigator.userAgent) && /Intel/i.test(navigator.userAgent)) {
      osInfo += " (Intel)";
    } else if (/Macintosh/i.test(navigator.userAgent) && /Apple/i.test(navigator.userAgent)) {
      osInfo += " (Apple Silicon)";
    }

    return osInfo;
  }

  postInputBlur() {
    // this.createPostActive = false;
  }

  removeAttachment(attachment?) {
    if (attachment) {
      this.uploadedAttachments = this.uploadedAttachments.filter((item) => item.id != attachment.id);
    } else {
      this.uploadedAttachments = undefined;
      // this.uploadingFiles = undefined;
    }
  }

  watchVideo() {
    this.eventBusService.emit(this.eventBusService.types.playVideo, {
      id: "669865361",
      title: "Studio One+ Community Overview",
      type: "vimeo",
    });
  }

  fileOverBase(e) {
    this.createPostActive = true;
    this.hasBaseDropZoneOver = e;
  }

  attachFile(droppedFile = false) {
    const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.isPostAttachment = true;
    if (droppedFile) modalRef.componentInstance.droppedFile = droppedFile;
    modalRef.componentInstance.uploadProgressChanged.pipe().subscribe((result) => {
      this.uploadingFiles.forEach((item) => {
        if (item.id == result.file.id) item.progress = result.progress;
      });
    });
    modalRef.componentInstance.fileCreated.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (!this.uploadingFiles) this.uploadingFiles = [];
      this.uploadingFiles.push(result);
    });
    modalRef.componentInstance.fileUploadFinished.subscribe((result) => {
      this._zone.run(() => {
        if (!this.uploadedAttachments) this.uploadedAttachments = [];
        result.url = this.communityService.buildFileUrl(result.user_id, result);
        this.uploadedAttachments.push(result);
        this.uploadingFiles = this.uploadingFiles.filter((item) => item.id != result.id);
        let queuedPostSent = false;
        if (this.queuedPosts.length) {
          let queuedPostIndex: any = false;
          if (!this.uploadingFiles.length) {
            this.queuedPosts.forEach((item, index) => {
              this.sendPost(item);
            });
            this.queuedPosts = [];
          }
        }
        this.uploadingFiles = this.uploadingFiles.filter((item) => item.id != result.id);

        // if (!queuedPostSent) {
        //   this.uploadedAttachmentUrl = this.communityService.buildFileUrl(this.uploadedAttachment.user_id, this.uploadedAttachment);
        // }
      });
    });
  }

  addChannel(channel) {
    if (!this.selectedChannels.filter((item) => item.string_id == channel.string_id).length) {
      this.selectedChannels.push(channel);
    }
    this.shouldShowChannelSelector = false;
  }

  cancelCreatePost() {
    this.createPostActive = false;
    if (this.subjectInput && this.subjectInput.emptyInput) this.subjectInput.emptyInput();
    this.msgInput.emptyInput();
    this.msgInput.selectedArticlesInMessage = [];
    this.msgInput.selectedLearnContentInMessage = [];
    this.msgInput.publicFileLinksInMessage = [];
    this.msgInput.messageEmbeds = [];
    this.msgInput.elligibleMentions = [];
    this.postSubject = "";
    this.postContent = "";
    this.pinToBetaProgram = undefined;
    this.sendBetaProgramAnnouncementEmail = undefined;
    this.removeAttachment();
    this.msgInput.displayPlaceholder = true;
    this.betaPostTemplatePopulated = false;
  }

  removeChannel(channel) {
    this.selectedChannels = this.selectedChannels.filter((item) => item.string_id != channel.string_id);
  }

  moveToBeta() {
    this.communityService.migratingPostMessage = this.postContent;
  }

  sendPost(queuedPost?, confirmed?) {
    if (!confirmed && !this.betaStringId && this.betaService.enrolledBetas?.length) {
      this.appService.showModal(this.confirmPublicPostModal, { size: "sm" });
    } else {
      this.shouldShowChannelSelector = false;
      if (queuedPost) {
        this.communityService.addPost(queuedPost).then((result) => {});
      } else if (this.betaStringId && !this.postSubject) {
        alert("Please enter a subject for your post.");
      } else if (this.betaStringId && !this.selectedChannels.length) {
        alert("You must choose at least one tag for this post.");
      } else if (this.postContent && this.postContent.trim()) {
        let args: any = {
          author: {
            name: this.userService.user.firstName + " " + this.userService.user.lastName,
            photo: this.userService.user.photoURL,
            id: this.userService.user.id,
          },
          created: new Date(),
        };
        if (this.betaStringId) {
          args.beta_string_id = this.betaStringId;
          args.reviewed = false;
        }
        if (this.sphereOnlyPost) args.sphere_only = true;
        if (this.employeeOnlyPost) args.employee_only = true;
        if (this.pinToBetaProgram) args.pinned = true;
        if (this.selectedChannels.length) {
          args.channels = [];
          this.selectedChannels.forEach((item) => {
            if (item.string_id != "general" && item.string_id != "all_posts") args.channels.push(item.string_id);
          });
        }
        if (!args.channels?.length) delete args.channels;
        if (this.topic) {
          args.topics = [this.topic.string_id];
        }
        if (this.uploadedAttachments || this.uploadingFiles) args.files = this.uploadedAttachments || this.uploadingFiles;
        if (this.msgInput.selectedMentionsInMessage && this.msgInput.selectedMentionsInMessage.length) {
          let realMentions = [];
          this.msgInput.selectedMentionsInMessage.forEach((item) => {
            if (this.postContent.indexOf(item.selector) > -1 && item.user?.id) {
              realMentions.push({
                id: item.user.id,
                user: item.user,
                selector: item.selector,
                editorSelector: item.editorSelector,
              });
            }
          });
          this.msgInput.selectedMentionsInMessage.forEach((men) => {
            this.postContent = this.postContent.replace(men.editorSelector, men.selector + " ");
          });
          args.mentions = realMentions;
          this.msgInput.selectedMentionsInMessage = [];
        }

        if (this.msgInput.selectedArticlesInMessage && this.msgInput.selectedArticlesInMessage.length) {
          let articleEntites = [];
          this.msgInput.selectedArticlesInMessage.forEach((item) => {
            articleEntites.push({
              article: item.article,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
          });
          args.articles = articleEntites;
          this.msgInput.selectedArticlesInMessage.forEach((item) => {
            this.postContent = this.postContent.replace(item.editorSelector, item.selector + " ");
          });
        }

        if (this.msgInput.selectedLearnContentInMessage && this.msgInput.selectedLearnContentInMessage.length) {
          let learnContentEntities = [];
          this.msgInput.selectedLearnContentInMessage.forEach((item) => {
            learnContentEntities.push({
              content: item.content,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
          });
          args.learn_content = learnContentEntities;
          this.msgInput.selectedLearnContentInMessage.forEach((item) => {
            this.postContent = this.postContent.replace(item.editorSelector, item.selector + " ");
          });
        }

        if (this.msgInput.selectedCategoriesSectionsInMessage && this.msgInput.selectedCategoriesSectionsInMessage.length) {
          let sectionCategoryEntities = [];
          this.msgInput.selectedCategoriesSectionsInMessage.forEach((item) => {
            sectionCategoryEntities.push({
              category_or_section: item.category_or_section,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
          });
          args.categories_or_sections = sectionCategoryEntities;
          this.msgInput.selectedCategoriesSectionsInMessage.forEach((item) => {
            this.postContent = this.postContent.replace(item.editorSelector, item.selector + " ");
          });
        }

        if (this.msgInput.publicFileLinksInMessage && this.msgInput.publicFileLinksInMessage.length) {
          let publicFileLinkEntities = [];
          this.msgInput.publicFileLinksInMessage.forEach((item) => {
            publicFileLinkEntities.push({
              publicFile: item.publicFile,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
          });
          args.public_file_links = publicFileLinkEntities;
          this.msgInput.publicFileLinksInMessage.forEach((item) => {
            this.postContent = this.postContent.replace(item.editorSelector, item.selector + " ");
          });
        }

        const { messageEmbeds } = this.msgInput;
        if (messageEmbeds.length > 0) {
          args.embeds = messageEmbeds.map(({ id, type, subtype }) => {
            const embed = { id, type, subtype, src: "" };
            if (type == "youtube") {
              embed.src = `https://www.youtube.com/embed/${id}`;
            } else if (type == "soundcloud") {
              embed.src = `https://w.soundcloud.com/player/?url=${id}`;
            } else if (type == "spotify") {
              embed.src = `https://open.spotify.com/embed/${subtype}/${id}`;
            }
            return embed;
          });
        }
        args.subject = this.postSubject;
        args.message = this.postContent;
        // queue the post if the upload hasnt finished.
        if (this.uploadingFiles && this.uploadingFiles.length) {
          this.queuedPosts.push(args);
          this.queuedPostsChanged.next(this.queuedPosts);
          alert("Your post will be submitted when your files have finished uploading.");
        } else {
          let collection = this.betaStringId ? `beta_community_posts` : "community_posts";
          this.communityService.addPost(args, collection).then((result) => {
            if (this.sendBetaProgramAnnouncementEmail) {
              const modalRef = this.appService.showModal(ConfirmBetaAnnouncementEmail, { size: "lg" });
              modalRef.componentInstance.userIds = this.communityService.betaAuthorList.map((item) => item.id);
              modalRef.componentInstance.subject = this.postSubject;
              modalRef.componentInstance.messageHtml = this.postContent;
              modalRef.componentInstance.from = {
                name: this.userService.user.firstName + " " + this.userService.user.lastName,
                photo: this.userService.user.photoURL,
                id: this.userService.user.id,
              };
              modalRef.componentInstance.directLink = `https://my.presonus.com//beta/${this.betaStringId}/channels/all_posts`;
            }
            if (args.pinned) this.eventBusService.emit(this.eventBusService.types.betaAnnouncementUpdated, true);
            if (args.mentions && args.mentions.length) {
              let mentionIDs = [];
              args.mentions.forEach((item) => {
                args.id = result.id;
                mentionIDs.push(item.id);
              });
              if (mentionIDs.length) {
                let notification: Notification = {
                  title: this.user.firstName + " mentioned you in a post.",
                  body: args.message,
                  photo: this.userService.user.photoURL,
                  topics: ["community"],
                  type: "community_post_mention",
                  url: "https://my.presonus.com/community/post/" + args.id,
                  button: "View Post",
                  payload: {
                    post: {
                      id: args.id,
                      message: args.message,
                      author: args.author,
                    },
                    primary_post_id: args.id,
                  },
                };
                this.userService
                  .sendNotification({
                    notification: notification,
                    user_ids: mentionIDs,
                    clients: ["rollup", "mobile", "email"],
                  })
                  .subscribe();
              }
            }
            let channels = [];
            this.selectedChannels.forEach((item) => channels.push(item.title));
            let communityPostCreatedArgs: any = {
              siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
              channelName: this.getChannelName(channels),
              postType: "post",
              postId: result.id,
            };
            if (this.uploadedAttachments) communityPostCreatedArgs.attachmentExtension = this.uploadedAttachments[0].extension;
            this.avoService.trackEvent().communityPostCreated(communityPostCreatedArgs);
            this.cancelCreatePost();
          });
        }
      } else {
        alert("The textual content of your post cannot be empty.");
      }
    }
  }

  showArticle(article) {
    let modalRef = this.appService.showModal(HtmlOverlayComponent, { size: "lg" });
    modalRef.componentInstance.title = article.article.name;
    modalRef.componentInstance.html = '<style type="text/css">img { max-width: 100%; }</style>' + article.article.body;
  }

  removeArticle(article, e) {
    this.msgInput.removeSelectedArticle(article);
    e.stopPropagation();
  }

  removePublicFile(article, e) {
    this.msgInput.removePublicFile(article);
    e.stopPropagation();
  }

  removeLearnContent(item, e) {
    this.msgInput.removeSelectedLearnContent(item);
    e.stopPropagation();
  }

  removeEmbed(embed, e) {
    this.msgInput.removeEmbed(embed);
    e.stopPropagation();
  }

  attachSomething() {
    let args: any = {
      title: "What would you like to include in this post?",
      actions: ["Attach File", "Attach Article", "Attach Video"],
    };
    this.eventBusService.emit(this.eventBusService.types.showActionChooser, args);
    this.eventBusService
      .observe(this.eventBusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Attach File") {
            this.attachFile();
          } else if (result == "Attach Article") {
            this.showAttach("articles");
          } else if (result == "Attach Video") {
            this.showAttach("videos");
          }
        }
      });
  }

  showAttach(type) {
    const modalRef = this.appService.showModal(SearchContentComponent, { size: "lg" });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.contentSelected.pipe(take(1)).subscribe((result) => {
      if (type == "articles") {
        this.msgInput.selectedArticlesInMessage.push({ selector: false, editorSelector: false, article: result });
      } else {
        this.msgInput.selectedLearnContentInMessage.push({ selector: false, editorSelector: false, content: result });
      }
    });
  }

  getChannelName(channels) {
    return channels.length === 0 ? ["general"] : channels;
  }
}
