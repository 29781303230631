import { Component, ElementRef, Input, NgZone, ViewChild } from "@angular/core";

import { BaseComponent, CommunityService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploader } from "ng2-file-upload";
import { take, takeUntil } from "rxjs/operators";
import { WorkspaceUploadComponent } from "..";
import { MessageInputComponent } from "../../message-input/message-input.component";
import { SearchContentComponent } from "../search-content/search-content.component";

@Component({
  selector: "myp-edit-comment",
  templateUrl: "edit-comment.component.html",
})
export class EditCommentComponent extends BaseComponent {
  @ViewChild("messageSpan") messageSpan: ElementRef;
  @ViewChild("editPostInput") editPostInput: MessageInputComponent;
  @ViewChild("editSubjectInput") editSubjectInput: MessageInputComponent;
  @Input() comment: any;
  @Input() isCommunityPost: any;
  @Input() isHtmlComment: any;
  @Input() collaborators: any;
  @Input() isVideoNote: any;
  public shouldShowChannelSelector: boolean = false;
  public message: string;
  public files: Array<any>;
  public uploadingFiles: Array<any>;
  public uploadedAttachments: Array<any>;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: any;
  public editedPostContent: any;
  public editedSubjectContent: any;
  public isBetaComment: boolean = false;
  constructor(
    userService: UserService,
    private _win: WindowService,
    private _zone: NgZone,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService,
    public communityService: CommunityService,
    public appService: AppService,
    public betaService: BetaService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.comment.subject) {
      this.isBetaComment = true;
      this.editedSubjectContent = this.comment.subject;
    }
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    if (this.isCommunityPost) this.communityService.getAvailableSkills().subscribe();
    this.message = (this.comment.message || this.comment.text) + "";
    this.editedPostContent = this.message;
    if (this.comment.files) this.files = [...this.comment.files];
    if (this.isCommunityPost || this.isHtmlComment) {
      this.comment = this.communityService.buildHtmlComment({ ...this.comment }, true, true);
    }
  }

  ngAfterViewInit() {
    if (this.isCommunityPost || this.isHtmlComment) {
      this._win.setTimeout(() => {
        this.editPostInput.setMessageValue(this.comment.message);
        if (this.isBetaComment) this.editSubjectInput.setMessageValue(this.comment.subject);
      }, 50);
    } else {
      if ((this.comment.message || this.comment.text) && this.messageSpan) this.messageSpan.nativeElement.innerText = this.comment.message || this.comment.text;
    }
  }

  addChannel(channel) {
    this.shouldShowChannelSelector = false;
    if (!this.comment.channels || this.comment.channels.length < 3) {
      this.comment.channels = [...(this.comment.channels || []), channel.string_id];
    }
  }

  removeChannel(string_id) {
    this.comment.channels = this.comment.channels.filter((item) => item != string_id);
  }

  removeAttachment(file) {
    this.files = this.files.filter((item) => file.id != item.id);
  }

  removeArticle(article) {
    this.comment.articles = this.comment.articles.filter((item) => item.article.id != article.article.id);
  }

  removeLearnContent(content) {
    this.comment.learn_content = this.comment.learn_content.filter((item) => item.content.id != content.content.id);
  }

  removeEmbed(embed) {
    this.comment.embeds = this.comment.embeds.filter(({ id }) => id != embed.id);
  }

  keyUpEvent(args) {
    this.message = this.messageSpan.nativeElement.innerText;
  }

  contentPasted() {
    this.message = this.messageSpan.nativeElement.innerText;
  }

  focusOut(args) {
    this.message = this.messageSpan.nativeElement.innerText;
  }

  droppedFile(args) {
    this.attachFile(args);
    this.uploader.clearQueue();
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  attachFile(droppedFile = false) {
    const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.isPostAttachment = true;
    if (droppedFile) modalRef.componentInstance.droppedFile = droppedFile;
    modalRef.componentInstance.uploadProgressChanged.pipe().subscribe((result) => {
      this.uploadingFiles.forEach((item) => {
        if (item.id == result.file.id) item.progress = result.progress;
      });
    });
    modalRef.componentInstance.fileCreated.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (!this.uploadingFiles) this.uploadingFiles = [];
      this.uploadingFiles.push(result);
    });
    modalRef.componentInstance.fileUploadFinished.subscribe((result) => {
      this._zone.run(() => {
        if (!this.files) this.files = [];
        result.url = this.communityService.buildFileUrl(result.user_id, result);
        this.files.push(result);
        this.uploadingFiles = this.uploadingFiles.filter((item) => item.id != result.id);
      });
    });
  }

  showAttach(type) {
    const modalRef = this.appService.showModal(SearchContentComponent, { size: "lg" });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.contentSelected.pipe(take(1)).subscribe((result) => {
      if (type == "articles") {
        if (!this.comment.articles) this.comment.articles = [];
        this.comment.articles.push({ selector: false, editorSelector: false, article: result });
      } else {
        if (!this.comment.learn_content) this.comment.learn_content = [];
        this.comment.learn_content.push({ selector: false, editorSelector: false, content: result });
      }
    });
  }

  contentDetected(args) {
    if (args.article) {
      if (!this.comment.articles) this.comment.articles = [];
      this.comment.articles.push(args);
    } else if (args.content) {
      if (!this.comment.learn_content) this.comment.learn_content = [];
      this.comment.learn_content.push(args);
    }
  }

  mentionSelected(args) {
    if (!this.comment.mentions) this.comment.mentions = [];
    this.comment.mentions.push(args);
  }

  close() {
    if (this.uploadingFiles && this.uploadingFiles.length) {
      alert("Please wait until your files finish uploading.");
    } else {
      this.activeModal.close();
    }
  }

  postContentChanged(args) {
    this.editedPostContent = args;
  }

  postSubjectChanged(args) {
    this.editedSubjectContent = args;
  }

  save() {
    if (this.uploadingFiles && this.uploadingFiles.length) {
      alert("Please wait until your files finish uploading.");
    } else {
      if (this.messageSpan) this.comment[this.comment.text ? "text" : "message"] = this.message;
      if (this.files || this.comment.files) this.comment.files = this.files;

      if (this.isCommunityPost || this.isHtmlComment) {
        let messageContent = this.editedPostContent;
        if (this.comment.articles) {
          this.comment.articles.forEach((item) => {
            if (item.editorSelector) {
              item.editorSelector = item.editorSelector.replace("&nbsp;", "");
              messageContent = messageContent.replace(item.editorSelector, item.selector + " ");
            }
          });
        }
        if (this.comment.learn_content) {
          this.comment.learn_content.forEach((item) => {
            if (item.editorSelector) {
              item.editorSelector = item.editorSelector.replace("&nbsp;", "");
              messageContent = messageContent.replace(item.editorSelector, item.selector + " ");
            }
          });
        }
        if (this.comment.categories_or_section) {
          this.comment.categories_or_section.forEach((item) => {
            if (item.editorSelector) {
              item.editorSelector = item.editorSelector.replace("&nbsp;", "");
              messageContent = messageContent.replace(item.editorSelector, item.selector + " ");
            }
          });
        }
        if (this.comment.mentions) {
          this.comment.mentions.forEach((item) => {
            if (item.editorSelector) {
              item.editorSelector = item.editorSelector.replace("&nbsp;", "");
              messageContent = messageContent.replace(item.editorSelector, item.selector + " ");
            }
          });
        }
        if (this.comment.message != messageContent) {
          if (!this.comment.edits) this.comment.edits = [];
          this.comment.edits.push(this.comment.message);
        }
        this.comment.message = messageContent;
        if (this.isBetaComment) this.comment.subject = this.editedSubjectContent;
      }

      this.activeModal.close(this.comment);
    }
  }
}
