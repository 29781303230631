import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, CheckoutService, environment, GAService, HelperService, ProductService, UserService, WindowService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterProductComponent, SphereSubscribeStripeComponent, VerifyEmailComponent } from "../modals";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Auth } from "aws-amplify";
import { Meta } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductPurchaseUpgradeComponent } from "../product-purchase-upgrade/product-purchase-upgrade.component";

@Component({
  selector: "myp-sphere-onboarding-stripe",
  templateUrl: "sphere-onboarding-stripe.component.html",
})
export class SphereOnboardingStripeComponent extends BaseComponent implements OnInit {
  @Input() isModal: boolean;
  @Input() languageCode: string = "en";
  @Input() defaultToCreateAccount: boolean;
  @Input() shouldShowLogin: boolean;
  @Input() term: string;
  @Input() source: string;
  @Input() coupons: Array<string>;
  @ViewChild("productPurchaseUpgradeCmp", { static: false }) productPurchaseUpgradeCmp: ProductPurchaseUpgradeComponent;

  public authMethod = "signup";
  public termsCheck: boolean;
  public signupArgs: any = {
    email: "",
    password: "",
    password_repeat: "",
    client_id: "WEB",
    wantsEmail: false,
    captcha: false,
    source: "STUDIOONEPLUS",
  };

  public step = "signup";
  public env = environment;
  public productKey: string;
  public loginError: string;
  public loadingSignup: boolean = true;

  public verifyingUserStatus: boolean = true;
  public error: any;
  public captchaReady: boolean = true;
  public errorMessage: string;

  public passwordPasses: boolean = false;

  public selectedAccountType: string = "";

  public ssoType: string;
  public sso: string;
  public mustVerifyAccount: boolean;
  public mustHaveStudioOne: boolean;

  public subscription: any;
  public couponParam: string;
  public upgradableProducts: any;
  constructor(
    public userService: UserService,
    private _spin: NgxSpinnerService,
    public win: WindowService,
    public translate: TranslateService,
    public gaService: GAService,
    public appService: AppService,
    public router: Router,
    public fbAuth: AngularFireAuth,
    public productService: ProductService,
    public helperService: HelperService,
    public activatedRoute: ActivatedRoute,
    public avoService: AvoService,
    public db: AngularFirestore,
    public metaService: Meta,
    private modalService: NgbModal,
    public fbService: FirebaseService,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.appService.title = "Studio One+ Sign Up — Access Studio One & More";
    this.metaService.addTags([
      {
        name: "Description",
        content:
          "Join Studio One+ for a low monthly membership fee or even lower annual membership and access Studio One Professional music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { itemprop: "name", content: "Studio One+ Sign Up" },
      {
        itemprop: "Description",
        content:
          "Join Studio One+ for a low monthly membership fee or even lower annual membership and access Studio One Professional music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { itemprop: "image", content: "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png" },
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@PreSonus" },
      { name: "twitter:title", content: "Studio One+ Sign Up" },
      {
        name: "twitter:description",
        content:
          "Join Studio One+ for a low monthly membership fee or even lower annual membership and access Studio One Professional music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { name: "twitter:creator", content: "@PreSonus" },
      { name: "twitter:image:src", content: "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png" },
      { property: "og:url", content: "https://my.presonus.com/signup" },
      { property: "og:title", content: "Studio One+ Sign Up" },
      {
        property: "og:description",
        content:
          "Join Studio One+ for a low monthly membership fee or even lower annual membership and access Studio One Professional music production software, Notion notation software, and all the add-ons and sounds you need to bring your music to life.",
      },
      { property: "og:site_name", content: "Studio One+ Sign Up" },
      { property: "og:image", content: "https://my.presonus.com/angular/assets/images/presonus-sphere-bundle.png" },
      { property: "fb:app_id", content: "243955558952702" },
      { property: "og:type", content: "website" },
      { property: "og:locale", content: "en_US" },
      { name: "my.presonus", content: "my.presonus" },
      { name: "Presonus register", content: "Presonus register" },
      { name: "Presonus product registration", content: "Presonus product registration" },
      { name: "Mypresonus", content: "Mypresonus" },
      { name: "My presonus", content: "My presonus" },
      { name: "Mypresonus login", content: "Mypresonus login" },
      { name: "My presonus login", content: "My presonus login" },
      { name: "my.presonus login", content: "my.presonus login" },
      { name: "Studio one login", content: "Studio one login" },
      { name: "Studio one+ login", content: "Studio one+ login" },
      { name: "Presonus login", content: "Presonus login" },
      { name: "Log into my presonus", content: "Log into my presonus" },
      { name: "Log into mypresonus", content: "Log into mypresonus" },
      { name: "Studio one free demo", content: "Studio one free demo" },
      { name: "Studio one free", content: "Studio one free" },
      { name: "Studio one demo", content: "Studio one demo" },
      { name: "Studio one prime", content: "Studio one prime" },
    ]);
    if (!this.isModal) {
      this.appService.initTheme("auto");
    }
    if (this.activatedRoute.snapshot.queryParams["coupon"]) {
      this.couponParam = this.activatedRoute.snapshot.queryParams["coupon"];
    }
    if (this.router.url.indexOf("/join") > -1 || this.router.url.indexOf("/signup") > -1) {
      this.selectedAccountType = "join";
    } else if (this.router.url.indexOf("/register") > -1) {
      this.selectedAccountType = "register";
    } else if (this.router.url.indexOf("/buy") > -1) {
      this.selectedAccountType = "buy";
    }
    this.appService.setTokensFromCookies();
    this.win.setTimeout(() => {
      this.userService.isInWelcomeFlow = true;
    }, 10);
    const go = () => {
      this.loadingSignup = false;
      if (this.helperService.token && !this.isModal) {
        this.verifyingUserStatus = true;
        let timeout = 0;
        this.win.setTimeout(() => {
          this.userService.getSubscriptionDetails(true).subscribe({
            next: (result) => {
              this.win.setTimeout(() => {
                this.verifyingUserStatus = false;
              }, 500);
              this.init(result);
            },
            error: (error) => {
              this.userService.logout().subscribe((result) => {
                this.fbAuth.signOut().then(() => {
                  this.appService.redirectToConnect(true);
                });
              });
            },
          });
        }, timeout);
      } else {
        this.verifyingUserStatus = false;
        this.init();
      }
    };

    if (!this.isModal) {
      this.retrieveUpgradableProducts();
      this.appService.initTheme("auto");
    }
    this.userService.getUserDetails(false).subscribe({
      next: (result) => {
        go();
        if (!this.userService.user.active) {
          Auth.currentAuthenticatedUser()
            .then((user) => {})
            .catch((error) => {
              this.appService.redirectToConnect(true);
            });
        }
      },
      error: (error) => {
        this.userService.logout().subscribe((result) => {
          this.fbAuth.signOut().then(() => {
            this.appService.redirectToConnect(true);
          });
        });
      },
    });

    if (this.defaultToCreateAccount) {
      this.authMethod = "signup";
    } else if (this.shouldShowLogin) {
      this.authMethod = "login";
    }
  }

  retrieveUpgradableProducts() {
    if (!this.checkoutService.upgradableProducts) {
      this.checkoutService.getUpgradableProducts().subscribe();
    }
  }

  init(result?) {
    if (!this.isModal && this.languageCode) this.helperService.selectedLanguage = this.languageCode;
    if (!this.isModal) {
      let body = document.getElementsByTagName("body")[0];
      body.classList.add("full-page");
      if (!this.helperService.token) this.appService.redirectToConnect(true);
    }
    let baseSub: any = false;
    if (result && result.subscription) {
      this.subscription = result.subscription;
    }
    if (this.sso && this.userService.user) {
      this.initLoggedInUser();
    } else if (this.subscription && this.subscription.provider_subscription_id && this.userService.user && result.active) {
      // this._spin.hide();
      if (this.isModal) {
        this.step = "has_subscription";
      } else {
        if (this.selectedAccountType == "buy") {
          this.initLoggedInUser();
        } else {
          this.userService.isInWelcomeFlow = false;
          this.appService.initApp();
          this.router.navigate(["home"]);
        }
      }
    } else if (this.subscription && this.subscription.provider_subscription_id && result.active) {
      this.userService.getUserDetails(false).subscribe((result) => {
        // this._spin.hide();
        if (this.isModal) {
          this.step = "has_subscription";
        } else {
          if (this.selectedAccountType == "buy") {
            this.initLoggedInUser();
          } else {
            this.userService.isInWelcomeFlow = false;
            this.appService.initApp();
            this.router.navigate(["home"]);
          }
        }
      });
    } else {
      // this._spin.hide();
      if (this.userService.user) {
        this.initLoggedInUser();
      }
    }
  }

  showRegisterProduct() {
    this.appService.closeModals();
    const modalRef = this.appService.showModal(RegisterProductComponent, {
      size: "lg",
      ariaLabelledBy: "modal-title",
      beforeDismiss: () => {
        if (modalRef.componentInstance.registering) {
          alert(this.translate.instant("products.register.registration_in_process"));
          return false;
        }
      },
    });
    modalRef.componentInstance.autoNavigate = true;
  }

  continueFree() {
    this.appService.closeModals();
    this.appService.initApp();
    this.router.navigate(["/home"]);
  }

  continueSphere() {
    this.appService.closeModals();
    this.checkoutService.couponParam = this.couponParam;
    this.checkoutService.selectedS1PlusPlan = this.term;
    this.checkoutService.source = this.source;
    this.checkoutService.coupons = this.coupons;
    if (this.env.features.checkout_page) {
      this.router.navigate(["/checkout"]);
    } else {
      this.appService.showModal(SphereSubscribeStripeComponent, { size: "xl", backdrop: "static" });
    }
  }

  checkPassword() {
    this.passwordPasses = this.userService.passwordTester.test(this.signupArgs.password);
    return this.passwordPasses;
  }

  initLoggedInUser() {
    if (this.sso) {
      if (this.mustVerifyAccount && !this.userService.user.active) {
        alert("You need to activate your account before using this feature.");
        this.router.navigate(["/home"]);
        this._spin.hide();
      } else if (this.mustHaveStudioOne) {
        this.productService.getProducts(true).subscribe((result: any) => {
          this._spin.hide();
          let hasStudioOne = false;
          if (result && result.software && result.software.length) {
            result.software.forEach((item) => {
              if (
                item.product.title == "Studio One 4 Professional" ||
                item.product.title == "Studio One 4 Professional OEM" ||
                item.product.title == "Studio One 4 Artist" ||
                item.product.title == "Studio One 4 Artist OEM"
              ) {
                hasStudioOne = true;
              }
            });
          }
          if (hasStudioOne) {
            window.location.href = this.sso;
          } else {
            this._spin.hide();
            alert("You need a valid copy of Studio One 4 Artist or Professional to perform this action.");
          }
        });
      } else {
        if (this.ssoType == "amazon") this.sso = this.sso += "&infoField1=" + this.userService.user.email + "&infoField2=" + this.userService.user.id;
        this._spin.hide();
        window.location.href = this.sso;
      }
    } else if (this.userService.user && this.userService.user.active_subscription && !this.subscription) {
      if (this.isModal) {
        this.continueSphere();
      }
    } else if (this.userService.user && (!this.userService.user.active_subscription || this.selectedAccountType == "buy")) {
      // this.appService.setCookies();
      this.fbService.loginFirebaseUser();

      let checkoutUrlParam = this.activatedRoute.snapshot.queryParamMap.get("checkout");
      if (checkoutUrlParam && environment.stripe.products[checkoutUrlParam]) {
        // app component handles opening this (from anywhere), so dont open these.
      } else {
        if (this.selectedAccountType == "join" || this.isModal) {
          this.continueSphere();
        } else if (this.selectedAccountType == "register") {
          this.step = "choose_account_type";
          this.showRegisterProduct();
        } else if (this.selectedAccountType == "buy") {
          this.win.setTimeout(() => {
            this.productPurchaseUpgradeCmp.showRegisterUpgradeProduct();
          }, 200);
        } else {
          this.step = "choose_account_type";
        }
      }
    } else {
      this._spin.hide();
      this.userService.isInWelcomeFlow = false;
      this.router.navigate(["home"]);
    }
  }

  resolved(captchaResponse) {
    this.signupArgs.captcha = captchaResponse;
  }

  checkSignupArgs() {
    if (!this.signupArgs.email.trim() || !this.signupArgs.password || !this.signupArgs.password_repeat || !this.signupArgs.captcha) {
      return {
        passed: false,
        error: "login.fill_required_fields",
      };
    } else if (this.signupArgs.password != this.signupArgs.password_repeat || !this.checkPassword()) {
      return {
        passed: false,
        error: "login.password_not_match",
      };
    } else if (!this.termsCheck) {
      return {
        passed: false,
        error: "terms.plz_agree_to_terms",
      };
    } else {
      return { passed: true };
    }
  }

  logout() {
    this.router.navigate(["/logout"]);
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("sphere-color-bg");
    body.classList.remove("full-page");
    this.userService.isInWelcomeFlow = false;
  }
}
