import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { WindowService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-autocomplete-dropdown",
  templateUrl: "autocomplete-dropdown.component.html",
})
export class AutocompleteDropdownComponent {
  private _items: Array<any>;
  @Input() set items(items) {
    this._items = items;
    this.filteredItems = [...this.items];
  }
  get items() {
    return this._items;
  }
  public filteredItems: Array<any>;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() selection: string = "";
  @Input() hint: string = "Make a selection";
  @Input() clearOnSelect: boolean = false;
  @Input() hasDefault: boolean = false;
  @Input() tabindex: number = 0;

  @ViewChild("input") input;
  public query: string;
  public focused: boolean = false;
  public searching: boolean = false;
  public preselectedItem: any;
  public firstTouch: boolean = true;

  public mode: string = "string";
  constructor(public win: WindowService) {}

  ngOnInit() {
    if (this.items[0] && typeof this.items[0] == "object") this.mode = "object";
    this.filteredItems = [...this.items];
  }

  initAutocomplete(delayCursor?) {
    this.searching = true;
    this.cursorToEnd();
  }

  select(item, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.firstTouch = false;
    if (!this.clearOnSelect) this.selection = item;
    this.win.setTimeout(() => {
      this.searching = false;
    }, 100);
    this.selected.next(this.mode == "string" ? item : item.value);
    if (this.clearOnSelect) this.query = "";
  }

  inputFocusChange(type, event) {
    this.focused = type == "focus";
    if (type == "blur") {
      this.win.setTimeout(() => {
        if (this.input && this.input.nativeElement && this.input.nativeElement.innerText == "") {
          this.selection = "";
          this.selected.next("");
        }
        this.searching = false;
      }, 500);
    } else {
      if (this.firstTouch && !this.hasDefault) this.input.nativeElement.innerText = "";
    }
  }

  cursorToEnd(timeout = 10) {
    this.win.setTimeout(() => {
      let input = this.input.nativeElement;
      if (input) {
        input.focus();
        var range = document.createRange();
        range.selectNodeContents(input);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        sel.collapseToEnd();
        input.focus();
      }
    }, timeout);
  }

  public scrollItemIntoView() {
    let index = this.filteredItems.indexOf(this.preselectedItem);
    let itemEl = document.getElementById("item" + index);
    if (itemEl && itemEl.scrollIntoView) {
      itemEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }

  filter(event) {
    if (event.key == "Tab" || event.key == "Shift") {
      // dont do anything if its tab or shift
    } else if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (this.preselectedItem) this.select(this.preselectedItem);
    } else if (event.key == "ArrowDown" || event.key == "ArrowUp") {
      event.preventDefault();
      event.stopPropagation();
      let preselectedIndex = -1;
      this.filteredItems.forEach((item, index) => {
        if ((this.mode == "string" ? item : item.value) == (this.mode == "string" ? this.preselectedItem : this.preselectedItem?.value)) preselectedIndex = index;
      });
      if (preselectedIndex == 0 && event.key == "ArrowUp") {
        preselectedIndex = this.filteredItems.length - 1;
      } else if (preselectedIndex == this.filteredItems.length - 1 && event.key == "ArrowDown") {
        preselectedIndex = 0;
      } else {
        preselectedIndex = preselectedIndex + (event.key == "ArrowDown" ? 1 : -1);
      }
      this.preselectedItem = this.filteredItems[preselectedIndex];
      this.scrollItemIntoView();
    } else {
      this.query = this.input.nativeElement.innerText;
      this.filteredItems = this.items.filter((item) => (this.mode == "string" ? item : item.value).toLowerCase().trim().indexOf(this.query.toLowerCase().trim()) > -1);
    }
  }
}
