import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit, HostListener, Output, EventEmitter, NgZone } from "@angular/core";

import { WorkspaceDetailBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, WorkspaceService, WindowService, environment, EventBusService, CommunityService, Notification } from "@mypxplat/xplat/core";
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { AppService, AvoService, FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { WorkspaceUploadComponent, ManageCollaboratorsComponent, AddWorkspaceComponent, SubscribeComponent, EditCommentComponent, CreatePublicLinkComponent } from "../modals";
import { take, filter, map, takeUntil } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FileUploader } from "ng2-file-upload";
import firebase from "firebase/compat/app";
import { SharedFilesComponent } from "../shared-files/shared-files.component";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageInputComponent } from "../message-input/message-input.component";
declare var window: any;

@Component({
  selector: "myp-shared-workspace-detail",
  templateUrl: "workspace-detail.component.html",
})
export class WorkspaceDetailComponent extends WorkspaceDetailBaseComponent implements OnInit, OnDestroy {
  // @HostListener("window:beforeunload", ["$event"])
  // doSomething($event) {
  //   this.timelineRef.update({
  //     viewing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
  //     typing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
  //   });
  // }

  public loading: boolean = false;
  public descriptionExpanded: boolean = false;
  public selectedView: "timeline" | "files" = "timeline";
  public firestore: AngularFirestoreCollection;
  public messagesRef: AngularFirestoreCollection;
  public missedMessagesRef: AngularFirestoreCollection;
  public timelineRef: AngularFirestoreDocument<any>;
  public loadingTimeline: boolean = true;

  public refreshFileDone: boolean = true;
  public shouldShowShowMore: boolean = false;
  public playingAudioFile: any;
  private _isListeningToEnded: boolean = false;

  public displayedSection = "timeline";
  public timelineFilesViewHeight: number = 100;
  public chatWidth: number = 200;
  public uiMeasured: boolean = false;
  public cachedUserID: any;
  public activeUsers: any = {};
  public typingUsers: any;
  public hasBaseDropZoneOver: boolean = false;
  public routerEvents: any;
  public batchOperationMode: boolean = false;
  public batchOperationSelection: any;
  public hideBatchDownloadNotice = false;
  public isChrome: boolean = false;
  public showingAllMessages: boolean = false;
  public attingCollabs: Array<any>;
  public highlightedAtCollab: any;
  public selectedAtCollab: any;
  public selectedAtCollabsInMessage: any = {};
  public fileRefs: any = {};
  public unReadCounts: any = {};
  public totalUnreadFileMessages: number;

  public inputContainerHeight: number = 44;
  public inputCmp: MessageInputComponent;
  public msgInputMargin: number = 0;
  public detailsWidth: string = "40%";
  public rightOffset: number;

  public hasMessagesPermission: boolean = false;

  @ViewChild("msgInput", { static: false }) msgInput: ElementRef;

  @Output() workspaceLoaded: EventEmitter<any> = new EventEmitter();

  @ViewChild("container", { static: false }) container: ElementRef;
  @ViewChild("chatWindow", { static: false }) chatWindow: ElementRef;
  @ViewChild("timelineFilesView", { static: false }) timelineFilesView: ElementRef;
  @ViewChild("descTextSpan", { static: false }) descTextSpan: ElementRef;
  @ViewChild("sharedFilesCmp", { static: false }) sharedFilesCmp: SharedFilesComponent;

  public uploader: FileUploader;
  public activeFolder: any;

  constructor(
    public windowService: WindowService,
    public userService: UserService,
    public helperService: HelperService,
    private _activatedRoute: ActivatedRoute,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    private _eventbusService: EventBusService,
    public router: Router,
    public zone: NgZone,
    public translate: TranslateService,
    public spinner: NgxSpinnerService,
    public communityService: WebCommunityService,
    public avoService: AvoService,
    public fbService: FirebaseService
  ) {
    super(userService, windowService, workspaceService, zone, communityService);
  }

  ngOnInit() {
    this.cachedUserID = this.userService.user.id += "";
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    if (this._activatedRoute.snapshot.params["id"]) {
      this.initView(this._activatedRoute.snapshot.params["id"]);
    }

    this._eventbusService.observe(this._eventbusService.types.playAudio).subscribe((result) => {
      this.measureUI();
    });
  }

  decodeName(string) {
    return string ? decodeURIComponent(string) : string;
  }

  initView(id) {
    this.loading = true;
    this.workspaceService.getWorkspace(id).subscribe(
      (result) => {
        let hasAccess = false;
        result.collaborators.forEach((item) => {
          if (item.user_id == this.user.id) hasAccess = true;
        });
        if (result.user_id == this.user.id) hasAccess = true;
        if (hasAccess) {
          this.loading = false;
          this.workspaceLoaded.next(result);
          this.setupWorkspace(result);

          const go = () => {
            this.selectView(this._activatedRoute.snapshot.params["view"]);
            if (this.workspace.firebase_timeline_id) {
              this.initMessages();
            } else {
              // /create a timeline for this project.
              if (this.userService.user.id == this.workspace.user_id) {
                const ref = this.db.collection("timelines");
                this.fbService
                  .handleFirestorePromise(() =>
                    ref.add({
                      created: new Date(),
                      workspace_id: this.workspace.id,
                      created_by: this.user.id,
                      contributors: {
                        [this.user.id]: true,
                      },
                    })
                  )
                  .then((doc) => {
                    this.workspaceService.updateWorkspace({ id: this.workspace.id, firebase_timeline_id: doc.id }).subscribe((result) => {
                      this.workspace.firebase_timeline_id = doc.id;
                      this.initMessages();
                    });
                  });
              }
            }
          };
          go();
        } else {
          alert("You do not have access to this workspace.");
        }
      },
      (error) => {
        this.appService.alertError(error);
        // this.router.navigate(["workspaces"]);
      }
    );
    window.scrollTo(0, 0);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  setupWorkspace(details, includeStorage = true) {
    if (details.shared_files && details.shared_files.length) {
      details.image = details.shared_files.filter((item) => item.coverphoto)[0] ? details.shared_files.filter((item) => item.coverphoto)[0].url : null;
    }
    this.workspace = details;
    this.myWorkspace = this.workspace.user_id == this.user.id;
    this.setupFiles(this.workspaceService.workspaceFilesMap[this.workspace.id] || []);
    this.windowService.setTimeout(() => {
      this.measureUI();
    }, 600);
    if (this.workspace.mainMix) {
      this.workspace.mainMix.creator = this.workspace.collaborators.filter((item) => item.user_id == this.workspace.mainMix.user_id)[0];
    }
    this.collabsNotMe = [];
    this.workspace.collaborators.forEach((item) => {
      this.collaboratorMap[item.user_id] = item;
      if (item.user_id != this.user.id) {
        this.collabsNotMe.push({
          id: item.user_id,
          name: item.name,
          photo: item.photo,
          email: item.email,
        });
      }
    });
    if (includeStorage) {
      this.userService.getStorageDetails(this.workspace.user_id).subscribe((result) => {
        this.userStorageDetails = result;
      });
    }
    this.myRole = this.workspace.collaborators.filter((item) => item.user_id == this.user.id)[0].role;
    this.windowService.setTimeout(() => {
      if (this.descTextSpan && this.descTextSpan.nativeElement) {
        if (this.descTextSpan.nativeElement.offsetHeight > 60) {
          this.shouldShowShowMore = true;
        }
      }
    }, 500);
  }

  setupFiles(files) {
    super.setupFiles(files);
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  measureUI() {
    if (this.timelineFilesView && this.timelineFilesView.nativeElement) {
      let offsetTop = this.timelineFilesView.nativeElement.getBoundingClientRect().y;
      const height = window.innerHeight - offsetTop;
      let body = document.getElementsByTagName("body")[0];
      this.timelineFilesViewHeight = height - (body.classList.contains("audio-playing") ? 130 : 70);
      this.rightOffset = 115;
      if (window.innerWidth < 1200) {
        this.rightOffset = 65;
      }
      if (window.innerWidth < 601) {
        this.rightOffset = 35;
      }
      if (window.innerWidth < 401) {
        this.rightOffset = 30;
      }
      // if (this.container.nativeElement.offsetWidth == 1800) {
      this.msgInputMargin = window.innerWidth - this.container.nativeElement.offsetWidth - this.container.nativeElement.offsetLeft - 60;
      // } else {
      // this.msgInputMargin = 0;
      // }
      if (this.chatWindow && this.chatWindow.nativeElement) this.chatWidth = this.chatWindow.nativeElement.clientWidth;
      this.uiMeasured = true;
    }
    if (window.innerWidth < 992) {
      this.detailsWidth = "100%";
    } else {
      this.detailsWidth = "40%";
    }
    // if (window.innerWidth < 500) {
    //   this.rightOffset = 20;
    // } else if (window.innerWidth < 600) {
    //   this.rightOffset = 50;
    // }
  }

  resendInvite(email, event) {
    event.stopPropagation();
    this.workspaceService.resendWorkspaceInvite(email, this.workspace.id).subscribe((result) => {
      alert("Invite resent!");
    });
  }

  collabClick(collab) {
    if (collab.user_id) {
      this.router.navigate(["./profile", collab.user_id]);
    }
  }

  watchTimelineEvents() {
    this.timelineRef
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        map((timelineData: any) => {
          let data = timelineData.payload.data();
          let rtn = {
            ...data,
            key: timelineData.payload.id,
          };
          return rtn;
        })
      )
      .subscribe((result) => {
        this.activeUsers = {};
        if (result.viewing && result.viewing.length) {
          result.viewing.forEach((item) => (this.activeUsers[item] = true));
        }
        this.typingUsers = result.typing && result.typing.length ? result.typing.filter((item) => item != this.user.id) : [];
      });
  }

  initMessages(all = false) {
    this.timelineRef = this.db.collection("timelines").doc(this.workspace.firebase_timeline_id);
    this.watchTimelineEvents();
    this.fbService
      .handleFirestoreObservable(() => this.timelineRef.get().pipe(take(1)))
      .subscribe({
        next: (result) => {
          if (this.userService.user.id == this.workspace.user_id) {
            let existingContributors = result.data()?.contributors || {};
            let contributors = { ...existingContributors };
            this.workspace.collaborators.forEach((item) => {
              if (item.user_id) contributors[item.user_id] = true;
            });
            if (result.data() && !result.data().viewingFix) {
              this.timelineRef.update({ viewingFix: true });
            }
            if (JSON.stringify(contributors) != JSON.stringify(existingContributors)) {
              this.timelineRef.update({ contributors });
            }
          }
        },
        error: (error) => {},
      });
    this.missedMessagesRef = this.timelineRef.collection("missedMessages");
    this.messagesRef = this.timelineRef.collection("messages", (ref) => {
      if (all) {
        this.showingAllMessages = true;
        return ref.orderBy("timestamp");
      } else {
        this.showingAllMessages = false;
        return ref.orderBy("timestamp").limitToLast(100);
      }
    });
    this.communityService
      .subscribeToComments(this.messagesRef, "asc")
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.hasMessagesPermission = true;
          this.windowService.setTimeout(() => {
            this.scrollChatToBottom();
            this.setupMissedMessages();
            this.updateMyReadStatus();
          }, 1000);
          this.refreshFiles();
          this.loadingTimeline = false;
          this.scrollChatToBottom();
        },
        (error) => {
          this.loadingTimeline = false;
          if (this.helperService.retrieveErrorMessage(error) == "Missing or insufficient permissions.") {
            alert("You do not have permission to view messages in this workspace. If you believe this is an error, please contact support.");
            this.hasMessagesPermission = false;
          }
        }
      );
  }

  setupMissedMessages() {
    this.sharedFiles.forEach((file) => {
      if (file.type != "folder") {
        this.fileRefs[file.id] = this.db.collection("timelines").doc(this.workspace.firebase_timeline_id).collection("fileThreads").doc(file.id.toString());

        // Check if the document exists
        this.fileRefs[file.id]
          .collection("missedMessages")
          .doc(this.user.id)
          .get()
          .subscribe((docSnapshot) => {
            if (docSnapshot.exists) {
              // Continue with handling the observable if the document exists
              this.fbService
                .handleFirestoreObservable(() =>
                  this.fileRefs[file.id]
                    .collection("missedMessages")
                    .doc(this.user.id)
                    .snapshotChanges()
                    .pipe(
                      takeUntil(this.destroy$),
                      map((c: any) => {
                        let data = c.payload.data();
                        let rtn = {
                          ...data,
                          key: c.payload.id,
                        };
                        return rtn;
                      })
                    )
                )
                .subscribe({
                  next: (result: any) => {
                    this.unReadCounts[file.id] = result && result.missedCount ? result.missedCount : 0;
                    this.totalUnreadFileMessages = 0;
                    for (var i in this.unReadCounts) {
                      this.totalUnreadFileMessages += this.unReadCounts[i];
                    }
                  },
                  error: (error) => {},
                });
            } else {
              // Handle the case where the document does not exist
              // For example, set a default value or skip
              this.unReadCounts[file.id] = 0;
            }
          });
      }
    });
  }

  filesRefreshed(files) {
    this.setupFiles(files);
  }

  msgOptions(msg?, file?, event?) {
    if (event) event.stopPropagation();
    if (msg && msg.file) file = msg.file;
    if (file) file = this.sharedFilesMap[file.id];
    let title = file ? file.filename : msg.message;
    if (file && file.grouping_id && this.groupedFilesMap[file.grouping_id])
      title = "Stem Upload from Studio One 5 (" + this.groupedFilesMap[file.grouping_id].length + " file" + (this.groupedFilesMap[file.grouping_id].length > 1 ? "s" : "") + ")";
    let args: any = {
      title: title,
      actions: [],
    };
    if ((!file && this.user.id == msg.author.id) || (file && !file.grouping_id && ((file && file.user_id == this.user.id) || !file || this.myWorkspace))) {
      args.actions.push(file ? "Edit File Description" : "Edit Message");
    }
    if (file) {
      if (!file.grouping_id && !file.mainmix && this.workspaceService.audioFiles[file.extension]) args.actions.push("Mark as Main Mix");
      if (!file.grouping_id) args.actions.push("Download File");
      if (!file.grouping_id) args.actions.push("Rename File");
      if (file.grouping_id) args.actions.push("Download Stems");
      args.actions.push("Move File");
      if (this.user.active_subscription) args.actions.push("Share in Community");
      if (this.user.active_subscription && !file.hash) args.actions.push("Create Shareable Link");
      if (file.hash) args.actions.push("View Public Share Page");
    }
    if (this.myWorkspace || this.myRole == "admin" || (msg && msg.author.id == this.user.id) || (this.myRole == "contributor" && file && file.user_id == this.user.id)) {
      args.actions.push(file ? (file.grouping_id ? "Delete Stems" : "Delete File") : "Delete Message");
    }
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message" || result == "Edit File Description") {
            this.editMsg(msg, file);
          } else if (result == "Mark as Main Mix") {
            this.markAsMainMix(file);
          } else if (result == "Download File" || result == "Download Stems") {
            this.downloadFile(false, file);
          } else if (result == "Delete Message" || result == "Delete File" || result == "Delete Stems") {
            this.deleteMsg(msg, file);
          } else if (result == "Move File") {
            this.sharedFilesCmp.showMoveFolder([file]);
          } else if (result == "Rename File") {
            let newName = prompt("Enter a new filename...", file.filename.split(".")[0]);
            if (newName) {
              newName = newName.replace(/[#+%><*?}{|$=`!:/]/g, "");
              let exists = false;
              this.sharedFiles.forEach((item) => {
                if (newName + "." + file.extension == item.filename && file.folder_id == item.folder_id) exists = true;
              });
              if (exists) {
                alert("A file with that name already exists in this folder.");
              } else {
                let args: any = {
                  id: file.id,
                  filename: newName + "." + file.extension,
                };
                if (!file.storage_id) args.storage_id = file.filename;
                this.workspaceService.updateFile(args).subscribe(() => {
                  this.refreshFiles();
                });
              }
            }
          } else if (result == "Share in Community") {
            this.communityService.attachingExistingFile = file;
            this.router.navigate(["community/channels/general"]);
          } else if (result == "Create Shareable Link") {
            this.createPublicLink(file);
          } else if (result == "View Public Share Page") {
            this.router.navigate(["share/" + file.hash]);
          }
        }
      });
  }

  createPublicLink(file) {
    const go = (file) => {
      if (file.formats && file.formats.length > 1) {
        let args: any = {
          title: "Which format would you like to share?",
          actions: [],
        };
        file.formats.forEach((item) => {
          if (!item.public_link) args.actions.push(item.extension.toUpperCase());
        });
        this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
        this._eventbusService
          .observe(this._eventbusService.types.actionChosen)
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              file.formats.forEach((item) => {
                if (item.extension.toUpperCase() == result) {
                  const modalRef = this.appService.showModal(CreatePublicLinkComponent, { size: "am" });
                  modalRef.componentInstance.file = item;
                }
              });
            }
          });
      } else {
        const modalRef = this.appService.showModal(CreatePublicLinkComponent, { size: "am" });
        modalRef.componentInstance.file = file;
      }
    };
    if (file.format_grouping_id) {
      this.spinner.show();
      this.workspaceService.getFileDetails(file.id).subscribe((result) => {
        this.spinner.hide();
        go(result);
      });
    } else {
      go(file);
    }
  }

  deleteMsg(msg, file?) {
    if (msg.file) file = msg.file;
    if (file) {
      this.communityService.comments.forEach((item, index) => {
        if (item.file && item.file.id == file.id) this.messageDeleteIndex = index;
      });
      this.deleteFile(file);
    } else if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.communityService.comments.forEach((item, index) => {
        if (msg.key == item.key) this.messageDeleteIndex = index;
      });
      this.messagesRef.doc(msg.key).delete();
    }
  }

  editMsg(msg, file?) {
    if (msg.file) file = msg.file;
    if (file) file = this.sharedFilesMap[file.id];
    if (file) {
      let newFileDescription = prompt("Edit your message...", file.description || "");
      if (newFileDescription) {
        this.workspaceService.updateFile({ id: file.id, description: newFileDescription }).subscribe((result) => {
          this.refreshFiles();
        });
      }
    } else {
      const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg" });
      modalRef.componentInstance.comment = { ...msg };
      modalRef.componentInstance.isHtmlComment = true;
      modalRef.componentInstance.collaborators = this.collabsNotMe;
      modalRef.result.then((result) => {
        let updateItem = this.communityService.comments.filter((item) => item.key == msg.key)[0];
        let updateData: any = { message: result.message, edited: true };
        if (result.mentions) updateData.mentions = result.mentions;
        this.messagesRef.doc(updateItem.key).update(updateData);
      });
    }
  }

  manageCollaborators() {
    const modalRef = this.appService.showModal(ManageCollaboratorsComponent, { size: "lg" });
    modalRef.componentInstance.collaborators = this.workspace.collaborators;
    modalRef.componentInstance.workspace_id = this.workspace.id;
    modalRef.componentInstance.collaboratorAdded.pipe(take(1)).subscribe((addedCollaborator) => {
      if (addedCollaborator && addedCollaborator.id) {
        this.timelineRef.get().subscribe((result) => {
          let contributors = result.data().contributors;
          contributors[addedCollaborator.id] = true;
          this.timelineRef.update({ contributors });
        });
      }
      this.workspaceService.getWorkspace(this.workspace.id, true).subscribe((result) => {
        this.setupWorkspace(result);
      });
    });
  }

  downloadFile(event, file) {
    file.url = this.workspaceService.buildFileUrl(this.workspace.user_id, this.workspace.id, file);
    if (event) event.stopPropagation();
    if (file.type == "stems" && file.grouping_id) {
      let files = this.groupedFilesMap[file.grouping_id];
      let timeout = 0;
      files.forEach((item) => {
        timeout += 500;
        item.delay = timeout;
      });
      files.forEach((file) => {
        this.windowService.setTimeout(() => {
          let link = document.createElement("a");
          link.href = file.url;
          link.download = file.url;
          link.click();
        }, file.delay);
      });
    } else if (file.format_grouping_id) {
      let args: any = {
        title: "Which format would you like to download?",
        actions: [],
      };
      this.groupedFormatsMap[file.format_grouping_id].forEach((item) => {
        args.actions.push(item.extension.toUpperCase());
      });
      this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
      this._eventbusService
        .observe(this._eventbusService.types.actionChosen)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.groupedFormatsMap[file.format_grouping_id].forEach((item) => {
              if (item.extension.toUpperCase() == result) {
                let link = document.createElement("a");
                link.href = item.url;
                link.download = item.url;
                link.click();
              }
            });
          }
        });
    } else {
      let link = document.createElement("a");
      link.href = file.url;
      link.download = file.url;
      link.click();
    }
    this.avoService.trackEvent().workspaceFileDownloaded({
      siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
      workspaceId: parseInt(this.workspace.id),
    });
  }

  selectFileForBatchOperation(file, event) {
    if (event) event.stopPropagation();
    if (!this.batchOperationSelection) this.batchOperationSelection = {};
    file.selectedForBatchOperation = !file.selectedForBatchOperation;
    if (file.selectedForBatchOperation) {
      this.batchOperationSelection[file.id] = file;
    } else {
      delete this.batchOperationSelection[file.id];
    }
  }

  toggleBatchOperationMode() {
    this.batchOperationMode = !this.batchOperationMode;
    if (!this.batchOperationMode) {
      this.batchOperationSelection = {};
      for (var i in this.workspaceService.folderMap) {
        this.workspaceService.folderMap[i].files.forEach((item) => {
          item.selectedForBatchOperation = false;
        });
      }
    }
  }

  batchOperation(operation) {
    let files = [];
    for (var i in this.batchOperationSelection) {
      this.batchOperationSelection[i].selectedForBatchOperation = false;
      files.push(this.batchOperationSelection[i]);
    }
    if (!files.length) {
      alert("You did not select any files to download.");
    } else {
      this.batchOperationSelection = {};
      this.batchOperationMode = false;
      this.hideBatchDownloadNotice = false;
      let timeout = 0;
      if (operation == "download") {
        files.forEach((item) => {
          timeout += 500;
          item.delay = timeout;
        });
        files.forEach((file) => {
          this.windowService.setTimeout(() => {
            this.downloadFile(false, file);
          }, file.delay);
        });
      } else if (operation == "move") {
        this.sharedFilesCmp.showMoveFolder(files);
      } else if (operation == "delete") {
        this.deleteBatchFiles(files);
      }
    }
  }

  deleteBatchFiles(files) {
    let deletedFiles = [];
    files.forEach((file) => {
      let path = this.user.id + "/workspace-uploads/" + file.workspace_id + "/" + file.filename;
      deletedFiles.push({
        path: path,
        id: file.id,
        storage_location: file.storage_location,
      });
    });

    let msg = this.translate.instant("sphere.workspaces.confirm_remove_file");
    if (deletedFiles.length) msg = this.translate.instant("sphere.workspaces.confirm_remove_files", { count: deletedFiles.length });
    if (confirm(msg)) {
      this.spinner.show();
      this.workspaceService.batchDeleteFiles(deletedFiles).subscribe(
        (result) => {
          if (result.status == "success") {
            this.spinner.hide();
            this.refreshFiles();
          } else {
            alert("There was an error deleting the files, please try again.");
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          this.appService.alertError(error);
        }
      );
    }
  }

  deleteFile(file, deletingStems?) {
    const go = (myFile) => {
      let deleteItem = this.communityService.comments.filter((item) => item.file && item.file.id == myFile.id)[0];
      if (this.myWorkspace || this.myRole == "admin" || (this.myRole == "contributor" && myFile.user_id == this.user.id)) {
        let path = this.user.id + "/workspace-uploads/" + this.workspace.id + "/" + myFile.filename;
        this.workspaceService.deleteFile(myFile.id).subscribe((result) => {
          this.refreshFiles();
          let deleteIndex;
          this.sharedFiles.forEach((item, index) => {
            if (item.id == myFile.id) deleteIndex = index;
          });
          this.sharedFiles.splice(deleteIndex, 1);
          if (deleteItem && deleteItem.key) this.messagesRef.doc(deleteItem.key).delete();
        });
      }
    };
    if (!deletingStems) {
      let msg = "Are you sure you want to remove this file? It cannot be undone.";
      if (file.grouping_id && this.groupedFilesMap[file.grouping_id].length > 1)
        msg =
          "Are you sure you want to remove " + (this.groupedFilesMap[file.grouping_id].length + " file" + (this.groupedFilesMap[file.grouping_id].length > 1 ? "s" : "")) + "? It cannot be undone.";
      if (confirm(msg)) {
        if (file.grouping_id) {
          this.groupedFilesMap[file.grouping_id].forEach((groupedFile) => {
            this.deleteFile(groupedFile, true);
          });
        } else {
          go(file);
        }
      }
    } else {
      go(file);
    }
  }

  selectView(view) {
    this.selectedView = view;
    this.activeFolder = undefined;
    this.router.navigate(["./workspaces/detail/" + this.workspace.id, view], {
      queryParams: this._activatedRoute.snapshot.queryParams,
    });
    this.windowService.setTimeout(() => {
      this.measureUI();
    }, 200);
    this.scrollChatToBottom();
  }

  editWorkspace() {
    const modalRef = this.appService.showModal(AddWorkspaceComponent);
    modalRef.componentInstance.editing = this.workspace;
    modalRef.componentInstance.workspaceSaved.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.setupWorkspace(result);
    });
    modalRef.componentInstance.workspaceDeleted.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.workspaceService.deleteWorkspace(this.workspace.id).subscribe((result) => {
        if (result.status == "success") {
          let notification: Notification = {
            title: this.workspace.name + " was deleted.",
            body: "The workspace " + this.workspace.name + " was deleted. Contact the workspace owner for more information.",
            photo: this.workspace.image,
            topics: ["collaboration"],
            type: "collaboration_workspace_deleted",
            payload: {
              workspace_name: this.workspace.name,
              workspace_id: this.workspace.id,
              workspace_id_with_prefix: "workspace_" + this.workspace.id,
            },
          };
          if (this.collabsNotMe.length) {
            let ids = [];
            this.collabsNotMe.forEach((collab) => {
              ids.push(collab.id);
            });
            if (ids.length) {
              this.userService
                .sendNotification({
                  notification: notification,
                  user_ids: ids,
                  clients: ["rollup", "mobile", "email"],
                })
                .subscribe();
            }
          }
          this.timelineRef.delete();
          this.timelineRef = null;
          this.helperService.goBack();
        } else {
          alert("Deleting the workspace failed, please contact support.");
        }
      });
    });
  }

  incrementUnreadCount() {
    this.workspaceService.getWorkspace(this.workspace.id, true).subscribe((result) => {
      // get the collaborator details again just in case a collaborator has left in the meantime.
      this.setupWorkspace(result, false);
      this.workspace.collaborators.forEach((item) => {
        if (item.status == "approved") {
          if (item.user_id && item.user_id != this.userService.user.id) {
            this.missedMessagesRef
              .doc(item.user_id)
              .get()
              .pipe(take(1))
              .subscribe((result) => {
                if (!result.data()) {
                  this.missedMessagesRef.doc(item.user_id).set({
                    missedCount: 1,
                  });
                } else {
                  let count = result.data().missedCount;
                  this.missedMessagesRef.doc(item.user_id).set({
                    missedCount: ++count,
                  });
                }
              });
          }
        }
      });
    });
  }

  addMessage() {
    if (this.newMsg) {
      let obj: any = {
        author: {
          id: this.user.id,
          photo: this.user.photoURL,
          name: this.user.firstName + " " + this.user.lastName,
        },
        user_id: this.user.id,
        type: "message",
        workspace_name: this.workspace.name,
        timestamp: new Date(),
      };

      if (this.inputCmp.selectedMentionsInMessage && this.inputCmp.selectedMentionsInMessage.length) {
        let realMentions = [];
        this.inputCmp.selectedMentionsInMessage.forEach((item) => {
          if (this.newMsg.indexOf(item.selector) > -1 && item.user?.id) {
            realMentions.push({
              id: item.user.id,
              user: item.user,
              selector: item.selector,
              editorSelector: item.editorSelector,
            });
          }
        });
        this.inputCmp.selectedMentionsInMessage.forEach((men) => {
          this.newMsg = this.newMsg.replace(men.editorSelector, men.selector + " ");
        });

        obj.mentions = realMentions;
        this.inputCmp.selectedMentionsInMessage = [];
      }

      obj.message = this.newMsg;
      let notification: Notification = {
        title: this.user.firstName + " in " + this.workspace.name,
        body: this.helperService.htmlToText(this.newMsg),
        photo: this.user.photoURL,
        topics: ["collaboration"],
        url: "https://my.presonus.com/workspaces/detail/" + this.workspace.id,
        button: "View Workspace",
        type: "collaboration_new_message",
        payload: {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          workspace_name: this.workspace.name,
          workspace_id: this.workspace.id,
          workspace_id_with_prefix: "workspace_" + this.workspace.id,
        },
      };
      if (this.collabsNotMe.length) {
        let notMentionIds = [];
        this.collabsNotMe.forEach((collab) => {
          if (!obj.mentions || !obj.mentions.length) {
            notMentionIds.push(collab.id);
          } else {
            // only add the id to the notMentionIds array if its not present in the mentions array
            let found = false;
            obj.mentions.forEach((mention) => {
              if (mention.id == collab.id) found = true;
            });
            if (!found) notMentionIds.push(collab.id);
          }
        });
        if (notMentionIds.length) {
          // send the notification to users who were not mentioned, users who were mentioned should also get an email
          this.userService
            .sendNotification({
              notification: notification,
              user_ids: notMentionIds,
              clients: ["rollup", "mobile"],
            })
            .subscribe();
        }
      }
      if (obj.mentions && obj.mentions.length) {
        // send the notification to users who were mentioned, and include "email" in clients array
        let mentionIds = [];
        obj.mentions.forEach((mention) => {
          mentionIds.push(mention.id);
        });
        notification.title = this.user.firstName + " mentioned you in " + this.workspace.name;
        this.userService
          .sendNotification({
            notification: notification,
            user_ids: mentionIds,
            clients: ["rollup", "mobile", "email"],
          })
          .subscribe();
      }

      this.messagesRef.add(obj);
      this.newMsg = "";
      this.inputCmp.emptyInput();
      this.incrementUnreadCount();
      this.scrollChatToBottom();
      this.timelineRef.get().subscribe((result) => {
        let readStatus = result.data().readStatus;
        if (!readStatus) readStatus = Object.assign({}, result.data().contributors);
        for (var i in readStatus) {
          readStatus[i] = i == this.user.id ? true : false;
        }

        this.timelineRef.update({ readStatus });
      });
    }
  }

  updateMyReadStatus() {
    this.timelineRef
      .get()
      .pipe(take(1))
      .subscribe((result) => {
        let existingReadStatus = result.data().readStatus;
        let readStatus = existingReadStatus ? { ...existingReadStatus } : Object.assign({}, result.data().contributors);
        readStatus[this.user.id] = true;
        let readStatusHasChanged = JSON.stringify(readStatus) != JSON.stringify(existingReadStatus);
        let count = Object.entries(readStatus).length;
        let caughtUpCount = 0;
        let caughtUp = false;
        for (var i in readStatus) {
          if (readStatus[i]) {
            caughtUpCount++;
          }
        }
        if (caughtUpCount == count) caughtUp = true;
        let caughtUpStatusHasChanged = caughtUp != result.data().caughtUp;
        if (caughtUpStatusHasChanged || readStatusHasChanged) {
          const update = { readStatus, caughtUp };
          this.timelineRef.update(update);
        }
      });
    this.missedMessagesRef
      .doc(this.userService.user.id)
      .get()
      .pipe(take(1))
      .subscribe((result) => {
        if (result.exists && result.data() && result.data().missedCount != 0) {
          this.missedMessagesRef.doc(this.userService.user.id).set({
            missedCount: 0,
          });
        }
      });
  }

  addFile(mainMix = false, coverPhoto = false, droppedFile = false) {
    if (this.myRole != "browser") {
      if (this.userStorageDetails && this.userStorageDetails.used > this.userStorageDetails.plan_total) {
        if (this.user.id == this.workspace.user_id) {
          alert("You have exceeded your Cloud Storage limit. Free up space by deleting unsused files.");
        } else {
          alert("The owner of this workspace has exceeded their Cloud Storage limit.");
        }
      } else {
        const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
        modalRef.componentInstance.workspace_id = this.workspace.id;
        modalRef.componentInstance.file_path = this.activeFolder ? this.activeFolder.path : "Home";
        if (this.activeFolder && this.activeFolder.path != "Home") modalRef.componentInstance.folder_id = this.workspaceService.folderMap[this.activeFolder.path].id;
        modalRef.componentInstance.myWorkspace = this.workspace.user_id == this.user.id;
        modalRef.componentInstance.mainMix = mainMix;
        modalRef.componentInstance.coverPhoto = coverPhoto;
        modalRef.componentInstance.description = this.newMsg;
        if (droppedFile) modalRef.componentInstance.droppedFile = droppedFile;
        modalRef.componentInstance.uploadProgressUpdated.pipe().subscribe((result) => {
          const file = this.sharedFiles.filter((item) => item.id == result.file.id)[0];
          if (file) file.progress = result && result.progress ? result.progress : false;
        });
        modalRef.componentInstance.fileCreated.pipe(take(1)).subscribe((result) => {
          this.workspaceService.getWorkspace(this.workspace.id, true).subscribe((details) => {
            this.setupWorkspace(details);
          });
        });
        modalRef.componentInstance.fileUploadFinished.pipe(take(1)).subscribe((result) => {
          let obj = {
            author: {
              id: this.user.id,
              photo: this.user.photoURL,
              name: this.user.firstName + " " + this.user.lastName,
            },
            user_id: this.user.id,
            file: result,
            type: "file-uploaded",
            timestamp: new Date(),
          };
          this.messagesRef.add(obj);

          if (this.collabsNotMe && this.collabsNotMe.length) {
            this.workspaceService.notifyWorkspaceFileUploaded(result.id).subscribe();
          }

          this.incrementUnreadCount();
          this.refreshFileDone = false;
          this.userService.getStorageDetails().subscribe();
          this.workspaceService.getWorkspace(this.workspace.id, true).subscribe((details) => {
            this.refreshFileDone = true;
            this.windowService.setTimeout(() => {
              this.setupWorkspace(details);
            }, 1000);
          });
        });
      }
    }
  }

  playAudio(file, event) {
    if (event) event.stopPropagation();

    let playlist = [];
    if (file.folder_id) {
      playlist = this.sharedFiles.filter((item) => item.folder_id == file.folder_id && this.workspaceService.audioFiles[item.extension]);
    } else {
      playlist = this.sharedFiles.filter((item) => !item.folder_id && this.workspaceService.audioFiles[item.extension]);
    }
    playlist.sort((a, b) => (new Date(a.date_created) > new Date(b.date_created) ? 1 : -1));

    let url = file.url;
    let size: any = false;
    if (file.format_grouping_id) {
      let formats = this.groupedFormatsMap[file.format_grouping_id];
      // choose the smallest format to play.
      formats.forEach((file) => {
        if (!size) size = parseInt(file.filesize);
        if (parseInt(file.filesize) < size) {
          size = parseInt(file.filesize);
          url = file.url;
        }
      });
    }

    let fileEntity = { ...this.sharedFilesMap[file.id] };
    if (playlist.length && playlist.length > 1) fileEntity.playlist = playlist;

    this._eventbusService.emit(this._eventbusService.types.playAudio, fileEntity);
    this.avoService.trackEvent().workspaceMediaPlayed({
      siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
      workspaceId: parseInt(this.workspace.id),
      fileId: file.id,
    });
  }

  pauseAudio() {
    const audioPlayer: HTMLAudioElement = document.getElementById("audio_player") as HTMLAudioElement;
    this.playingAudioFile.isPlaying = false;
    audioPlayer.pause();
  }

  changeImage() {
    this.addFile(false, true);
  }

  scrollChatToBottom() {
    this.windowService.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = this.selectedView == "files" ? 0 : this.chatWindow.nativeElement.scrollHeight;
      }
    }, 25);
  }

  scrollToTop() {
    this.windowService.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = 0;
      }
    }, 25);
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  droppedFile(args) {
    this.addFile(false, false, args);
    this.uploader.clearQueue();
  }

  upgrade() {
    const modalRef = this.appService.showModal(SubscribeComponent, { size: "lg" });
  }

  fileItemClick(file) {
    if (!this.batchOperationMode) this.router.navigate(["workspaces/file/" + file.id]);
  }

  messageContainerHeightChange(args) {
    this.inputContainerHeight = args;
    this.scrollChatToBottom();
  }

  messageValueChange(args) {
    this.newMsg = args;
  }

  messageInputCmpLoaded(args) {
    this.inputCmp = args.cmp;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.communityService.comments = [];
    this.workspaceService.lastViewedFolder = undefined;
    this.totalUnreadFileMessages = 0;
    for (var i in this.unReadCounts) {
      this.totalUnreadFileMessages += this.unReadCounts[i];
    }
    if (this.totalUnreadFileMessages) {
      this.missedMessagesRef.doc(this.user.id).set({
        missedCount: this.totalUnreadFileMessages,
      });
    }
    this.clearWatchFileProgress();
  }
}
