import { Component, Input, OnInit } from "@angular/core";

import { InstallerButtonsBaseComponent } from "@mypxplat/xplat/features";
import { UserService, GAService, ProductService, WindowService, ProductSnapshot, ProductLicenseItem } from "@mypxplat/xplat/core";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { PreviousVersionsComponent, IlokComponent, FirmwareInstructionsComponent } from "../modals";

@Component({
  selector: "myp-installer-buttons",
  templateUrl: "installer-buttons.component.html",
})
export class InstallerButtonsComponent extends InstallerButtonsBaseComponent implements OnInit {
  @Input() downloads: any;
  @Input() showAllSystems: boolean;
  @Input() product: ProductSnapshot["product"];
  @Input() license: ProductLicenseItem;
  @Input() dealer: boolean = false;
  @Input() firmwareList: Array<any>;
  @Input() previousVersions: Array<any>;
  @Input() beta: Array<any>;
  @Input() isSideBar: boolean = true;
  public myOs: string;

  constructor(userService: UserService, public appService: AppService, public ga: GAService, public productService: ProductService, private _win: WindowService, public avoService: AvoService) {
    super(userService);
  }

  ngOnInit() {
    this.myOs = this.appService.os;
    // if (this.product.partnerDownloadUrl && this.product.partnerDownloadUrl.indexOf("{code}") > -1) {
    // this.product.partnerDownloadUrl = this.product.partnerDownloadUrl.replace("{code}", this.details.product_data.productKeyString);
    // }
  }

  showPreviousVersions() {
    const modalRef = this.appService.showModal(PreviousVersionsComponent, {
      size: "lg",
    });
    modalRef.componentInstance.productId = this.product.id;
    modalRef.componentInstance.versions = this.previousVersions;
    modalRef.componentInstance.licenseScheme = this.product.licenseScheme;
  }

  showiLokOverlay() {
    const modalRef = this.appService.showModal(IlokComponent, { size: "lg" });
  }

  showFirmwareInstructions(ware) {
    if (this.dealer) {
      this.downloadTokenizedAsset(ware);
    } else {
      const modalRef = this.appService.showModal(FirmwareInstructionsComponent, {
        size: "lg",
      });
      let data: any = {
        product_id: this.dealer ? this.product.id : this.license.licenseId,
        version: ware.version,
      };
      if (this.dealer) data.dealer = true;
      modalRef.componentInstance.firmware = data;
      modalRef.componentInstance.productDetails = this.product;
    }
  }

  downloadTokenizedAsset(download) {
    if (download.title.indexOf("Notion 6") > -1) {
      this._win.openUrl("https://notion_content.presonusmusic.com/" + download.folder + "/" + download.filename);
    } else if (download.tokenized || download.type == "firmware") {
      this.productService
        .getTokenizedUrl(
          download.folder,
          download.filename,
          this.userService.appId == "mypresonus" ? "mypresonus" : "studio_one_plus",
          this.product.title,
          this.product.id,
          download.filename,
          download.filesize,
          download.version || download.version_number
        )
        .subscribe({
          next: (result: any) => {
            window.open(result.url);
          },
          error: (error) => {
            this.appService.alertError(error);
          },
        });
    } else {
      this._win.openUrl(download.url);
    }
  }
  openUrl(Url) {
    this._win.openUrl(Url);
  }
}
