import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, ProductService, UserService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "myp-add-edit-beta-program",
  templateUrl: "add-edit-beta-program.component.html",
})
export class AddEditBetaProgramComponent extends BaseComponent implements OnInit {
  @Input() program: any = {};
  public startDate: any;
  public loading: boolean = false;
  public endDate: any;
  @Output() programAdded: EventEmitter<any> = new EventEmitter();
  public groups: Array<any>;
  public groupMap: any = {};
  public betaTags: Array<any>;
  public betaTagMap: any = {};
  public betaTagName: string;
  public creatingTag: boolean = false;
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public betaService: BetaService,
    public adminService: AdminService,
    public appService: AppService,
    public productService: ProductService,
    public db: AngularFirestore
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    if (this.program.id) {
      this.initTags();
    }
    this.adminService.get("groups").subscribe((result: any) => {
      this.groups = result;
      this.groups.forEach((group) => {
        this.groupMap[group.id] = group;
      });
      if (this.program.group_ids) {
        let groupIds = this.program.group_ids.split(",");
        groupIds.forEach((groupId) => {
          this.groupMap[groupId].selected = true;
        });
      }
      this.loading = false;
    });
  }

  initTags() {
    this.betaService.getBetaCommunityTags(this.program.string_id).subscribe((result) => {
      this.creatingTag = false;
      this.betaTags = result;
      this.betaTags.forEach((tag) => {
        this.betaTagMap[tag.id] = tag;
      });
    });
  }

  removeTag(tag) {
    this.betaTags = this.betaTags.filter((t) => t.id !== tag.id);
    this.betaService.deleteBetaTag(tag.id).subscribe();
  }

  addTag() {
    this.creatingTag = true;
    let name = this.betaTagName;
    this.betaTagName = "";
    this.betaService
      .createBetaTag({
        title: name,
        string_id: name.replace(" ", "_").toLowerCase(),
        beta_string_id: this.program.string_id,
      })
      .subscribe((result) => {
        this.initTags();
      });
  }

  showDatePicker(start?) {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    modalInstance.componentInstance.past = false;
    if (start && this.program.start_date) {
      modalInstance.componentInstance.initWithDate = new Date(this.program.start_date);
    } else if (!start && this.program.end_date) {
      modalInstance.componentInstance.initWithDate = new Date(this.program.end_date);
    }
    modalInstance.componentInstance.earliestYear = new Date().getFullYear();
    modalInstance.componentInstance.dateSelected.subscribe((date) => {
      this.program[start ? "start_date" : "end_date"] = date;
    });
  }

  submit() {
    let groupIds = [];
    for (const i in this.groupMap) {
      if (this.groupMap[i].selected) groupIds.push(i);
    }
    this.program.group_ids = groupIds.join(",");
    if (this.program.start_date) this.program.start_date = moment(this.program.start_date).format("YYYY-MM-DD");
    if (this.program.end_date) this.program.end_date = moment(this.program.end_date).format("YYYY-MM-DD");
    this.adminService.createBeta(this.program).subscribe((result) => {
      this.programAdded.next(result);
      this.activeModal.close();
    });
  }
}
