import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { UserService, ProductService, EventBusService, ProductListItem, ProductSnapshot } from "@mypxplat/xplat/core";
import { Product } from "@mypxplat/xplat/core";
import { ProductTileBaseComponent } from "@mypxplat/xplat/features";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-product-tile",
  templateUrl: "product-tile.component.html",
})
export class ProductTileComponent extends ProductTileBaseComponent implements OnInit {
  @ViewChild("register") registerBtn: ElementRef;
  @Input() product: any;
  @Input() image: string;
  @Input() buttons: Array<any>;
  @Input() title: string;
  @Input() description: string;
  @Input() productLink: string;
  @Input() registerProductTile: boolean;
  @Input() showDate: boolean = true;
  @Output() tileClicked: EventEmitter<any> = new EventEmitter();
  @Output() registerProductClicked: EventEmitter<any> = new EventEmitter();
  public ownedProductID: any;
  public samplePrefix = "https://pae-web.presonusmusic.com/uploads/products/";

  constructor(
    public userService: UserService,
    public productService: ProductService,
    public appService: AppService,
    public translate: TranslateService,
    public eventBusService: EventBusService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.product) {
      if (!this.productLink && this.product.url) {
        this.productLink = this.product.url;
      } else if (!this.productLink && this.product.permalink_products) {
        this.productLink = "https://shop.presonus.com/" + this.product.permalink_products;
      }

      this.title = this.product.title;
      this.description = this.product.shortDescription;
      this.image = this.product.imageUrl;
    }

    this.productService.newProductRegistered.subscribe((flag) => {
      if (this.registerBtn && flag) {
        this.registerBtn.nativeElement.focus();
        this.productService.newProductRegistered$.next(false);
      }
    });
  }

  playSample(url) {
    this.eventBusService.emit(this.eventBusService.types.playAudio, {
      url: this.samplePrefix + url,
      filename: this.product.title,
    });
  }

  downloadInstaller(product) {
    this.productService.getProductSnapshot(product.id).subscribe((snapshot: ProductSnapshot) => {
      let download;
      if (this.appService.os == "mac") {
        download = snapshot.downloads.installers.mac[0];
      } else {
        if (snapshot.downloads.installers.win64) {
          download = snapshot.downloads.installers.win64[0];
        } else {
          download = snapshot.downloads.installers.win[0];
        }
      }
      window.open(download.url);
    });
  }
}
