import { Component, ViewChild, OnInit } from "@angular/core";

import { BaseComponent, UserService, HelperService, WindowService } from '@mypxplat/xplat/core';
import { Router } from "@angular/router";
import { AppService } from '@mypxplat/xplat/web/core';
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: "myp-cloud-header",
  templateUrl: "cloud-header.component.html",
})
export class CloudHeaderComponent extends BaseComponent implements OnInit {
  @ViewChild("myDrop", { static: false }) myDrop: NgbDropdown;

  constructor(userService: UserService, public helperService: HelperService, public router: Router, public appService: AppService, public fbAuth: AngularFireAuth) {
    super(userService);
  }

  ngOnInit() {}

  logout() {
    (<any>window).Intercom("shutdown");
    this.userService.logout().subscribe((result) => {
      this.fbAuth.signOut().then(() => {
        this.router.navigate(["/login"]);
      });
    });
  }

  openMenu() {
    // if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
    //   this.openMobileMenu();
    // } else {
    // }
    this.myDrop.open();
  }
}
