import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { BaseComponent, UserService, ExchangeService, environment, HelperService, CommunityService, ProductService, AdminService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { Subject, interval, skip, takeUntil, throttle } from "rxjs";

@Component({
  selector: "myp-confirm-beta-announcement-email",
  templateUrl: "confirm-beta-announcement-email.component.html",
})
export class ConfirmBetaAnnouncementEmail extends BaseComponent implements OnInit {
  @Input() to: Array<string>;
  @Input() userIds: Array<any>;
  @Input() subject: any;
  @Input() messageHtml: any;
  @Input() from: any;
  @Input() directLink: string;
  public loading: boolean;
  public preview: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService,
    public productService: ProductService,
    public adminService: AdminService
  ) {
    super(userService);
  }

  ngOnInit() {}

  getMessageHtml() {
    let html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${this.subject}</title>
          <style>
              .email-container {
                  max-width: 600px;
                  margin: 20px auto;
                  padding: 20px;
                  border-radius: 8px;
              }
              .content { padding: 20px 0; line-height: 1.6; }
              .author-section {
                  display: flex;
                  align-items: center;
                  padding: 20px 0;
                  margin-bottom: 20px;
              }
              .author-photo {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 15px;
              }
              .author-photo img {
                  width: 100%;
                  height: auto;
                  display: block;
              }
              .author-name { font-weight: bold; font-size: 16px; }
              .button {
                  background-color: #007bff;
                  color: #ffffff;
                  padding: 10px 12px;
                  border-radius: 30px;
                  text-decoration: none;
                  font-size: 16px;
              }
              .button:hover { background-color: #0056b3; color: white }
          </style>
      </head>
      <body>
          <div class="email-container">
              <div><strong>${this.subject}</strong></div>
              <div class="content"><p>${this.messageHtml}</p></div>
              <div class="author-section">
                  <div class="author-photo"><img src="${this.from.photo}" alt="Author Photo"></div>
                  <div class="author-name">${this.from.name}</div>
              </div>
              <div class="button-container"><a href="${this.directLink}" class="button">View More Details</a></div>
          </div>
      </body>
      </html>
    `;
    return html;
  }

  submit() {
    if (this.subject && (this.to?.length || this.userIds?.length) && this.messageHtml) {
      if (confirm("Are you sure you want to send this email? It will be sent to " + (this.to?.length || this.userIds?.length) + " recipients.")) {
        let html = this.getMessageHtml();
        let args: any = {
          subject: this.subject,
          messageHtml: html,
          from: "donotreply@presonus.com",
          author: {
            name: this.userService.user.firstName + " " + this.userService.user.lastName,
            id: this.userService.user.id,
          },
        };
        if (this.to) args.to = this.to;
        if (this.userIds) args.userIds = this.userIds;
        // args.userIds = [804975, 880848]; // for testing
        if (this.userService.user.photoURL) args.author.photoURL = this.userService.user.photoURL;
        this.adminService.sendEmail(args).subscribe((result) => {
          this.activeModal.close();
        });
      }
    } else {
      alert("Please fill out all the required fields.");
    }
  }
}
