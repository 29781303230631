import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrdersBaseComponent } from '@mypxplat/xplat/features';
import { BaseComponent, StorageService, UserService, OrderService, ProductService } from '@mypxplat/xplat/core';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-shared-orders',
  templateUrl: 'orders.component.html'
})
export class OrdersComponent extends OrdersBaseComponent implements OnInit, OnDestroy {

  public destroy$: Subject<any> = new Subject();
  public orders: Array<any>;
  public loading: boolean = true;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public orderService: OrderService,
    public productService: ProductService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.orderService.orders$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((result:any) => {
      if (result) {
        this.loading = false;
        this.orders = result;
      }
    })
    this.orderService.getOrders();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goShopping() {
    window.open('https://shop.presonus.com');
  }

  goToProduct(id) {
    this._router.navigate(['/products/detail', id]);
  }

  removeCCInfo() {
    if (confirm("Are you sure you want to remove stored credit card info from your Shop account? Next time you check out you will have the option to save it again.")) {
      this.userService.removeCreditCardDetails().subscribe((result: any) => {
        if (result.success) {
          alert('Your credit card details were successfully removed.');
        }
      })
    }
  }
}